import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Fields } from "../Fields";
import { DatePickerComponent } from './date-picker/date-picker.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';


const moment = _rollupMoment || _moment;
moment.defaultFormat = "DD/MM/YYYY";
const _date = moment().toDate();
export var MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: "app-date",
  template: `
  <div [formGroup]="group">
<mat-form-field>
  <input matInput [matDatepicker]="picker" placeholder="{{field.settings.hint}}"   [(ngModel)]="date.value"  [disabled]="field.disabled"  [formControlName]="field.name" readonly="{{field.disabled}}"  required="{{field.settings.required}}" >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
  <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"    *ngIf="field.settings.info != null" ></mat-hint>
  </div>
`,
  styles: ['.hint-container { display: flex; flex-direction: column; font-size: 65%; }'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class DateComponent implements OnInit {
  field: Fields;
  group: FormGroup;


  //date = new FormControl(moment().format("DD/MM/YYYY"));
  date = new FormControl();

 
  
  constructor() { }
  ngOnInit() {
    MY_FORMATS.parse.dateInput = this.field.settings.dateformat;
    MY_FORMATS.display.dateInput = this.field.settings.dateformat;
    //console.log("a", this.date);


  }


}

