import { Component, OnInit,Output,EventEmitter,Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fields } from "../Fields";

@Component({
  selector: "app-input",
  template: `

<div   [formGroup]="group">
 <mat-form-field>
    <input matInput  [placeholder]="field.settings.hint" [formControlName]="field.name" name="{{field.name}}"  required="{{field.settings.required}}"  readonly="{{field.disabled}}"  >
    <span *ngIf="field.settings.unit != null" matSuffix>&nbsp;{{field.settings.unit}}</span>
    <mat-error *ngIf="InputFormControl.invalid" > {{getErrorMessage()}}</mat-error>
 </mat-form-field>

  <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"  *ngIf="field.settings.info != null" ></mat-hint>
 </div>
`,
  styles: ['.example-full-width {  width: 100%; } .hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class InputComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() {  }

 InputFormControl = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    return this.InputFormControl.hasError(this.field.validations.name) ? this.field.validations.message :'';
  }


}
