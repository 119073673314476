import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, AfterContentChecked, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { Fields } from '../Fields';
import { FormBuilder } from '@angular/forms';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { ApiService } from '../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, interval, Subscription } from 'rxjs';
import { Guid } from "guid-typescript";
import { DataService } from '../service/data.service';
import { DoCheck } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../service/authentication.service';
import { takeUntil, debounceTime, filter } from 'rxjs/operators';
import { UiService } from '../ui.service'

@Component({
  selector: 'app-study-detail',
  templateUrl: './study-detail.component.html',
  styleUrls: ['./study-detail.component.css']
})

export class StudyDetailComponent implements OnInit, DoCheck, AfterContentChecked, OnDestroy, AfterViewInit {

  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  scrollEvent: any;

  @ViewChildren(DynamicFormComponent) form: QueryList<DynamicFormComponent>;
  dataSource: any;
  id: any;
  data: Fields[];
  rightsdata = {};

  differ: any;
  public isNew: boolean = true;
  public isEdit: boolean = true;
  public completed: boolean = false;
  public creationdate: any;
  public createdBy: any;
  public fields = [];
  public studyOrg: any;
  public saveInProgress: boolean = false;
  public autoSaveInProgress: boolean = false;
  public _recordname: string;
  public dependentValues = [];

  private onDestroy$ = new Subject<void>();
  private unsubscribe: Subscription;

  constructor(private api: ApiService, private route: ActivatedRoute, private location: Location, private dataservice: DataService, private ref: ChangeDetectorRef,
    private toastr: ToastrService, private fb: FormBuilder, private translate: TranslateService, private auth: AuthenticationService, private ui: UiService, private router: Router) {

  }

  scroll = (event: any): void => {
    this.scrollEvent = event;
    if (event.srcElement.scrollTop > this.showScrollHeight)
      this.showScroll = true;
    else if (this.showScroll && event.srcElement.scrollTop  < this.hideScrollHeight)
      this.showScroll = false;
  }

  scrollToTop () {
    this.scrollEvent.srcElement.scrollTop = 0;
  }



  ngAfterViewInit(): void {
    if (!localStorage.getItem('autosave_interval')) {
      this.api.getAutoSaveInterval().subscribe(interval => {
        localStorage.setItem('autosave_interval', interval);
      })
    }

    
    //this.unsubscribe = interval(60 * 1000).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.autoSave());
    this.unsubscribe = interval(5 * 1000).pipe(takeUntil(this.onDestroy$)).subscribe(count => {
      var autosave_interval = Number(localStorage.getItem('autosave_interval'));
      var autosave_block = autosave_interval - 5;
      count = count * 5;
      if (count % autosave_interval == autosave_block) {
        this.autoSaveInProgress = true;
        this.ui.spin$.next(true);
      }
      else {
        setTimeout(() => {          
          this.autoSaveInProgress = false;
        }, 1000);
      }

      if (count != 0 && count % autosave_interval ==0) {
        this.autoSave();
      }
    });

  }
  ngOnDestroy(): void {
    if (this.unsubscribe)
      this.unsubscribe.unsubscribe();
    if (this.onDestroy$)
      this.onDestroy$.next();
    window.removeEventListener('scroll', this.scroll, true);

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    this.loadData();
    window.addEventListener('scroll', this.scroll, true);

  }
  ngDoCheck(): void {
    var _this = this;
    if (this.form != undefined) {
      this.form.forEach(fromInstance => {
        if (fromInstance.groupId) {
          
          var result = this.dependentValues.find(i => { return i.groupStep == fromInstance.groupId && i.fieldStep == fromInstance.formfield.step && i.value == fromInstance.form.value[fromInstance.formfield.name] });
          if (result == undefined)
            result = this.dependentValues.find(i => { return i.groupStep == fromInstance.groupId && i.fieldStep == fromInstance.formfield.step });

          if (result != undefined) {
            var dependency = false;
            var arr = [];
            if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple && fromInstance.formfield.value != "") {


              fromInstance.formfield.value.forEach(c => {

                var inc = undefined; var multipleResult = undefined;
                if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {

                  multipleResult = this.dependentValues.filter(a => { return a.groupStep == fromInstance.groupId && a.fieldStep == fromInstance.formfield.step });
                }
                if (multipleResult.length > 0) {
                  var inc = multipleResult.find(i => { return i.groupStep == fromInstance.groupId && i.fieldStep == fromInstance.formfield.step && i.value === c });
                  if (inc != undefined) {
                    arr.push({ result: eval(`\"${c}\" ${inc.operator} \"${inc.value}\"`), value: c, step: inc.fieldStep, groupstep: inc.groupStep });
                  }
                }
                else {
                  arr.push({ result: eval(`\"${c}\" ${result.operator} \"${result.value}\"`), value: c, step: result.fieldStep, groupstep: result.groupStep });
                }

              });

              var contains = arr.find(a => a.result === true);
              if (contains != undefined)
                dependency = true;
              else dependency = false;
            }
            else if (fromInstance.formfield.type === "radiobutton") {
        
              dependency = eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${result.operator} \"${result.value}\"`)
              
              var rdbresult = this.dependentValues.filter(i => { return i.groupStep == fromInstance.groupId && i.fieldStep == fromInstance.formfield.step  })
              if (rdbresult.length >0  && !dependency) {
                _this.fields.forEach(f => {
                  if (f.type === "group" && f.step === fromInstance.groupId) {
                    rdbresult.forEach(r => {
                      var groupresult = f.group.filter(fg => { return fg.step === r.affectedStep || (fg.dependency.fieldName === r.affectedfield && fg.dependency.fieldStep === r.affectedStep) });
                      if (groupresult.length > 0) {
                        groupresult.forEach(g => {
                          g.disabled = true;
                          
                          var radioOptionControl = this.form.find(a => a.formfield.name == g.name)
                          if (radioOptionControl != undefined && radioOptionControl.value[g.name] != "") {
                            g.value = "";
                            radioOptionControl.setValue(g.name, "");
                            this.dataservice.dependent = true;

                          }

                        })

                      }
                    })

           
                     
                  }
                })
                return
              }
            }
            else
              dependency = eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${result.operator} \"${result.value}\"`);

            if (dependency) {

              //if (eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${result.operator} \"${result.value}\"`)) {
              this.fields.forEach(f => {
                if (f.type === "group" && f.step === fromInstance.groupId) {
                  if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {
                   
                    var groupresult = f.group.filter(fg => { return fg.disabled });
                    if (groupresult.length > 0)
                      groupresult.forEach(gr => {
                        if (!fromInstance.formfield.value.includes(gr.dependency.value)) {
                          var radioOptionControl = this.form.find(a => a.formfield.name == gr.name)
                          if (radioOptionControl != undefined && radioOptionControl.value[gr.name] != "") {
                            radioOptionControl.setValue(gr.name, "");
                          }
                        }
                      
                      })
                  }

                  f.group.forEach(g => {

                    if (g.dependency.value === fromInstance.form.value[fromInstance.formfield.name] && g.dependency.fieldStep === result.fieldStep && g.dependency.groupStep === result.groupStep) {
                      g.disabled = false;
                    }
                    else if (fromInstance.form.value[fromInstance.formfield.name] != "" && dependency && g.dependency.fieldStep === result.fieldStep && g.dependency.groupStep === result.groupStep) {
                      g.disabled = false;
                      if (fromInstance.form.value[fromInstance.formfield.name] != g.dependency.value && g.dependency.value!="") {
                        g.disabled = true;

                        if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {
                          var contains = arr.find(a => a.result === true && a.value === g.dependency.value);
                          
                          if (contains != undefined) {
                            g.disabled = false;
                          }
                          else {
                            g.disabled = true;

                          }
                        }
                        else {
                          g.disabled = true;

                        }
                      }
                      else {
                        if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {
                          var contains = arr.find(a => a.result === true && a.value === g.dependency.value);

                          if (contains != undefined) {
                            g.disabled = false;
                          }
                          else {
                            g.disabled = true;
                          }
                        }

                      }
                    }

                    else if (fromInstance.form.value[fromInstance.formfield.name] != "" && dependency && f.dependency.fieldStep === result.fieldStep && f.dependency.groupStep === result.groupStep) {
                      g.disabled = false;
                    }
                    else if (fromInstance.formfield.value != "" && dependency && fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {
                      var dep = []; var arry = [];
                      this.dependentValues.forEach(a => {
                        fromInstance.formfield.value.forEach(c => {
                          if (a.groupStep === fromInstance.groupId && a.fieldStep === fromInstance.formfield.step && c != a.value && g.step == a.affectedStep) {
                            var ary = arr.find(i => { return i.step === fromInstance.formfield.step && i.groupstep === fromInstance.groupId && i.value != c })
                            var dependentList = this.dependentValues.filter(i => { return i.fieldStep === fromInstance.formfield.step && i.groupStep === fromInstance.groupId })
                            dependentList.forEach(x => {
                              if (!dep.includes(x.value))
                                dep.push(x.value);
                            })

                            arr.forEach(x => {
                              if (!arry.includes(x.value))
                                arry.push(x.value);
                            })

                            if (ary === undefined) {
                              g.disabled = true;
                            }

                            if (ary != undefined && !dep.includes(a.value)) {
                              g.disabled = true;
                            }

                            if (dep.includes(a.value) && !arry.includes(a.value)) {
                              g.disabled = true;
                            }

                          }
                        })
                      })
                    }
                    else if (fromInstance.formfield.value != "" && dependency && g.step === result.affectedStep) {
                      g.disabled = false;
                    }

                  })
                }
                else if (f.type != "group" && f.step === fromInstance.formfield.step) {
                  var depResult = this.form.find(i => { return f.dependency.value == "" || i.value[f.dependency.fieldName] == f.dependency.value });
                  if (depResult)
                    f.disabled = false;
                  else
                    f.disabled = true;


                }

              })
            }
            else {
              _this.fields.forEach(f => {
                if (f.type === "group" && f.step === fromInstance.groupId) {
                  var other = null;
                  f.group.forEach(g => {
                    if (other != null) {
                      if (g.step === other.step) {
                        g.disabled = true;

                      }
                    }


                    if (g.dependency.fieldName === fromInstance.formfield.name && g.type == "checkbox") {
                      var checkdependent = eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${g.dependency.operator} \"${g.dependency.value}\"`);
                      if (!checkdependent && fromInstance.form.value[fromInstance.formfield.name] != "") {
                        this.dataservice.dependent = true;
                      }
                    }



                    if (g.dependency.value != fromInstance.form.value[fromInstance.formfield.name] && g.dependency.fieldStep === result.fieldStep && g.dependency.groupStep === result.groupStep) {
                      g.disabled = true;
                      //if (fromInstance.form.value[fromInstance.formfield.name] != "" && fromInstance.formfield.type == "checkbox" ) {
                      if (fromInstance.form.value[fromInstance.formfield.name] != true && fromInstance.form.value[fromInstance.formfield.name] != "" && fromInstance.formfield.type == "checkbox" && fromInstance.formfield.settings.multiple) {

                        // fromInstance.formfield.value = [];
                        //g.value = [];
                      }


                      other = f.group.find(i => { return i.dependency.fieldStep == g.step });

                      if (other != undefined) {

                        f.group.forEach(gg => {
                          if (gg.name == other.dependency.fieldName && other.dependency.fieldName == fromInstance.formfield.name) {
                            gg.value = "";
                            //gg.value = [];

                            //fromInstance.formfield.value = [];
                          }
                        })
                      }
                      var dependent = this.dependentValues.find(a => { return a.affectedStep == g.step && a.affectedfield == g.name })
                      
                      if (dependent != undefined) {
                        fromInstance.form.value[dependent.affectedfield] = "";
                        this.form.forEach(frm => {
                          if (frm.formfield.step === dependent.affectedStep && frm.formfield.name === dependent.affectedfield) {

                            frm.setValue(dependent.affectedfield, "");
                          }
                        })

                      }

                    }

                  })

                }
                else if (f.type != "group" && f.step === fromInstance.formfield.step && f.name === fromInstance.formfield.name && f.dependency.value != fromInstance.form.value[fromInstance.formfield.name]) {
                  f.disabled = true;
                }
              })


            }
          }

        }
        else {

          var result = this.dependentValues.find(i => { return i.fieldStep == fromInstance.formfield.step });

          if (result != undefined) {
            dependency = eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${result.operator} \"${result.value}\"`);
            if (fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple && fromInstance.formfield.value != "") {
              var multipleResult = this.dependentValues.filter(a => { return a.fieldStep == fromInstance.formfield.step });
              var arr = [];
              fromInstance.formfield.value.forEach(c => {
                arr.push(eval(`\"${c}\" ${result.operator} \"${result.value}\"`));

                if (multipleResult.length > 0) {
                  var inc = multipleResult.find(i => { return i.fieldStep == fromInstance.formfield.step && i.value === c });
                  if (inc != undefined) {
                    arr.push(eval(`\"${c}\" ${inc.operator} \"${inc.value}\"`));
                  }
                }
              });

              var contains = arr.find(a => a === true);

              if (contains != undefined)
                dependency = true;
              else dependency = false;
            }
            //if (eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${result.operator} \"${result.value}\"`)) {
            //   console.log("x", fromInstance.formfield.step, fromInstance.formfield.value, result, arr, dependency)

            if (dependency) {
              this.fields.forEach(f => {
                if (f.dependency.fieldStep === fromInstance.formfield.step && fromInstance.formfield.type === "checkbox" && fromInstance.formfield.settings.multiple) {
                  f.disabled = true;
                  fromInstance.formfield.value.forEach(c => {
                    if (eval(`\"${c}\" ${f.dependency.operator} \"${f.dependency.value}\"`)) {
                      f.disabled = false;
                      var depp = this.fields.find(a => { return a.step == f.dependency.fieldStep && a.name == f.dependency.fieldName })
                      if (depp.disabled) {

                        fromInstance.form.value[f.name] = "";
                        f.disabled = true;

                        this.form.forEach(frm => {
                          if (frm.formfield.name === f.name) {

                            frm.setValue(f.name, "");
                          }
                        })
                      }
                    }
                    else {
                      if (f.dependency.fieldName == fromInstance.formfield.name) {
                        var rslt = fromInstance.formfield.value.find(i => { return i == f.dependency.value })
                        if (rslt == undefined) {

                          fromInstance.form.value[f.name] = "";

                          var dep = this.dependentValues.find(a => { return a.affectedStep == f.step && a.affectedfield == f.name })
                          if (dep != undefined) {
                            fromInstance.form.value[dep.affectedfield] = "";
                            this.form.forEach(frm => {
                              if (frm.formfield.step === dep.affectedStep && frm.formfield.name === dep.affectedfield) {

                                frm.setValue(dep.affectedfield, "");
                              }
                            })
                            var depp = this.fields.find(a => { return a.dependency.fieldStep == dep.affectedStep && a.dependency.fieldName == dep.affectedfield })
                            if (depp != undefined) {
                              depp.disabled = false;
                            }

                          }


                        }

                      }

                    }

                  });
                }
                else if (f.dependency.value === fromInstance.form.value[fromInstance.formfield.name] && f.dependency.fieldStep === result.fieldStep) {
                  f.disabled = false;
                }
                else if (fromInstance.form.value[fromInstance.formfield.name] != "" && dependency && f.dependency.fieldStep === result.fieldStep) {
                  f.disabled = false;
                }

              })
            }
            else {

              this.fields.forEach(f => {

                if (f.dependency.fieldName === fromInstance.formfield.name && f.type == "checkbox") {
                  var checkdependent = eval(`\"${fromInstance.form.value[fromInstance.formfield.name]}\" ${f.dependency.operator} \"${f.dependency.value}\"`);

                  if (!checkdependent && fromInstance.form.value[fromInstance.formfield.name] != "") {
                    this.dataservice.dependent = true;

                    f.value = false;
                  }
                }


                if (f.dependency.value != fromInstance.form.value[fromInstance.formfield.name] && f.dependency.fieldStep === result.fieldStep) {
                  f.disabled = true;

                  var dep = this.dependentValues.find(a => { return a.affectedStep == f.step && a.affectedfield == f.name })
                  if (dep != undefined) {
                    fromInstance.form.value[dep.affectedfield] = "";
                    this.form.forEach(frm => {
                      if (frm.formfield.step === dep.affectedStep && frm.formfield.name === dep.affectedfield) {

                        frm.setValue(dep.affectedfield, "");
                      }
                    })
                  }
                }
                else if (fromInstance.form.value[fromInstance.formfield.name] != "" && !dependency && f.dependency.fieldStep === result.fieldStep) {
                  f.disabled = true;
                  var dep = this.dependentValues.find(a => { return a.affectedStep == f.step })
                  if (dep != undefined) {
                    fromInstance.form.value[dep.affectedfield] = "";
                    this.form.forEach(frm => {
                      if (frm.formfield.step === dep.affectedStep) {

                        frm.setValue(dep.affectedfield, "");
                      }
                    })
                  }
                }


              })
            }
          }
          else {

          }
        }

      });

      this.dataservice.formData = this.form;
      
    }

  }

  loadData(): void {
    this.rightsdata = this.dataservice.right_fields;
    this.dataservice.fieldlist = [];
    var sid = this.route.snapshot.params.schemaId;
    var rid = this.route.snapshot.params.recordId;
    if (!rid) rid = this.id;
    //get study schema
    this.api.getSchema(sid).subscribe(response => {

      this.data = response.schema;
      this.fields = response.schema;

      this.dataservice.alldatafield = response.schema;

      if (rid) {
        this.isNew = false;
      }

      response.schema.forEach(rlist => {

        if (rlist.type == "group") {
          var groupList = rlist.group.filter(g => { return g.dependency && g.dependency.value != "" && g.dependency.value != null })
          if (groupList.length > 0) {
            var grup = rlist.step;
            groupList.forEach(_group => {
              if (_group.dependency.groupStep === grup) {
                this.dependentValues.push({ operator: _group.dependency.operator, fieldStep: _group.dependency.fieldStep, groupStep: _group.dependency.groupStep, value: _group.dependency.value, affectedStep: _group.step, affectedfield: _group.name });
                // this.dependentValues.push(gg.dependency);
              }

            })
          }
        }
        else {
          if (rlist.dependency && rlist.dependency.value != "" && rlist.dependency.value != null)
            this.dependentValues.push({ operator: rlist.dependency.operator, fieldStep: rlist.dependency.fieldStep, groupStep: rlist.dependency.groupStep, value: rlist.dependency.value, affectedStep: rlist.step, affectedfield: rlist.name });
          // this.dependentValues.push(rlist.dependency);
        }
      })

      this.fields.forEach(_values => {

        _values.disabled = this.isNew ? false : true;
        if (_values.type === "slider") {
          _values.settings.disabled = this.isNew ? false : true;
        }
        if (_values.type === "radiobutton" || _values.type === "dropdown") {
          _values.options.forEach(_value => {
            if (_value.id === _values.settings.option) {
              _values.options = _value.items;
            }
          })

        }
        if (_values.type === "time") {
          _values.value = new Date().getHours() + ':' + new Date().getMinutes();
        }

        if (_values.type === "randomization") {
          var _guid = Guid.create().toString();
          _guid = _guid.toLocaleUpperCase().replace("-", "").replace("-", "").replace("-", "").replace("-", "")
          _values.value = _guid;
        }
        if (_values.type === "remark") {
          _values.value = _values.question;
        }
        if (_values.type === "summary") {
          _values.value = _values.settings.summary;
        }


        if (_values.type === "group") {
          _values.group.forEach(g => {
            var included = this.dependentValues.find(i => { return i.affectedfield === g.name })
            g.disabled = included != undefined ? true : false;
            //g.disabled = g.dependency.value == "" ? false : true;               
          })
        }
        else
          _values.disabled = _values.dependency ? _values.dependency.value == null ? false : _values.dependency.value == "" ? false : true : false;
      });

      let result = false;

      if (rid) {
        this.isNew = false;
        //get study record data
        this.api.getRecord(rid).subscribe(responsedata => {
          this.studyOrg = responsedata.organization;
          this.createdBy = responsedata.createdBy;
          this.creationdate = responsedata.creationDate;
          this._recordname = responsedata.name;

          this.data = responsedata.values;
          this.dataservice.alldatafield = responsedata.values;

          this.form.forEach(_values => {

            _values.setValue(_values.formfield.name, responsedata.values[_values.formfield.name]);
            if (_values.formfield.type == "numberdate") {

              _values.formfield.value = responsedata.values[_values.formfield.name];
            }

            if (_values.formfield.type == "checkbox") {

              _values.formfield.value = responsedata.values[_values.formfield.name];
              _values.newGroup(responsedata.values[_values.formfield.name]);

              this.fields.forEach(_fieldvalues => {

                if (_fieldvalues.name === _values.formfield.name && _fieldvalues.type == "checkbox") {


                  _fieldvalues.value = responsedata.values[_values.formfield.name];
                }
              }
              );

            }
            result = true;
          });


        });

      }
      setTimeout(
        () => this.ui.spin$.next(false), 1000
      )
    });

    this.dataservice.formData = this.form;
  }

  cancelConfirm() {

    var title = new BehaviorSubject<string>('');
    var text = new BehaviorSubject<string>('');
    var okButton = new BehaviorSubject<string>('');
    var cancelButton = new BehaviorSubject<string>('');

    this.translate.get('alertModal.studyCancel.title').subscribe((translateTitle: string) => { title.next(`${translateTitle}`) });
    this.translate.get('alertModal.studyCancel.text').subscribe((translateText: string) => { text.next(`${translateText}`) });

    this.translate.get('alertModal.buttons.ok').subscribe((translateOk: string) => { okButton.next(`${translateOk}`) });
    this.translate.get('alertModal.buttons.cancel').subscribe((translateCancel: string) => { cancelButton.next(`${translateCancel}`) });

    Swal.fire({
      title: title.getValue(),
      text: text.getValue(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: okButton.getValue(),
      cancelButtonText: cancelButton.getValue()
    }).then((result) => {
      if (result.value) {
        this.goBack();
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }

  editform(): void {
    this.fields.forEach(_values => {
      _values.disabled = false;
      if (_values.type === "slider") {
        _values.settings.disabled = false;
      }
      if (_values.type === "radiobutton" || _values.type === "dropdown") {
        _values.options.forEach(_value => {
          if (_value.id === _values.settings.option) {
            _values.options = _value.items;
          }
        })

      }

    });
    this.isEdit = true;
  }

  submit(value: { [text: string]: any }) {
    // console.log(value);

  }
  goBack(): void {
    this.location.back();
  }

  save() {
    if (!this.completed && !this.saveInProgress && !this.autoSaveInProgress) {
      this.ui.spin$.next(true);
      this.saveInProgress = true;
      this.completed = true;
      var data = {};
      var value: any;
      var choicecontrol = 0;
      let dependencycontrol = false;
      let incluedDependentReq = false;
      let ischeckcomponent = false;
      let validcontrol = false;
      let checkvalidcontrol = false;
      let fielddependency = false;

      var validateTest = [];
      this.form.forEach(fromInstance => {
        fielddependency = false;
        checkvalidcontrol = false;
        incluedDependentReq = false;
        let name = fromInstance.formfield.name;
        //let aa = fromInstance.form.invalid;
        if (fromInstance.formfield.type == "checkbox" && fromInstance.formfield.settings.multiple) {
          // value = fromInstance.formfield.value;
          value = {};

          if (fromInstance.formfield.value.length === undefined || fromInstance.formfield.value.length === 0) //true || false
          {
            value[name] = "";
            fromInstance.formfield.value = "";
          }
          else {
            value[name] = fromInstance.formfield.value;
          }


        }
        else if (fromInstance.formfield.type == "numberdate") {
          value = {};
          value[name] = fromInstance.formfield.value;

        }
        else if (fromInstance.formfield.type == "number")
        {
          var minvalue = Number(fromInstance.formfield.settings.minvalue)
          var currentvalue = Number(fromInstance.form.value[`${fromInstance.formfield.name}`] == "" ? 0 : fromInstance.form.value[`${fromInstance.formfield.name}`])

          if (currentvalue < minvalue) {
            value = {};
            value[name] = fromInstance.formfield.value;
            if (fromInstance.formfield.value == "" && fromInstance.formfield.settings.required)
              validcontrol = true;
          }
          else
            value = fromInstance.form.value;
    
          
        }
        else {
          value = fromInstance.form.value;
        }

        if (fromInstance.form.invalid) {
          if (fromInstance.formfield.settings.multiple && fromInstance.formfield.type == "checkbox" && fromInstance.formfield.value != "") {
            if (fromInstance.formfield.value.length == 0) {
              validcontrol = true; ischeckcomponent = true; checkvalidcontrol = true;
              choicecontrol++;


            }
          }
          else if (fromInstance.formfield.settings.multiple && fromInstance.formfield.type == "checkbox" && fromInstance.formfield.value == "") {
            validcontrol = true; ischeckcomponent = true; checkvalidcontrol = true;
            choicecontrol++;
          }
          else {
            validcontrol = true;
          }


          var result = this.dependentValues.find(i => { return i.affectedfield == fromInstance.formfield.name });
          if (result != undefined && fromInstance.formfield.settings.required && fromInstance.formfield.disabled) {
            dependencycontrol = incluedDependentReq ? false : true;
            if (!dependencycontrol) {
              var taa = fromInstance.formfield;
            }
            fielddependency = incluedDependentReq ? false : true;
          }
          else if (result != undefined && fromInstance.formfield.settings.required && !fromInstance.formfield.disabled) {
            incluedDependentReq = true;
          }


          if (validcontrol && !fielddependency) {
            if (checkvalidcontrol && fromInstance.formfield.type == 'checkbox' && !fromInstance.formfield.disabled) {
              if (fromInstance.groupId != undefined)
                validateTest.push(`${fromInstance.groupId}.${fromInstance.formfield.step}`);
              else
                validateTest.push(fromInstance.formfield.step);
            }
            else if (fromInstance.formfield.type != 'checkbox' && !fromInstance.formfield.disabled) {
              if (fromInstance.groupId != undefined)
                validateTest.push(`${fromInstance.groupId}.${fromInstance.formfield.step}`);
              else
                validateTest.push(fromInstance.formfield.step);
            }
          }

        }

        data[name] = value[name];
      }

      );
      var request = {};
      if (validcontrol && !dependencycontrol) {
        if (ischeckcomponent && choicecontrol > 0) {
          this.completed = false;
          this.saveInProgress = false;
          this.toastr.error('', 'Fill in the required fields ', {
            timeOut: 3000
          });
          return;
        }
        else {
          this.completed = false;
          this.saveInProgress = false;
          this.toastr.error('', 'Fill in the required fields ', {
            timeOut: 3000
          });
          return;
        }


      }
      else if (validcontrol && validateTest.length > 0) {
        this.completed = false;
        this.saveInProgress = false;
        this.toastr.error('', 'Fill in the required fields ', {
          timeOut: 3000
        });
      }
      else {
        if (this.isNew) {
          //let orgCode = this.auth.currentUserValue.organizationCode;
          let _time = new Date().getTime();
          this._recordname = `${this.auth.currentUserValue.organizationCode} - ${Math.floor(_time / 1000)}`; //"R -" + Math.floor(_time / 1000);

          //request = {
          //  name: this._recordname,
          //  organization: null,
          //  studySchema: this.route.snapshot.params.schemaId,
          //  values: data,
          //  version: "v1"
          //}

          //var result = this.api.saveRecord(request).subscribe((response) => {
          //  //todo: sonuc başarılı ise
          //  this.toastr.success('', 'Successfully created');
          //  this.location.back();
          //}, (error) => {/*todo: sonuç başarısız ise*/
          //  this.toastr.error('', 'Error occured', {
          //    timeOut: 3000
          //  });
          //});

          request = {
            name: this._recordname,
            organization: null,
            studySchema: this.route.snapshot.params.schemaId,
            values: data,
            version: "v1"
          }

          var result = this.api.saveRecordWithCounter(this.route.snapshot.params.schemaId, request).subscribe((response) => {
            if (response.success) {
              this.completed = false;
              this.saveInProgress = false;
              setTimeout(() => this.ui.spin$.next(false), 1000)
              //todo: sonuc başarılı ise
              this.toastr.success('', 'Successfully created');
              this.location.back();
            }
            else {
              if (response.message.includes("duplicate key error")) {
                this.completed = false;
                this.saveInProgress = false;
                setTimeout(() => this.ui.spin$.next(false), 1000)
                this.toastr.error('', 'Error occured, please try again', {
                  timeOut: 3000
                });
              }
            }

          }, (error) => {/*todo: sonuç başarısız ise*/
              this.completed = false;
              this.saveInProgress = false;
              setTimeout(() => this.ui.spin$.next(false), 1000)
            this.toastr.error('', 'Error occured', {
              timeOut: 3000
            });
          });

        }
        else {
          request = {
            _id: this.route.snapshot.params.recordId,
            name: this._recordname,
            organization: null,
            studySchema: this.route.snapshot.params.schemaId,
            values: data,
            version: "v1"
          }

          var result = this.api.updateRecord(request).subscribe((response) => {
            this.completed = false;
            this.saveInProgress = false;
            setTimeout(() => this.ui.spin$.next(false), 1000)
            //todo: sonuc başarılı ise
            this.toastr.success('', 'Successfully updated.');
            this.location.back();
          }, (error) => {/*todo: sonuç başarısız ise*/
              this.completed = false;
              this.saveInProgress = false;
              setTimeout(() => this.ui.spin$.next(false), 1000)
            this.toastr.error('', 'Error occured', {
              timeOut: 3000
            });
          });
        }

      }

    }
  }

  toggle(content: HTMLElement, header: HTMLElement) {
    content.classList.toggle('collapse-content');
    header.classList.toggle('toggle');
  }

  autoSave() {
    
    let userOrg = JSON.parse(localStorage.getItem('currentUser'));
    if (!this.isNew && userOrg.organization != this.studyOrg) {      
      this.completed = false;
      this.saveInProgress = false;
      setTimeout(() => this.ui.spin$.next(false), 1000)
      return;
    }
     
    if (!this.saveInProgress) {
      console.log(new Date().toLocaleTimeString())
      try {
        this.saveInProgress = true;
        var data = {};
        var value: any;
        var choicecontrol = 0;
        let dependencycontrol = false;
        let incluedDependentReq = false;
        let ischeckcomponent = false;
        let validcontrol = false;
        let checkvalidcontrol = false;
        let fielddependency = false;

        var validateTest = [];
        if (!this.form) return;
        this.form.forEach(fromInstance => {
          fielddependency = false;
          checkvalidcontrol = false;
          incluedDependentReq = false;
          let name = fromInstance.formfield.name;
          if (fromInstance.formfield.type == "checkbox" && fromInstance.formfield.settings.multiple) {
            value = {};
            if (fromInstance.formfield.value.length === undefined || fromInstance.formfield.value.length === 0) //true || false
            {
              value[name] = "";
              fromInstance.formfield.value = "";
            }
            else {
              value[name] = fromInstance.formfield.value;
            }
          }
          else if (fromInstance.formfield.type == "numberdate") {
            value = {};
            value[name] = fromInstance.formfield.value;
          }
          else if (fromInstance.formfield.type == "number") {
            var minvalue = Number(fromInstance.formfield.settings.minvalue)
            var currentvalue = Number(fromInstance.form.value[`${fromInstance.formfield.name}`] == "" ? 0 : fromInstance.form.value[`${fromInstance.formfield.name}`])
            if (currentvalue < minvalue) {
              value = {};
              value[name] = fromInstance.formfield.value;
              if (fromInstance.formfield.value == "" && fromInstance.formfield.settings.required)
                validcontrol = true;
            }
            else
              value = fromInstance.form.value;


          }
          else {
            value = fromInstance.form.value;
          }
          if (fromInstance.form.invalid) {
            if (fromInstance.formfield.settings.multiple && fromInstance.formfield.type == "checkbox" && fromInstance.formfield.value != "") {
              if (fromInstance.formfield.value.length == 0) {
                validcontrol = true; ischeckcomponent = true; checkvalidcontrol = true;
                choicecontrol++;
              }
            }
            else if (fromInstance.formfield.settings.multiple && fromInstance.formfield.type == "checkbox" && fromInstance.formfield.value == "") {
              validcontrol = true; ischeckcomponent = true; checkvalidcontrol = true;
              choicecontrol++;
            }
            else {
              validcontrol = true;
            }
            var result = this.dependentValues.find(i => { return i.affectedfield == fromInstance.formfield.name });
            if (result != undefined && fromInstance.formfield.settings.required && fromInstance.formfield.disabled) {
              dependencycontrol = incluedDependentReq ? false : true;
              if (!dependencycontrol) {
                var taa = fromInstance.formfield;
              }
              fielddependency = incluedDependentReq ? false : true;
            }
            else if (result != undefined && fromInstance.formfield.settings.required && !fromInstance.formfield.disabled) {
              incluedDependentReq = true;
            }

            if (validcontrol && !fielddependency) {
              if (checkvalidcontrol && fromInstance.formfield.type == 'checkbox' && !fromInstance.formfield.disabled) {
                if (fromInstance.groupId != undefined)
                  validateTest.push(`${fromInstance.groupId}.${fromInstance.formfield.step}`);
                else
                  validateTest.push(fromInstance.formfield.step);
              }
              else if (fromInstance.formfield.type != 'checkbox' && !fromInstance.formfield.disabled) {
                if (fromInstance.groupId != undefined)
                  validateTest.push(`${fromInstance.groupId}.${fromInstance.formfield.step}`);
                else
                  validateTest.push(fromInstance.formfield.step);
              }
            }
          }
          data[name] = value[name];
        }
        );
        var request = {};
        if (validcontrol && !dependencycontrol) {
          if (ischeckcomponent && choicecontrol > 0) {
            this.saveInProgress = false;
            setTimeout(
              () => this.ui.spin$.next(false), 1000
            )
            this.toastr.error('', 'Fill in the required fields ', {
              timeOut: 3000
            });
            return;
          }
          else {
            this.saveInProgress = false;
            setTimeout(
              () => this.ui.spin$.next(false), 1000
            )
            this.toastr.error('', 'Fill in the required fields ', {
              timeOut: 3000
            });
            return;
          }
        }
        else if (validcontrol && validateTest.length > 0) {
          this.saveInProgress = false;
          setTimeout( () => this.ui.spin$.next(false), 1000)
          this.toastr.error('', 'Fill in the required fields ', {
            timeOut: 3000
          });
        }
        else {
          if (this.isNew) {
            let _time = new Date().getTime();
            this._recordname = `${this.auth.currentUserValue.organizationCode} - ${Math.floor(_time / 1000)}`; //"R -" + Math.floor(_time / 1000);
            request = {
              name: this._recordname,
              organization: null,
              studySchema: this.route.snapshot.params.schemaId,
              values: data,
              version: "v1"
            }
            

       
            var result = this.api.saveRecordWithCounter(this.route.snapshot.params.schemaId, request).subscribe((response) => {
              if (response.success) {
                //console.log(response, 'autosave')
                this.id = response.id;
                this.saveInProgress = false;
                //todo: sonuc başarılı ise
                this.toastr.success('', 'Successfully created');
                this.location.replaceState(`/home/study-detail/${this.route.snapshot.params.schemaId}/${response.id}`);
                setTimeout(
                  () => this.ui.spin$.next(false), 1000
                )
                this.loadData()
              }
              else {
                if (response.message.includes("duplicate key error")) {
                  this.saveInProgress = false;
                  setTimeout(() => this.ui.spin$.next(false), 1000)
                  this.toastr.error('', 'Error occured during autosave', {
                    timeOut: 3000
                  });
                }
              }
    
             
            }, (error) => {/*todo: sonuç başarısız ise*/
                this.completed = false;
                this.saveInProgress = false;
                setTimeout(
                  () => this.ui.spin$.next(false), 1000
                )
                this.toastr.error('', 'Error occured', {
                timeOut: 3000
              });
            });

          }
          else {
            request = {
              _id: this.id || this.route.snapshot.params.recordId,
              name: this._recordname,
              organization: null,
              studySchema: this.route.snapshot.params.schemaId,
              values: data,
              version: "v1"
            }

            var result = this.api.updateRecord(request).subscribe((response) => {
              //console.log(response, 'autosave')
              this.saveInProgress = false;
              //todo: sonuc başarılı ise
              this.toastr.success('', 'Successfully updated.');
              this.loadData();
            }, (error) => {/*todo: sonuç başarısız ise*/
              this.saveInProgress = false;
              this.toastr.error('', 'Error occured', {
                timeOut: 3000
              });
            });
          }
        }

      } catch (e) {
        console.error('auto save', e);
      }
    }

  }
}


