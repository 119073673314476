import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';


class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
//export class ResetPasswordComponent implements OnInit {
export class ResetPasswordComponent {
  userForm: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();



  //constructor() { }
  constructor(private fb: FormBuilder) {
    this.initForm();
  }

  initForm() {
    this.userForm = this.fb.group({
      username: '',
      password: '',
      verifyPassword: ''
    }, {
        validator: this.passwordValidator
      })
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('verifyPassword').value;

    return condition ? { passwordsDoNotMatch: true } : null;
  }
  ngOnInit() {
  }

}


