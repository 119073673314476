import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-consent-form-dialog',
  templateUrl: './consent-form-dialog.component.html',
  styleUrls: ['./consent-form-dialog.component.css']
})
export class ConsentFormDialogComponent implements OnInit {

  constructor(private api: ApiService,public dialog: MatDialog, private router: Router, private translate: TranslateService, private auth: AuthenticationService, private toastr: ToastrService,
    private dialogRef: MatDialogRef<ConsentFormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.translate.setDefaultLang(this.auth.currentUserValue.language || "en");
  }
  close() {
    this.dialogRef.close();
   // this.route.navigate(['/login']);
  }
  approve() { 
    var request = {
      _id: this.data.data.id,
      organization: this.data.data.organization,
    }

    var result = this.api.updateConsentConfirm(this.data.data.id).subscribe((response) => {
      this.router.navigate(['/home/dashboard']);
    }, (error) => {
      this.toastr.error('', 'Error occured', {
        timeOut: 3000
      });
    });
   

  
  }

}
