import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {  Array, String } from 'core-js';
import { isString } from 'util';
import { ApiService } from '../service/api.service';
import { roles, Rights } from '../Roles';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import * as _ from 'lodash';


const moment = _rollupMoment || _moment;
const actionMapper = {
  1000: "user-add",
  1001: "user-edit",
  1002: "user-delete",
  1003: "user-list",
  1004: "customer-add",
  1005: "customer-edit",
  1006: "customer-delete",
  1007: "customer-list",
  1008: "licence-add",
  1009: "licence-edit",
  1010: "licence-delete",
  1011: "licence-list",
  1012: "package-add",
  1013: "package-edit",
  1014: "package-delete",
  1015: "package-list",
  1016: "package-purchase",
  1017: "study-add",
  1018: "study-edit",
  1019: "study-delete",
  1020: "study-list",
  1021: "record-add",
  1022: "record-edit",
  1023: "record-delete",
  1024: "record-list",
  1025: "report-add",
  1026: "report-edit",
  1027: "report-delete",
  1028: "report-list",


};

@Injectable({
  providedIn: 'root'
})




export class DataService {
  datavalues = []
  changeformula = ""; formula = ""; formul: number; checkvalues = []; dependent: any;
  calculation: number;
  alldatafield: any;
  fieldlist = [];
  replacedata = "";
  _error = false;
  _errormessage = "";
  studyroles: any;
  selectiondata: any[] = [];
  rights: any;
  new_data = [];
  new_selectiondata = {};
  new_data_add = [];
  new_data_edit = [];
  new_data_del = [];
  right_fields = {};
  studyvalues = [];
  deletedStudyName = false;
  formData: any;
  calculations: any = {};

  constructor(private api: ApiService) { }


  getcalculation() {
    this._error = false;
    this._errormessage = "";
    let _list = {};

    let checkcontrol = false;

    if (this.alldatafield.length == undefined) {

      let new_data = [];

      for (let prop in this.alldatafield) {
        let __data = {};
        __data["name"] = prop;
        __data["value"] = this.alldatafield[prop];
        new_data.push(__data);
      }
      this.alldatafield = new_data;

      this.alldatafield.forEach(alllist_ => {
        if (this.formula.includes("{" + alllist_.name + "}")) {
          _list["{" + alllist_.name + "}"] = alllist_.value;
          this.fieldlist.push(_list);
        }
      })
    }


    this.alldatafield.forEach(alllist => {
      this.datavalues.forEach(_data => {

        for (let d in _data) {
          if ("{" + alllist.name + "}" == d) {
            _list["{" + alllist.name + "}"] = _data[d];
            if (this.fieldlist.length > 0) {

              this.fieldlist.forEach(_field => {
                for (let f in _field) {
                  if (f == d) {
                    _field[f] = _data[d];
                    checkcontrol = true;
                  }
                }
              })

              if (!checkcontrol) {
                this.fieldlist.push(_list);
              }
            }
            else {
              this.fieldlist.push(_list);
            }

          }
        }
      })


    })
    if (this.changeformula != undefined && this.changeformula != "") {
      if (checkcontrol) {
        this.changeformula = this.formula;
      }
      this.fieldlist.forEach(list => {
        for (let v in list) {
          this.changeformula = this.changeformula.replace(new RegExp(v, "gi"), list[v].toString());
        }

      })


      if (!this.changeformula.includes("{")) {
        try {
          this.formul = eval(this.changeformula);
        }
        catch  {
          if (this.calculation != null) {
            this.calculation = null;
          }
          this._error = true;
          this._errormessage = "Error occurred  in calculation - " + this.formula;
        }

        if (!this._error) {
          if (this.formul != undefined) {
            this.calculation = parseFloat(this.formul.toFixed(2))//Math.round(this.formul);
          }
        }

      }

    }

  }

  getcalculation2() {
    this._error = false;
    this._errormessage = "";
    let _list = {};
    let checkcontrol = false;
    if (this.alldatafield.length == undefined) {

      let new_data = [];

      for (let prop in this.alldatafield) {
        let __data = {};
        __data["name"] = prop;
        __data["value"] = this.alldatafield[prop];
        new_data.push(__data);
      }
      this.alldatafield = new_data;

      this.alldatafield.forEach(alllist_ => {
        if (this.formula.includes("{" + alllist_.name + "}")) {
          _list["{" + alllist_.name + "}"] = alllist_.value;
          this.fieldlist.push(_list);
        }
      })
    }

    //this.alldatafield.forEach(alllist => {
    //  this.datavalues.forEach(_data => {

    //    for (let d in _data) {
    //      if ("{" + alllist.name + "}" == d) {
    //        _list["{" + alllist.name + "}"] = _data[d];
    //        if (this.fieldlist.length > 0) {

    //          this.fieldlist.forEach(_field => {
    //            for (let f in _field) {
    //              if (f == d) {
    //                _field[f] = _data[d];
    //                checkcontrol = true;
    //              }
    //            }
    //          })

    //          if (!checkcontrol) {
    //            this.fieldlist.push(_list);
    //          }
    //        }
    //        else {
    //          this.fieldlist.push(_list);
    //        }

    //      }
    //    }
    //  })


    //})

 

  
    this.datavalues.forEach(_data => {

      for (let d in _data) {
        _list[d] = _data[d];
        if (this.fieldlist.length > 0) {

          this.fieldlist.forEach(_field => {
            for (let f in _field) {
              if (f == d) {
                _field[f] = _data[d];
                checkcontrol = true;
              }
            }
          })

          if (!checkcontrol) {
            this.fieldlist.push(_list);
          }
        }
        else {
          this.fieldlist.push(_list);
        }
      }
    })

    Object.keys(this.calculations).forEach(p => {
      
      this.calculations[p].math = this.calculations[p].formula;
      this.fieldlist.forEach(list => {
        
        for (let v in list) {
          let control=true;
          var datachangecontrol = this.datavalues.find(a => { return Object.keys(a).toString() == v })
          if (datachangecontrol != undefined) {
            if (datachangecontrol[v] == null) {
              delete list[v];
              control = false;
              this.calculations[p].value = null;
            }
            else {
              this.calculations[p].math = this.calculations[p].math.replaceAll(v, datachangecontrol[v].toString());
            }
               
          }
          if (control) {
            this.formData.forEach(alllist_ => {

              if (this.calculations[p].math.includes("{" + alllist_.formfield.name + "}")) {
                
                if (alllist_.value[alllist_.formfield.name] == "") {
                  var alldatafieldValue = this.alldatafield.find(i => { return this.calculations[p].math.includes("{" + i.name + "}") && alllist_.formfield.name==i.name})
                  if (alldatafieldValue != undefined) {
                    if (alldatafieldValue.value !="")
                       this.calculations[p].math = this.calculations[p].math.replaceAll("{" + alllist_.formfield.name + "}", alldatafieldValue.value);

                  }
                }
                else
                   this.calculations[p].math = this.calculations[p].math.replaceAll("{" + alllist_.formfield.name + "}", alllist_.value[alllist_.formfield.name]);

              
              }

            })

           
          //  this.calculations[p].math = this.calculations[p].math.replaceAll(v, list[v].toString());
          
          }
            
        }


        //this.alldatafield.forEach(alllist_ => {

        //  if (this.calculations[p].math.includes("{" + alllist_.name + "}")) {

        //    this.calculations[p].math = this.calculations[p].math.replaceAll("{" + alllist_.name + "}", alllist_.value);
        //  }
        //})
      })
      
      if (!this.calculations[p].math.includes("{")) {
  
        try {
          this.calculations[p].value = eval(this.calculations[p].math);

        } catch (e) {
          if (this.calculations[p].value != null) {
            this.calculations[p].value = null;
          }
          this.calculations[p]._error = true;
          this.calculations[p]._errormessage = `Error occurred  in calculation - ${this.calculations[p].formula}`;
          //this._error = true;
          //this._errormessage = "Error occurred  in calculation - " + this.calculations[p].formula ;
        }
        if (!this.calculations[p]._error)//if (!this._error)
        {
          if (this.calculations[p].value != undefined) {
            this.calculations[p].value = parseFloat(this.calculations[p].value.toFixed(2))//Math.round(this.formul);
          }
        }

      }
    })

  }

  getselectiondata() {


    var updated = false;

    if (this.rights.type == "list" && this.new_selectiondata[this.rights.type] != undefined && this.new_selectiondata[this.rights.type].length != this.new_data.length) {
      this.new_data = this.new_selectiondata[this.rights.type]; updated = true;
    }
    if (this.rights.type == "add" && this.new_selectiondata[this.rights.type] != undefined && this.new_selectiondata[this.rights.type].length != this.new_data.length) {
      this.new_data_add = this.new_selectiondata[this.rights.type]; updated = true;
    }
    if (this.rights.type == "edit" && this.new_selectiondata[this.rights.type] != undefined && this.new_selectiondata[this.rights.type].length != this.new_data.length) {
      this.new_data_edit = this.new_selectiondata[this.rights.type]; updated = true;
    }
    if (this.rights.type == "delete" && this.new_selectiondata[this.rights.type] != undefined && this.new_selectiondata[this.rights.type].length != this.new_data.length) {
      this.new_data_del = this.new_selectiondata[this.rights.type]; updated = true;
    }


    this.rights.values.forEach(list => {

      var name = list.name;
      var typename = list.type;

      //  console.log(this.new_data);


      if (this.rights.type == "list") {
        var listcontroldata = this.new_data.find(i => i.name != name);
        var includedata = this.new_data.find(i => i.name == name);



        if (listcontroldata != undefined && listcontroldata.name == name) {
          this.new_data.push(list);
        }
        if (includedata == undefined) {
          this.new_data.push(list);
        }

        if (includedata != undefined && includedata.name == name && includedata.selection.length != list.selection.length) {

          this.new_data.forEach(datalist => {
            if (datalist.name == includedata.name) {
              datalist.selection = list.selection;

            }
          })
        }

        const index = this.new_data.findIndex(_data => _data.selection.length == 0);
        if (index >= 0) {
          this.new_data.splice(index, 1);
        }

      }
      else if (this.rights.type == "add") {
        var listcontroldata = this.new_data_add.find(i => i.name != name);
        var includedata = this.new_data_add.find(i => i.name == name);

        if (listcontroldata != undefined && listcontroldata.name == name) {
          this.new_data_add.push(list);
        }
        if (includedata == undefined) {
          this.new_data_add.push(list);
        }

        if (includedata != undefined && includedata.name == name && includedata.selection.length != list.selection.length) {

          this.new_data_add.forEach(datalist => {
            if (datalist.name == includedata.name) {
              datalist.selection = list.selection;

            }
          })
        }

        const index = this.new_data_add.findIndex(_data => _data.selection.length == 0);
        if (index >= 0) {
          this.new_data_add.splice(index, 1);
        }
      }
      else if (this.rights.type == "edit") {
        var listcontroldata = this.new_data_edit.find(i => i.name != name);
        var includedata = this.new_data_edit.find(i => i.name == name);

        if (listcontroldata != undefined && listcontroldata.name == name) {
          this.new_data_edit.push(list);
        }
        if (includedata == undefined) {
          this.new_data_edit.push(list);
        }

        if (includedata != undefined && includedata.name == name && includedata.selection.length != list.selection.length) {

          this.new_data_edit.forEach(datalist => {
            if (datalist.name == includedata.name) {
              datalist.selection = list.selection;

            }
          })
        }

        const index = this.new_data_edit.findIndex(_data => _data.selection.length == 0);
        if (index >= 0) {
          this.new_data_edit.splice(index, 1);
        }
      }
      else if (this.rights.type == "delete") {
        var listcontroldata = this.new_data_del.find(i => i.name != name);
        var includedata = this.new_data_del.find(i => i.name == name);

        if (listcontroldata != undefined && listcontroldata.name == name) {
          this.new_data_del.push(list);
        }
        if (includedata == undefined) {
          this.new_data_del.push(list);
        }
        if (includedata != undefined && includedata.name == name && includedata.selection.length != list.selection.length) {

          this.new_data_del.forEach(datalist => {
            if (datalist.name == includedata.name) {
              datalist.selection = list.selection;

            }
          })
        }
        const index = this.new_data_del.findIndex(_data => _data.selection.length == 0);
        if (index >= 0) {
          this.new_data_del.splice(index, 1);
        }
      }




    })

    var data = this.rights.type == "list" ? this.new_data : this.rights.type == "add" ? this.new_data_add : this.rights.type == "edit" ? this.new_data_edit : this.new_data_del;

    if (!updated) {
      this.new_selectiondata[this.rights.type] = data;
      //this.new_selectiondata = {
      //  type: this.rights.type, data: data
      //};
    }


    if (this.new_selectiondata[this.rights.type].length == 0) {
      delete this.new_selectiondata[this.rights.type];
    }

    delete this.new_selectiondata["data"];
  }

  saveStudyRights(): any {
    // console.log(this.new_selectiondata);
    return this.new_selectiondata;
  }

  getRightFieldList(rights: any) {

    for (var x = 0; x <= Object.keys(actionMapper).length; x++) {
      this.right_fields[Object.values(actionMapper)[x]] = false;
    }
    rights.forEach(rightlist => {
      this.right_fields[actionMapper[rightlist]] = true;
    })

  }

  getRights(roleid: string): any {
    let notFoundSystemRole = true;
    this.api.getsystemrole().subscribe(rolelist => {

      rolelist.forEach(_role => {
        if (_role.id == roleid) {
          notFoundSystemRole = false;
          return _role.rights;

        }
      })
      if (notFoundSystemRole) {
        this.api.getRole(roleid).subscribe(responsedata => {
          return responsedata.rights;
        });
      }

    });
  }


  roleMatch(rights): boolean {
    var isMatch = false;
    rights.forEach(element => {
      if (actionMapper.hasOwnProperty(element)) {
        isMatch = true;

      }
    });
    return isMatch;
  }


  getStudyRights() {

    this.api.getStudyRights().subscribe(studylist => {
      if (studylist != null) {
        studylist.result.forEach(_list => {
          if (_list.values["list"].length > 0) {
            _list.values["list"].forEach(_values => {
              if (!this.studyvalues.includes(_values.selection)) {
                this.studyvalues.push(_values.selection);
              }

            })
          }
        })
      }

    })


  }


  getLastWeek() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }
  getLastWeekEpoch() {
    var lastweek = this.getLastWeek();
    return moment(lastweek, 'YYYY MM DD').unix();
  }
}
