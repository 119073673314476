import { Component, OnInit} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Fields } from "../Fields";
import { rendererTypeName } from '@angular/compiler';
import { DataService } from '../service/data.service';

  
@Component({
  selector: "app-checkbox",
  template: `
        <div [formGroup]="group" class="inline" >
     
          <mat-checkbox *ngIf="!field.settings.multiple" [formControlName]="field.name" required="{{field.settings.required}}" [disabled]="field.disabled" ngDefaultControl></mat-checkbox>
          <div *ngIf="field.settings.multiple" >
          <mat-checkbox  *ngFor="let _field of field.settings.choices" [value]="_field.value" (change)="onChange($event,field.value)" [checked]="CheckControl(_field.value,field.value)"
             [formControlName]="field.name" required="{{field.settings.required}}" [disabled]="field.disabled">{{_field.key}}</mat-checkbox>
          </div>
          <mat-hint  class="hint-container" [innerHTML]="field.settings.info | safeHtml" *ngIf="field.settings.info != null" ></mat-hint>
        </div>
        `,
  styles: ['.inline { display: block !important;  justify-content: flex-start; margin-bottom: 0;} .mat-checkbox { display: flex!important;} .hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class CheckboxComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  checkresult: boolean;
  public choicevalues: any = [];
  public choiceindex: any = [];

  constructor(private data: DataService) {
  }
  ngOnInit() {
    
    this.field.value = false;

    if (this.data.dependent)
      this.choicevalues = [];
  }

  CheckControl(_value: string, arr) {
    
      if (arr != "" && arr != undefined) {
        if (arr.length != 0) {
          for (var i = 0; i < arr.length; i++) {
            if (arr.includes(_value)) {
              if (arr[i].indexOf(_value) === -1 ? false : true) {
                return arr[i].indexOf(_value) === -1 ? false : true;
              }
            }

          }
        }
        else {
          return false;
        }


      }
      else {
        return false;
      }


  

}

  onChange(event, fieldvalues) {
    
    this.field.value = false;
    if (fieldvalues.length >= 1) {
      this.choicevalues = fieldvalues;
     
    }
  
    if (this.data.dependent) {
      this.choicevalues = [];
    }
      
    if (event.checked) {
      this.data.dependent = false;
      if (!this.choicevalues.includes(event.source.value)) {
        this.choicevalues.push(event.source.value);       
      }
    }
    else {
      let _index = this.choicevalues.indexOf(event.source.value);
      this.choicevalues.splice(_index, 1);
      this.data.dependent = false;

    }

    this.field.value = this.choicevalues;
    this.data.checkvalues = this.choicevalues;
  }


}
