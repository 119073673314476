import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { TableDataSource } from './TableDataSource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from 'protractor';
import { tap, first } from 'rxjs/operators';
import { Response } from 'selenium-webdriver/http';
import { TranslationModel } from './TranslationModel';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../service/api.service';
import { DataSource } from '@angular/cdk/table';

export interface StudySchema {
  id: string;
  studyName: string;
  version: string;
  creationDate: number;
  modifiedDate: number;
};
export interface StudySchemaResponse{
  count: number;
  data: StudySchema[];
};
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements OnInit {
  [x: string]: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TableDataSource;
  displayedColumns: string[] = ['studyName', 'version', 'creationDate', 'modifiedDate'];
  columsToDisplay: string[] = this.displayedColumns.slice();
  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private api: ApiService
  ) {

  }

  arrTranslation: string[];
  private url = 'https://localhost:5001/api/sampledata?culture=en-US&&text=newstudy';
  getData(): Observable<TranslationModel> {
    return this.http.get<TranslationModel>(this.url);
  }

  ngOnInit() {
    console.log(this.route.params);

    this.api.getSchemas().pipe(first()).subscribe((response: StudySchemaResponse) => {

      this.dataSource = new TableDataSource(response.data,this.paginator, this.sort);
     
    });

    //this.dataSource = new TableDataSource(this.paginator, this.sort);
    //this.getData().subscribe((data: Config) => this.config = {
    //  Name: data['Name'],
    //  Value: data['Value']
    //});

  }
}
