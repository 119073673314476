import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';




export interface InvestigatorList {
  doctor: string;
  organization: string;
  logo: string;

}
const investigators: InvestigatorList[] = [
  {
    doctor: 'Dr. Abdullah Demirtaş',
    organization: 'Erciyes Üniversitesi',
    logo: 'erciyes_unv.png'
  },
  {

    doctor: 'Dr. Ali İhsan Taşçı',
    organization: 'İstanbul Bakırköy Dr. Sadi Konuk EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Alper Ötünçtemur',
    organization: 'Prof. Dr. Cemil Taşçıoğlu Şehir Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Atilla Semerciöz',
    organization: 'İstanbul Bağcılar EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Buğra Çetin',
    organization: 'İstanbul Gaziosmanpaşa EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Berat Cem Özgür',
    organization: 'Sağlık Bilimleri Üniversitesi Ankara SUAM Üroloji Kliniği',
    logo: 'sbu_ankara_unv.png'
  },
  {

    doctor: 'Dr. Cem Sönmez',
    organization: 'İstanbul Şişli Hamidiye Etfal EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Cevher Ersöz',
    organization: 'Bezmiâlem Vakıf Üniversitesi Hastanesi',
    logo: 'bezmialem_unv.png'
  },
  {

    doctor: 'Dr. Çağatay Çiçek',
    organization: 'Bursa Uludağ Üniversitesi',
    logo: 'bursa_uludag_unv.png'
  },
  {

    doctor: 'Dr. Ercan Yuvanç',
    organization: 'Kırıkkale Üniversitesi Tıp Fakültesi',
    logo: 'kirikkale_unv.png'
  },
  {

    doctor: 'Dr. Erkan Erkan',
    organization: 'İstanbul EA Hastanesi',
    logo: 'istanbul_ea.png'
  },
  {

    doctor: 'Dr. Eyüp Dil',
    organization: 'Recep Tayyip Erdoğan Üniversitesi Tıp Fak. Üroloji AD',
    logo: 'rte_unv.jpg'
  },
  {

    doctor: 'Dr. Faruk Özgör',
    organization: 'Sultangazi Haseki EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Ferhat Yakup Suçeken',
    organization: 'İstanbul Ümraniye EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Kadir Önem',
    organization: 'Samsun 19 Mayıs Üniversitesi SUAM',
    logo: 'ondokuzmayis_unv.png'
  },
  {

    doctor: 'Dr. Koray Kırdağ',
    organization: 'Samsun Üniversitesi Tıp Fakültesi',
    logo: 'samsun_unv.png'
  },
  {

    doctor: 'Dr. Mert Ali Karadağ',
    organization: 'Kayseri Şehir EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Metin İshak Öztürk',
    organization: 'İstanbul Haydarpaşa Numune EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Murat DEMİR',
    organization: 'Van Yüzüncü Yıl Üniversitesi',
    logo: 'yuzuncu_yil_unv.png'
  },
  {

    doctor: 'Dr. Oktay Akça',
    organization: 'Kartal Dr. Lütfi Kırdar Şehir Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Ömer Yılmaz',
    organization: 'İstanbul Sultan 2. Abdülhamid Han EA Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Özgür Efiloğlu',
    organization: 'İstanbul Medeniyet Üniversitesi Göztepe EA Hastanesi',
    logo: 'istanbul_medeniyet_unv.png'
  },
  {
    doctor: 'Dr. Özkan Polat',
    organization: 'Atatürk Üniversitesi',
    logo: 'ataturk_unv.png'
  },
  {

    doctor: 'Dr. Sedat Taştemur',
    organization: 'Ankara Şehir Hastanesi',
    logo: 'saglik_bakanligi.png'
  },
  {

    doctor: 'Dr. Selami Albayrak',
    organization: 'İstanbul Medipol Üniversitesi',
    logo: 'istanbul_medipol_unv.png'
  },
  {

    doctor: 'Dr. Tuncay Toprak',
    organization: 'İstanbul Fatih Sultan Mehmet EA Hastanesi',
    logo: 'fsm.png'
  },
  {

    doctor: 'Dr. Tunç Ozan',
    organization: 'Fırat Üniversitesi',
    logo: 'firat_unv.png'
  },
  {

    doctor: 'Dr. Yiğit Akın',
    organization: 'İzmir Katip Çelebi Üniversitesi',
    logo: 'izmir_katip_celebi_unv.png'
  },
]

@Component({
  selector: 'app-investigators',
  templateUrl: './investigators.component.html',
  styleUrls: ['./investigators.component.css']
})

export class InvestigatorsComponent implements OnInit {

  dataSource = investigators;
  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
  }
  logout() {
    this.authService.logout();
  }








}
