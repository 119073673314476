import { Component, OnInit, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Fields } from '../../Fields';


const moment = _rollupMoment || _moment;


export var MY_FORMATS = {
  parse: {   
    dateInput: 'DD/MM/YYYY' ,
  },
  display: {
    dateInput: 'DD/MM/YYYY' ,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  @Input() data: Fields;


  @Output() date2: EventEmitter<any> = new EventEmitter<any>();
  date = new FormControl(moment().format('L'));



  constructor() { }

  ngOnInit() {   
    MY_FORMATS.parse.dateInput = this.data.settings.dateformat;
    MY_FORMATS.display.dateInput = this.data.settings.dateformat;
 

  }

  

  change(dateEvent) {
    this.date2.emit(dateEvent.value)
   
    
  }

}
