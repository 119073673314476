import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '';

  constructor() {  }
}
