import { ElementRef, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableSource } from '../data/tablesource';
import { ApiService } from '../service/api.service';
import { DataTableComponent } from '../data/datatable.component';
import { first, debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { merge } from "rxjs/observable/merge";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { trigger, transition, style, state, animate, query, stagger, keyframes, animateChild } from "@angular/animations";
import { Location } from '@angular/common';
import { ExcelService } from '../service/excel.service';
import { __values } from 'tslib';
import { Array } from 'core-js';
import { join } from 'path';
import { DataService } from '../service/data.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as _ from 'lodash';
import { Sort } from '@angular/material';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.css'],
  animations: [
    trigger(':hover', [
      state(':hover', style({
        transform: 'rotate(0deg)'
      })),

      state('state2', style({
        transform: 'rotate(225deg)'
      })),
      transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class RecordsComponent extends DataTableComponent implements OnInit, AfterViewInit {
  rightsdata = {};
  excelexport: boolean;
  schemaId: string;
  current: string = "state1";
  constructor(private location: Location, private route: ActivatedRoute, private api: ApiService, private excelService: ExcelService, private dataservice: DataService, private toastr: ToastrService, private translate: TranslateService) {
    super(['name', 'version', 'createdInfo', 'modifiedInfo', 'creationDate', 'modifiedDate', "actions"]);
  }
  ngOnInit() {
    this.load();
  }
  refresh() {
    this.load();
  }
  load() {
    if (!localStorage.getItem('autosave_interval')) {
      this.api.getAutoSaveInterval().subscribe(interval => {
        localStorage.setItem('autosave_interval', interval);
      })
    }

    this.schemaId = this.route.snapshot.paramMap.get('schemaId');
    this.rightsdata = this.dataservice.right_fields;
    //this.dataSource = new TableSource('record-list', this.api,null,this.schemaId);
    this.dataSource = new TableSource('record-list', this.api, this.schemaId);
    this.dataSource.loadData('', "creationDate desc", 0, 10);

    if (this.schemaId) {

      this.api.getRecords(this.schemaId,'', 1, 10, "creationDate desc").subscribe(recordlist => {
        var records = recordlist.data.filter(i => { return i.deleted == false; })

        if (recordlist.count > 0 && records.length > 0) {
          if (this.rightsdata["record-add"]) {
            this.excelexport = true;
          }
          else { this.excelexport = false; }

        }
        else { this.excelexport = false; }
      })
    }
  }
  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    }

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;

            this.loadDataPage();
          })
        )
        .subscribe();
    }

    if (this.sort) {
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => this.loadDataPage())
        )
        .subscribe();
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => {
          this.pageAndSortLoad();
        })
      )
      .subscribe();


  }

  pageAndSortLoad() {
    let sort = this.sort.active ? `${this.sort.active} ${this.sort.direction.toString() || 'asc'}` : "creationDate desc";
    this.dataSource = new TableSource('record-list', this.api, this.schemaId);
    this.dataSource.loadData('', sort, this.paginator.pageIndex, this.paginator.pageSize);
  }
  sortData(sort: Sort) {
    if (!sort.active || sort.direction == '') {
      return;
    }
    else
      this.pageAndSortLoad();

  }
  navigateBack() {
    this.location.back();
  }
  changeState() { this.current = (this.current === "state1" ? "state2" : "state1"); }
  searchFilter(search: string): void {
    this.dataSource = new TableSource('record-list', this.api, this.schemaId);
    this.dataSource.loadData(search, "creationDate desc", 0, 10); 
  }
  exportAsXLSX(schemaId: string): void {
    let data = [];
    let studyname: string;
    let studydata = []; let studyquestion = [];
    if (schemaId != "") {
      //this.api.getSchema(schemaId).subscribe(_study => {
      //  studyname = _study.studyName;
      //})
      studyname = "CREMS";

      this.api.getSchema(schemaId).subscribe(studyresponse => {        
        let _studydata = {};
        let _studyquestion = {};

        studyresponse.schema.forEach(studylist => {
          if (studylist.type == "group") {
            studylist.group.forEach(g => {
              studyquestion.push({ step: `${studylist.step}.${g.step}`, name: g.name, value: g.question, type: g.inputType });
              var unit = studylist.settings.unit == undefined ? '' : studylist.settings.unit == "" ? '' : studylist.settings.unit;
              if (unit != "") {
                studydata.push({ name: studylist.name, value: unit });
              }
            })
          }
          else {
            //_studyquestion[studylist.name] = studylist.question;
            studyquestion.push({ step: `${studylist.step}`, name: studylist.name, value: studylist.question, type: studylist.inputType });
            var unit = studylist.settings.unit == undefined ? '' : studylist.settings.unit == "" ? '' : studylist.settings.unit;
            if (unit != "") {
              //_studydata[studylist.name] = unit;
              //studydata.push(_studydata);
              studydata.push({ name: studylist.name, value: unit });
            }
          }



        })

        this.api.getRecordList(schemaId, "creationDate desc").subscribe(response => {          
          var responseData = response.result.filter(i => { return i.deleted == false })

          var mapData = _.map(responseData, function (x) {
            return _.assign(x, {
              namecode: x.name.split(" -")[0]
            });
          });


          responseData = _.orderBy(mapData, ['name', 'creationDate'], ['asc', 'desc']);
          //response.data.forEach(list => {
          responseData.forEach(list => {
            for (let p in list.values) {

              if (list.values[p]) {

                if (list.values[p].hasOwnProperty('numbervalue')) {

                  let newDate = new Date(list.values[p].datevalue).toLocaleDateString('tr-TR');
                  list.values[p] = list.values[p].numbervalue + " - " + newDate;
                }
              }
            }

            let new_data = list.values;

            let alldata = [];
            let _data = {};
            if (Object.keys(list.values).length === 0 && Object.getPrototypeOf(list.values) === Object.prototype) {
              _data[" "] = list.name;
              alldata.push({ step: "", variablename: "", question: "", value: list.name });
              studyquestion.forEach(s => {
                alldata.push({ variablename: s.name, question: s.value, value: "" });
                _data[s.value] = "";
              })
            }
            else {
              for (let prop in new_data) {
                var includedata = studyquestion.find(i => i.name == prop);
                if (includedata != undefined) {
                  if (includedata.type == "date") {

                    if (new_data[prop] != null) {
                      let _newDate = new Date(new_data[prop]).toLocaleDateString('tr-TR');
                      new_data[prop] = _newDate;
                    }

                  }
                }
                else
                  new_data[prop] = "";

                _data[" "] = list.name;;
                
                var includedAlldata = alldata.find(a => { return a.value == list.name })
                if (includedAlldata == undefined)
                  alldata.push({ step: "", variablename: "", question: "", value: list.name });
                if (Array.isArray(new_data[prop])) {
                  var parsed = new_data[prop].join();
                  _data[includedata.value] = parsed;
                  alldata.push({ step: includedata.step, variablename: includedata.name, question: includedata.value, value: parsed });

                }
                else {
                  if (studydata.length == 0) {
                    _data[includedata.value] = new_data[prop];
                    alldata.push({ step: includedata.step, variablename: includedata.name, question: includedata.value, value: new_data[prop] });
                  }
                  else {
                    var _includedata = studydata.find(i => i.name == prop);
                    if (_includedata != undefined) {
                      _data[includedata.value + " (" + _includedata.value + ")"] = new_data[prop];
                      alldata.push({ step: includedata.step, variablename: includedata.name, question: includedata.value + " (" + _includedata.value + ")", value: new_data[prop] });
                    }
                    else {
                      alldata.push({ step: includedata.step, variablename: includedata.name, question: includedata.value, value: new_data[prop] });
                      _data[includedata.value] = new_data[prop];
                    }
                  }



                  //studydata.forEach(listdata => {
                  //  debugger
                  //  for (let p in listdata) {
                  //    debugger
                  //    if (p == prop) {
                  //      _data[includedata.value + " (" + listdata[p] + ")"] = new_data[prop];
                  //    }
                  //    else {
                  //      _data[includedata.value] = new_data[prop];
                  //    }
                  //  }

                  //})

                }

              }
            }

            var prefix = "";
            console.log("alldata", alldata)
            var transformResult = _.chain(alldata).transform((r, i) => {

              if (i.step != undefined) {
                if (!r[`${i.step}.${i.question}${prefix}`]) {
                  r[`${i.step}.${i.question}${prefix}`] = i.value;
                }
                else {
                  prefix += '_';
                  r[`${i.step}.${i.question}${prefix}`] = i.value;
                }
              }

            }).value()

            data.push(transformResult);
            //data.push(_data);
            console.log("data", data)
          })


          if (studyname != "" && studyname != null && studyname != undefined && data.length > 0) {
             this.excelService.exportAsExcelFile(data, studyname.replace(" ", ""));
          }


        })
      })


    }
  }



  deleteConfirm(record: string, recordName: string): void {

    var title = new BehaviorSubject<string>('');
    var text = new BehaviorSubject<string>('');
    var okButton = new BehaviorSubject<string>('');
    var cancelButton = new BehaviorSubject<string>('');

    this.translate.get('alertModal.delete.title').subscribe((translateTitle: string) => { this.translate.defaultLang == "tr" ? title.next(`${recordName} ${translateTitle}`) : title.next(`${translateTitle} ${recordName} ?`) });
    this.translate.get('alertModal.delete.text').subscribe((translateText: string) => { text.next(`${translateText}`) });

    this.translate.get('alertModal.buttons.ok').subscribe((translateOk: string) => { okButton.next(`${translateOk}`) });
    this.translate.get('alertModal.buttons.cancel').subscribe((translateCancel: string) => { cancelButton.next(`${translateCancel}`) });
    var _this = this;
    Swal.fire({
      title: title.getValue(),
      text: text.getValue(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: okButton.getValue(),
      cancelButtonText: cancelButton.getValue()
    }).then((result) => {
      if (result.value) {
        _this.deleteRecord(record);
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }
  deleteRecord(record: string): void {
    if (this.schemaId) {
      this.api.getRecordList(this.schemaId, "creationDate desc").subscribe(recordlist => {
        if (recordlist.result.length > 0) {
          var result = recordlist.result.find(i => { return i.id === record });
          if (result != undefined) {
            result.deleted = true;

            var request = {
              _id: result.id,
              name: result.name,
              deleted: true,
              organization: result.organization,
              studySchema: result.studySchema,
              values: result.values,
              version: result.version
            }
            var _this = this;
            var _this2 = this;
            _this2.api.getRecord(result.id).subscribe(responsedata => {
              var updateResult = this.api.updateRecord(request).subscribe((response) => {
                //todo: sonuc başarılı ise
                if (response.success) {
                  this.toastr.success('', 'Successfully deleted.');
                  this.load();
                }
                else {
                  this.toastr.error('', 'Error occured', {
                    timeOut: 3000
                  });
                }


              }, (error) => {/*todo: sonuç başarısız ise*/
                this.toastr.error('', 'Error occured', {
                  timeOut: 3000
                });
              });


            });

          }
        }

      })
    }



  }

  getUserInfo(u: Array<any>) {
    if (u.length > 0)
      return `${u[0].firstName} ${u[0].lastName}`;
    else
      return '';
  }
}
