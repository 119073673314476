import { Component, OnInit,Output,EventEmitter,Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fields } from "../Fields";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: "app-group",
  template: `

     <div>
          <div id="list3">
            <div class="field-group-box" *ngFor="let items of field.group" >
  <div class="droppedItem">
                <div class="row">
                 <app-dynamic-form [formfield]="items" [groupId] ="field.step"></app-dynamic-form>
                </div>
  </div>
              </div>
            </div>
        </div>

`,
  styles: ['.field-group-box {  padding: 20px 120px 20px 20px; border-bottom: solid 1px #ccc; color: rgba(0, 0, 0, 0.87);display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;cursor: move;background: white;font-size: 14px; }.dropItem{width: 100%;position: relative;margin: 20px 0px 20px 10px;} .hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class GroupComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }




}
