import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormcontrolsService {

  constructor() { }

  GetFieldType(field: any): any {
    let fieldtype: {};
    if (field.type === "slider") {
      fieldtype = {
        minvalue: [field.settings == undefined ? 0 : field.inputType != field.type ? 0 : field.settings.minvalue == "" ? 0 : field.settings.minvalue],
        maxvalue: [field.settings == undefined ? 9999 : field.inputType != field.type ? 9999 : field.settings.maxvalue == "" ? 9999 : field.settings.maxvalue],
        increment: [field.settings == undefined ? 1 : field.inputType != field.type ? 1 : field.settings.increment == "" ? 1 : field.settings.increment],
        invert: [],
        vertical: [field.settings == undefined ? "false" : field.inputType != field.type ? "false" : field.settings.vertical ? "true" : "false"],
        unit: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.unit == "" ? '' : field.settings.unit],
        disabled: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.disabled ? true : false],
        thumbLabel: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.thumbLabel ? true : false],
        required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
        link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],

      }
    }
    else if (field.type === "summary") {
      fieldtype = {
        summary: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.summary == "" ? '' : field.settings.summary],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
      }
    }
    else if (field.type === "calculation") {
      fieldtype = {
        summary: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.summary == "" ? '' : field.settings.summary],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        unit: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.unit == "" ? '' : field.settings.unit],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
      }
    }
    else if (field.type === "number" || field.type === "numberdate") {
      fieldtype = {
        minvalue: [field.settings == undefined ? 0 : field.inputType != field.type ? 0 : field.settings.minvalue == "" ? 0 : field.settings.minvalue],
        maxvalue: [field.settings == undefined ? 9999 : field.inputType != field.type ? 9999 : field.settings.maxvalue == "" ? 9999 : field.settings.maxvalue],
        unit: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.unit == "" ? '' : field.settings.unit],
        required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],

      }
    }
    else if (field.type === "input" || field.type === "textarea") {
      fieldtype = {
        hint: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.hint == "" ? '' : field.settings.hint],
        rows: [field.settings == undefined ? 5 : field.inputType != field.type ? 5 : field.settings.rows == "" ? 5 : field.settings.rows],
        cols: [field.settings == undefined ? 1 : field.inputType != field.type ? 1 : field.settings.cols == "" ? 1 : field.settings.cols],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
        unit: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.unit == "" ? '' : field.settings.unit],
        link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],

      }
    }
    else if (field.type === "dropdown") {
      fieldtype = {
        required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
        Isglobal: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.Isglobal ? true : false],
        option: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.option == "" ? '' : field.settings.option],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],
      }
    }
    else if (field.type === "checkbox") {
      fieldtype = {
        required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
        dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
        info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
        multiple: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.multiple ? true : false],
        choices: [],
        link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],

      }
    }
    //else if (field.type === "group") {
    //  fieldtype = {
    //    required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
    //    dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
    //    info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
    //    hint: [''],
    //    layout: ['vertical'],
    //    option: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.option == "" ? '' : field.settings.option],
    //    link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],
    //    collapsable: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.collapsable ? true : false]
    //  }
    //}
    else {
        fieldtype = {
          required: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.required ? true : false],
          dependent: [field.settings == undefined ? false : field.inputType != field.type ? false : field.settings.dependent ? true : false],
          info: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.info == "" ? '' : field.settings.info],
          hint: [''],
          layout: ['vertical'],
          option: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.option == "" ? '' : field.settings.option],
          link: [field.settings == undefined ? '' : field.inputType != field.type ? '' : field.settings.link == "" ? '' : field.settings.link],

        }
      }


      return fieldtype;
    }



  }
