import { ViewChild, Component, AfterViewInit, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { PageEvent, MatPaginator } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new BehaviorSubject<any[]>([]);
  loadingSubject = new BehaviorSubject<boolean>(false);
  dataCount: number;

  pageEvent: PageEvent;
  //dashboard components
  dc: { [id: string]: any } = {};
  constructor(private route: Router, private translate: TranslateService, private api: ApiService) { }


  getUserInfo(u: Array<any>) {
    if (u.length > 0)
      return `${u[0].firstName} ${u[0].lastName}`;
    else
      return '';
  }
  ngOnInit() {
    this.setDashboardComponents();
    this.dc["study-list"].loadData();

  }

  ngAfterViewInit(): void {

    //this.scrollDispatcher.scrolled().pipe(
    //  filter((event: CdkVirtualScrollViewport) => {
    //    console.log('x: ',this.virtualScroll.getRenderedRange().end , this.virtualScroll.getDataLength())
    //    return this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength();
    //  })
    //).subscribe(event => {

    //  this.pageNumber++;
    //  this.nextSearchPage(this.pageNumber);
    //  //this.cd.detectChanges();
    //})
  }


  setDashboardComponents() {
    this.dc["study-list"] = new StudyListDashboard(this.translate, this.api, this.paginator);
  }

  gotoRecords(id: string) {
    this.route.navigate(['home/records/' + id]);
  }

}
export class StudyListDashboard {
  dataSource = new BehaviorSubject<any[]>([]);
  loadingSubject = new BehaviorSubject<boolean>(false);
  dataCount: number=0;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageEvent: PageEvent;
  constructor(private translate: TranslateService, private api: ApiService, private paginator: MatPaginator) {
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
  }
  loadData(): Observable<any[]> {
    this.api["getSchemasforRecords"](this.paginator.pageIndex + 1, this.paginator.pageSize,"creationDate desc").subscribe((response) => {
      this.dataCount = response.count;
      this.dataSource.next(response.data);
    });
    return this.dataSource;
  }
  dateDiff(date: number): Observable<string> {
    var diff = Date.now() - date;
    var years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12));
    diff -= years * (1000 * 60 * 60 * 24 * 30 * 12);

    var months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    diff -= months * (1000 * 60 * 60 * 24 * 30);

    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    var hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    var mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    var seconds = Math.floor(diff / (1000));
    diff -= seconds * (1000);

    var txt = new BehaviorSubject<string>('');
    if (years > 0) {
      this.translate.get('dashboard-study-list.time-hint-years').subscribe(i =>
        txt.next(`${years} ${i}`)
      );
    }
    else if (months > 0 && months < 12) {
      this.translate.get('dashboard-study-list.time-hint-months').subscribe(i =>
        txt.next(`${months} ${i}`)
      );
    }
    else if (days > 0 && days < 31) {
      this.translate.get('dashboard-study-list.time-hint-days').subscribe(i =>
        txt.next(`${days} ${i}`)
      );
    }
    else if (hours > 0 && hours < 24) {
      this.translate.get('dashboard-study-list.time-hint-hours').subscribe(i =>
        txt.next(`${hours} ${i}`)
      );
    }
    else if (mins > 0 && mins < 60) {
      this.translate.get('dashboard-study-list.time-hint-min').subscribe(i =>
        txt.next(`${mins} ${i}`)
      );
    }
    else if (seconds > 0 && seconds < 60) {
      this.translate.get('dashboard-study-list.time-hint-sec').subscribe(i =>
        txt.next(`${seconds} ${i}`)
      );
    }
    return txt.asObservable();
  }



}
