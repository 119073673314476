import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimePickerComponent } from './time-picker/time-picker.component';

@Directive({
  selector: '[appMatTimepicker]'
})
export class MatTimepickerDirective {
  
constructor(
    private el: ElementRef,
    private dialog: MatDialog
  ) {  }

  @HostListener('click') OnClick ($event) {

    const dialogRef = this.dialog.open(TimePickerComponent, {
      panelClass: 'dialog-box',
      data: {
        value: this.el.nativeElement.value
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.el.nativeElement.value = data;
      }
    });
  }
}
