import { ViewChild, Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { SidenavService } from '../service/sidenav.service';
import { MatSidenav } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../service/authentication.service';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import { menus, permissions,roles } from '../Permissions';
import { Object } from 'core-js';
import { ApiService } from '../service/api.service';
import { DataService } from '../service/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('snav') public sidenav: MatSidenav;
  constructor(private translate: TranslateService, private auth: AuthenticationService, private sidenavService: SidenavService,
    private api: ApiService, private dataservice: DataService) { }
  menus: any; dataa = []; userrole = [];

  ngOnInit() {
    let notFoundSystemRole = true;
    this.sidenavService.setSidenav(this.sidenav);
    this.translate.setDefaultLang(this.auth.currentUserValue.language || "en");
    let locale = this.auth.currentUserValue.language === "en" ? localeEn : localeTr;
    let localeExtra = this.auth.currentUserValue.language === "en" ? localeEnExtra : localeTrExtra;
    registerLocaleData(locale, this.auth.currentUserValue.language, localeExtra);
    let roleid = this.auth.currentUserValue.role.id;
    this.api.getRights().subscribe(rightlist => {
      this.api.getsystemrole().subscribe(rolelist => {
        this.dataservice.getStudyRights();
        rolelist.forEach(_role => {
          if (_role.id == roleid) {
            notFoundSystemRole = false;
            this.userrole.push({ rights: _role.rights, permissionusers: this.auth.currentUserValue.id })
          }
        })
        this.dataa = rightlist;
      if (notFoundSystemRole) {
        this.api.getRole(roleid).subscribe(responsedata => {
          this.userrole.push({ rights: responsedata.rights, permissionusers: this.auth.currentUserValue.id })
        });
        }
        this.getMenus();

      });
    
    });

  }
  ngAfterViewInit() {
   
  }
  ngOnDestroy(): void {

  }
  toggle() {
    this.sidenavService.toggleIt();
  }

  getMenus(): void {
    var home = {  description: 'Anasayfa', code: "nav.home", routerlink: "/home/dashboard" };
    var settings = { description: 'Ayarlar', code: "nav.settings", routerlink: "/home/reports" }
    let menu = [];
    let userid = this.auth.currentUserValue.id;
    if (!menu.includes(home)) {
      menu.push(home);
    }
    this.userrole.forEach(list => {
      if (list.permissionusers == this.auth.currentUserValue.id) {
        this.dataservice.getRightFieldList(list.rights);

        this.dataa.forEach(datalist => {

          datalist.rights.forEach(per => {

            if (list.rights.includes(per.id)) {
              if (datalist.MenuGroup.description == "Kullanıcı Yönetimi") {
                if (list.rights.includes(1003) && !list.rights.includes([1000, 1001, 1002])) {
                  datalist.MenuGroup.code = "nav.user-info";
                }

                if (!menu.includes(datalist.MenuGroup)) {
                  menu.push(datalist.MenuGroup);
                }
               
              }
              else if (datalist.MenuGroup.description == "Kurum Yönetimi") {
                if (list.rights.includes(1007) && !list.rights.includes([1004, 1005, 1006])) {
                  datalist.MenuGroup.code = "nav.enterprise-info";
                }
                if (!menu.includes(datalist.MenuGroup)) {
                  menu.push(datalist.MenuGroup);
                }
              }
              else if (datalist.MenuGroup.description == "Lisans Yönetimi") {
                if (list.rights.includes(1011) && !list.rights.includes([1008, 1009, 1010])) {
                  datalist.MenuGroup.code = "nav.licence-info";
                }
                if (!menu.includes(datalist.MenuGroup)) {
                  menu.push(datalist.MenuGroup);
                }
              }
              else if (datalist.MenuGroup.description == "Paket Yönetimi") {
                if (list.rights.includes(1015) && !list.rights.includes([1013, 1014, 1016])) {
                  datalist.MenuGroup.code = "nav.package-info";
                }
                if (!menu.includes(datalist.MenuGroup)) {
                  menu.push(datalist.MenuGroup);
                }
              }
              else {
                if (!menu.includes(datalist.MenuGroup)) {
                  menu.push(datalist.MenuGroup);
                }
              }
            }
          })


        
        })

      }
    })
    if (!menu.includes(settings)) {
      menu.push(settings);    
    }
    this.menus = menu;
 
  }
}
