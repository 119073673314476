import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../service/api.service';
import { escapeLeadingUnderscores } from 'typescript';
import { AuthenticationService } from '../service/authentication.service';


@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})

export class LeftSidebarComponent {

  constructor(private route: ActivatedRoute, private api: ApiService, private auth: AuthenticationService) { }
  public studyname: string;
  public userOrganization: any;
  events: string[] = [];
  opened: boolean;

  components = [
    'textbox',
    'radiobutton',
    'checkbox',
    'dropdown',
    //'button',
    'date',
    'time',
    'textarea',
    'number',
    'slider',
    //'remark',
    'randomization',
    'summary',
    'numberdate',
    "calculation",
    "group"
  ];

  drop(event: CdkDragDrop<string[]>) {
    
    if (event.container.id === event.previousContainer.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      //copyArrayItem(event.previousContainer.data,
      //  event.container.data,
      //  event.previousIndex,
      //  event.currentIndex);
      var listID = "list1";
      if (listID === event.container.id) {
        var text = event.item.element.nativeElement.innerText;
        var index = event.container.data.indexOf(text);
        if (index !== -1) {
          event.container.data.splice(index, 1);
        }
        var previousIndex = event.previousContainer.data.indexOf(text);
        if (previousIndex !== -1) {
          event.previousContainer.data.splice(previousIndex, 1);
        }
      }
    }
  }

  ngOnInit(): void {
    

    var sid = this.route.snapshot.params.schemaId;
    if (sid != undefined) {
      this.api.getSchema(sid).subscribe(response => {
        this.studyname = response.studyName;
      });
    }
    else {
     
      var _studyName = localStorage.getItem('studyname');
      if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) == null) {
        localStorage.setItem('studyname', "");
      }
      else
        this.studyname = _studyName;


           
    }
 
  }

  private focusoutHandler(event) {
   // console.log(event.target.value);
    localStorage.setItem('studyname', event.target.value);
  }          

}
