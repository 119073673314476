
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { first } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsentFormDialogComponent } from '../consent-form-dialog/consent-form-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ApiService } from '../service/api.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);


  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }


  @Input() loggedUsername: string;
   username: string;
   password: string;
  errorMessage: string;
  private _hideSummaryError: boolean;
  private _hideUsernameError: boolean;
  private _hidePasswordError: boolean;

  //constructor() { }
  constructor(private router: Router,
    private loginService: AuthenticationService,
    public dialog: MatDialog,
    private translate: TranslateService, private api: ApiService) {
    this._hidePasswordError = true;
    this._hideSummaryError = true;
    this._hideUsernameError = true;
  }

  ngOnInit() {
    this.translate.getBrowserLang();
  }

  onLogin() {
    if ((this.username === '' || this.username === undefined) && (this.password === '' || this.password === undefined)) {
      this._hideUsernameError = false;
      this._hidePasswordError = false;
    }
    else if (this.username === '' || this.username === undefined) {
      this._hideUsernameError = false;
      this.errorMessage = "Username is required";
    }
    else if (this.password === '' || this.password === undefined) {
      this._hidePasswordError = false;
      this.errorMessage = "Password is required";
    }
    else {
      var loginResult = this.loginService.login(this.username, this.password);
      if (!localStorage.getItem('autosave_interval')) {
        this.api.getAutoSaveInterval().subscribe(interval => {
          localStorage.setItem('autosave_interval', interval);
        })
      }
      loginResult.pipe(first()).subscribe(
        data => {
          if (!data.consentFormConfirm) {
            const dialogRef = this.dialog.open(ConsentFormDialogComponent, {
              width: '800px',           
              data: { data: data }

            });


            dialogRef.afterOpen().subscribe(result => {
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
            });
          }
          else {
            if (data.message) {
              this.router.navigate(['/']);
              this._hideSummaryError = false;
              this.errorMessage = data.message;
            } else {
              //this.router.navigate(['/home/dashboard']);
              this.router.navigate(['/investigators']);
            }
          }


        },
        error => {
          this._hideSummaryError = false;
          this.errorMessage = "Invalid username or password";
          this.router.navigate(['/']);
        }
      );
      this._hideUsernameError = true;
      this._hidePasswordError = true;
      //if (this.username == "medipol" && this.password == "medipol01") {
      //  this.router.navigate(['/dashboard']);
      //}
      //else {
      //  this._hideSummaryError = false;

      //  this.errorMessage = "Invalid username or password";
      //}
    }


  }



}
