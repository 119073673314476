import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { ApiService } from '../service/api.service';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export class TableSource implements DataSource<any> {

  private dataSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public dataCount: number;
  public loading$ = this.loadingSubject.asObservable();
  _params: any[];
  constructor(private objectName: string, private apiService: ApiService, ...params: any[]) { this._params = params;}

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  public loadData( filter = '',
    sort = null, pageIndex = 0, pageSize = 3) {

    this.loadingSubject.next(true);
    this.apiService.getDataListWithPaged(this.objectName,filter, pageIndex + 1, pageSize,sort ,this._params).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {
      var data = response.data.filter(i => { return i.deleted == false });

      if (this.objectName == "record-list") {
        var mapData = _.map(data, function (x) {
          return _.assign(x, {
            namecode: x.name.split(" -")[0]
          });
        });

        data = _.orderBy(mapData, ['namecode', 'creationDate'], ['asc', 'desc']);
      }

      this.dataCount = response.count; this.dataSubject.next(data);
      //this.dataCount = response.count; this.dataSubject.next(response.data);
    });
    //this.apiService.findLessons(courseId, filter, sortDirection,
    //  pageIndex, pageSize).pipe(
    //    catchError(() => of([])),
    //    finalize(() => this.loadingSubject.next(false))
    //  )
    //  .subscribe(lessons => this.dataSubject.next(lessons));
  }


  public loadRoleData(filter = '',
    sort = null, pageIndex = 0, pageSize = 3) {

    this.loadingSubject.next(true);
    this.apiService.getDataListWithPaged(this.objectName,'', pageIndex + 1, pageSize, sort, this._params).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {   
      this.dataCount = response.count;
      this.dataSubject.next(response.data);
    });

  }
}
