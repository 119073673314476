import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-chart',
  templateUrl: './google-chart.component.html',
  styleUrls: ['./google-chart.component.css']
})
export class GoogleChartComponent implements OnInit {

  constructor() { }

  title = '';
  type = 'ComboChart';
  data = [
    ["18-24", 4, 5, 4],
    ["25-34", 9, 5.5, 7],
    ["35-44", 8, 17,13],
    ["45-54", 28, 36, 32],
    ["55-64", 18, 64, 42]

  ];
  columnNames = ['Loaction', 'Males', 'Females', 'Overall'];
  options = {
    hAxis: {
      title: 'Age in years'
    },
    vAxis: {
      title: 'Prevalence of hypertension'
    },
    seriesType: 'bars',
    series: { 2: { type: 'line' } }
  };
  width = 600;
  height = 400;

  ngOnInit() {
  }

}  
