import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Fields } from "../Fields";

@Component({
  selector: "app-remark",
  template:
    `
<div class="example-background" [formGroup]="group">     
<input   matInput  [formControlName] = "field.name" [(ngModel)] = "field.value"  readonly="{{field.disabled}}">
</div>
`,
  styles: ['.example-background {  background-color: #f1f1f1; box-sizing: content-box; width: 100 %; padding: 10px;}' ]
})
export class RemarkComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }


}
