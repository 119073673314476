import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../Fields";

@Component({
  selector: "app-textbox",
  template: `
<mat-form-field class="demo-full-width" [formGroup]="group">
<input matInput [formControlName]="field.name" [placeholder]="field.label" [type]="field.inputType">
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>

 <mat-form-field>
 <input matInput placeholder="Email" value="" >
 <mat-hint>Errors appear instantly!</mat-hint>


 </mat-form-field>
`,
  styles: []
})
export class TextboxComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
