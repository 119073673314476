import { Component, OnInit, ViewChild,Input} from '@angular/core';
import { trigger, transition, style, state, animate, query, stagger, keyframes, animateChild } from "@angular/animations";
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from "@angular/forms";
import { permissions, User, Customer, roles } from '../../Permissions';
import { AuthenticationService } from '../../service/authentication.service';
import { ApiService } from '../../service/api.service';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DataService } from '../../service/data.service';
import { Rights } from '../../Roles';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css'],
  animations: [
    trigger(':hover', [
      state(':hover', style({
        transform: 'rotate(0deg)'
      })),

      state('state2', style({
        transform: 'rotate(225deg)'
      })),
      transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class CreateRoleComponent implements OnInit {
  @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;
  options = [];
  @Input() roledata: roles;
  data = []; userdata = []; customerdata = [];
  rights: roles[];
  group: FormGroup
  permissioncodes: any;
  permissionuser = [];
  userrightId: any;
  customers: any;
  selectedOptions = [];
  _selected: string;
  selected = this.selectedOptions;
  showError = false;
  errorMessage = '';
  isSystemRole: boolean;
  isActive: boolean;
  userrole: string;
  rightcodes = [];
  newdata = [];
  org: boolean;
  userrights = [];
  adminOrgId: string;
  constructor(private fb: FormBuilder, private auth: AuthenticationService, private api: ApiService,
    private toastr: ToastrService, private location: Location, private dataservice: DataService, private route: ActivatedRoute) {


  }


  ngOnInit() {


    let notFoundSystemRole = true;
    var roleid = this.route.snapshot.params.roleid;
    this.api.getUserRights(roleid).subscribe(userrights => {

      userrights.result.forEach(ulist => {
        this.permissionuser = ulist.user;
        userrights = ulist.user;
        this.userrightId = ulist.id;
      })
    });




    this.api.getsystemrole().subscribe(list => {

      this.rights = list;

      this.api.getRights().subscribe(rightlist => {
        this.data = rightlist;
        this.api.getOrganizations().subscribe(orglist => {

          orglist.forEach(item => {
            this.customerdata.push({ name: item.name, value: item.id });
          })

          this.api.getAdminOrganization().subscribe(_org => {

            _org.forEach(_item => {
              this.adminOrgId = _item;
            })

        // supervisor da  getAllUsers metodu çalışacak
          this.api.getUsers().subscribe(_userlist => {


            this.userdata = _userlist.result;
            _userlist.result.forEach(aa => {
              this.options.push({ name: aa.firstname + " " + aa.lastname, id: aa.id })
            })

            if (roleid) {
              this.api.getsystemrole().subscribe(rolelist => {

                rolelist.forEach(_role => {
                  if (_role.id == roleid) {
                    notFoundSystemRole = false;
                    var _roles = new roles();
                    _roles.id = _role.id;
                    _roles.role = _role.role;
                    _roles.isSystemrole = _role.isSystem;
                    _roles.customer = this.adminOrgId;
                    _roles.rights = _role.rights;
                    _roles.permissionusers = this.permissionuser;//right.permissionusers;

                    this.roledata = _roles;
                    this.LoadRoleData();
                  }
                })

                if (notFoundSystemRole) {
                  this.api.getRole(roleid).subscribe(responsedata => {
                    var _roles = new roles();
                    _roles.id = responsedata.id;
                    _roles.role = responsedata.role;
                    _roles.isSystemrole = responsedata.isSystem;
                    _roles.customer = this.adminOrgId;
                    _roles.rights = responsedata.rights;
                    _roles.permissionusers = this.permissionuser;//right.permissionusers;

                    this.roledata = _roles;
                    this.LoadRoleData();
                  });
                }

              
              });
            }
            else {
              this.LoadRoleData();
            }
          //})
       
        })

          });
        });

      })
    });
 
   
 
   

   
  }

  LoadRoleData() {
    this.isActive = false;
    if (this.auth.currentUserValue.organization == "5cc1480b28aa164d2c75a008") {
      this.org = true;
    }
    else {
      this.org = false;
    }


    var field_data = new roles();
    if (this.roledata == undefined || (this.roledata != undefined && !this.roledata.isSystemrole && this.roledata.role != "")) {
      this.isSystemRole = false;
      this.userdata.forEach(userlist => {
        if (userlist.id == this.auth.currentUserValue.id) {
          this.userrole = this.auth.currentUserValue.role.role;// userlist.role;       
          this.rights.forEach(right => {
            if (this.userrole == right.role && this.userrole == "Supervisor" && this.org) {
              //this.isSystemRole = right.isSystemrole;
              this.isActive = true;
            }
            if (this.userrole == "Domain Admin" && right.role == "Site Admin" && this.org) {
              this.rightcodes = right.rights;
              this.data.forEach(_data => {
                _data.rights.forEach(_datalist => {
                  if (right.rights.includes(_datalist.id)) {
                    _datalist.isVisible = true;
                    _data.isVisible = true;
                  }
                })
              })
            }
            else if (right.role == this.userrole && !(this.userrole == "Domain Admin" && right.role == "Site Admin" && this.org)) {
              this.rightcodes = right.rights;
              if (this.userrole == "Supervisor") {
                this.data.forEach(_data => {
                  _data.rights.forEach(_datalist => {
                    _datalist.isVisible = true;
                    _data.isVisible = true;
                  })
                })
              }
              else {
                this.data.forEach(_data => {
                  _data.rights.forEach(_datalist => {
                    if (right.rights.includes(_datalist.id)) {
                      _datalist.isVisible = true;
                      _data.isVisible = true;
                    }
                  })
                })
              }

            }

          })
        }
      })

      this.data.forEach(_data => {

        if (_data.isVisible) {
          this.newdata.push(_data);
        }
      })

      if (this.roledata == undefined) {
        field_data.role = "";
        field_data.customer = this.adminOrgId;
        field_data.rights = [];
        field_data.permissionusers = [];
        field_data.isSystemrole = this.isSystemRole;
        this.roledata = field_data;
      }
      else {
        this.selectedOptions = this.permissionuser;
      }


    }
    else {
      var bb = this.permissionuser;
      this.selected = this.roledata.permissionusers;
      this.selectedOptions = this.roledata.permissionusers;
      this.userdata.forEach(userlist => {
        if (userlist.id == this.auth.currentUserValue.id) {
          if (this.auth.currentUserValue.role.role == "Supervisor" && this.org) {
            this.isActive = true;
          }
        }
      })

    }



    this.group = this.fb.group({
      role: [this.roledata.role == "" ? '' : this.roledata.role, [Validators.required]],
      customer: [this.roledata.customer == "" ? '' : this.roledata.customer],
      permissioncodes: this.fb.array([]),
      permissionusers: [''],
    });

    this.permissioncodes = this.userrole == "Supervisor" ? this.data : this.roledata.role == "" ? this.newdata : !this.roledata.isSystemrole ? this.newdata : this.data;
    if (this.roledata != undefined && !this.roledata.isSystemrole && this.roledata.role != "") {
      this.group.controls.permissioncodes = this.fb.array(this.roledata.rights);
    }

    //  this.options = userdata;
  
    this.customers = this.customerdata;
    this.isSystemRole = this.roledata.isSystemrole;


    this.group.controls.permissioncodes = this.fb.array(this.roledata.rights);
  }


  getSelectedOptions(selected) {
    this.selected = selected;
    this.group.patchValue({ permissionusers: this.selected })

  }

  getCustomerSelection(value) {
  
    if (value.value == "medipolunv") {
      this.options.push({ name: "Bahadır KALAY", id: "5c845HGFHGFJ1137841c" })
    }
    else {

      this.options.splice(3, 1)  
   
    }
  }
  getCustomerName(value: string) {
    var customername;
    this.customers.forEach(list => {
      if (list.value == value) {
        customername= list.name;
      }
    
    })
     return customername;
  }


  onChange(event) {
    const codes = <FormArray>this.group.get('permissioncodes') as FormArray;

    if (event.checked) {
      codes.push(new FormControl(event.source.value))
    } else {
      const i = codes.controls.findIndex(x => x.value === event.source.value);
      codes.removeAt(i);
    }
  }

  save() {
    if (this.group.value.permissioncodes.length != 0 && !this.group.invalid) {
      console.log(this.group.value, this.selected);

      var request = {}; var userrequest = {};
      if (this.route.snapshot.params.roleid == undefined) {
        request = {
          role: this.group.value.role,
          organization: null,
          rights: this.group.value.permissioncodes,
          isActive: true,

        }
        var result = this.api.saveRole(request).subscribe(response => {
          var newroleid = response.id;
          if (this.group.value.permissionusers.length > 0) {
            userrequest = {
              role: { role: this.group.value.role, _id: newroleid },
              organization: null,
              user: this.group.value.permissionusers,
              isSystemRole: this.isSystemRole,

            }
            var result = this.api.saveUserRights(userrequest).subscribe(response => {

              this.toastr.success('', 'Successfully created');
              this.location.back();
            }, (error) => {
              this.toastr.error('', 'Error occured', {
                timeOut: 3000
              });
            });
          }
          else {
            this.toastr.success('', 'Successfully created');
            this.location.back();
          }
         
        }, (error) => {
          this.toastr.error('', 'Error occured', {
            timeOut: 3000
          });
          });

      }
      else {
        request = {
          _id: this.roledata.id,
          role: this.group.value.role, 
          organization: null,
          rights: this.group.value.permissioncodes,
          isActive: true,
        }
        var result = this.api.updateRole(request).subscribe(response => {

          if (this.group.value.permissionusers.length > 0) {
            if (this.userrights.length == 0) {
              userrequest = {
                role: { role: this.group.value.role, _id: this.roledata.id },
                organization: null,
                user: this.group.value.permissionusers,
                isSystemRole: this.isSystemRole,

              }
              var result = this.api.saveUserRights(userrequest).subscribe(response => {

                this.toastr.success('', 'Successfully created');
                this.location.back();
              }, (error) => {
                this.toastr.error('', 'Error occured', {
                  timeOut: 3000
                });
              });
            }
            else {

              userrequest = {
                _id: this.userrightId,
                role: { role: this.group.value.role, _id: this.route.snapshot.params.roleid },
                organization: null,
                user: this.group.value.permissionusers,
                isSystemRole: this.isSystemRole,

              }
              var result = this.api.updateUserRights(userrequest).subscribe(response => {
                this.toastr.success('', 'Successfully created');
                this.location.back();
              }, (error) => {
                this.toastr.error('', 'Error occured', {
                  timeOut: 3000
                });
              });
            }

          }
          else {
            this.toastr.success('', 'Successfully created');
            this.location.back();
          }

        
        }, (error) => {
          this.toastr.error('', 'Error occured', {
            timeOut: 3000
          });
        });
      }

    
    
      

    }
   
  }

  CheckControl(_value: string, arr) {
    if (arr != "" && arr != undefined) {
      if (arr.length != 0) {
        if (arr.includes(_value)) {
          return true;
        }
        else return false;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
 
  }

  RightControl(_value: string, arr) {
    if (arr != "" && arr != undefined) {
      if (arr.length != 0) {

        if (this.userrole == "Supervisor") {
          if (this.roledata == undefined && this.roledata.role == "") {
            return false;
          }

        }
        else {
          if (arr.includes(_value)) {
            return false;
          }
          else return true;
        }
       
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }

  }

  

 
 

}
