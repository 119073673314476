import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../Fields";

@Component({
  selector: "app-radiobutton",
  template: `
  <div [formGroup]="group" >
    <mat-radio-group aria-label="Select an option" class="{{field.settings.layout}}" [formControlName]="field.name" [disabled]="field.disabled" required="{{field.settings.required}}">
     <mat-radio-button class="example-radio-button" *ngFor="let option of field.options; let i = index" [value]="option.value" >
        {{option.value}}
      </mat-radio-button>
    </mat-radio-group>
   <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"  *ngIf="field.settings.info != null" ></mat-hint>
  </div>

`,
  styles: ['.example-radio-button {  margin: 5px; }',
    '.vertical {display: flex;flex-direction: column; margin: 15px 0;}',
    '.horizontal {display: flex; margin: 15px 0;}',
    '.hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class RadiobuttonComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
