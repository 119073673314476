import { ElementRef, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableSource } from '../data/tablesource';
import { ApiService } from '../service/api.service';
import { DataTableComponent } from '../data/datatable.component';
import { first, debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { merge } from "rxjs/observable/merge";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { ExcelService } from '../service/excel.service';

@Component({
  selector: 'app-study-list',
  templateUrl: './study-list.component.html',
  styleUrls: ['./study-list.component.css']
})
export class StudyListComponent extends DataTableComponent implements OnInit, AfterViewInit {

  constructor(private route: Router,
    private api: ApiService, private routed: ActivatedRoute, private excelService: ExcelService) {
    super(['studyName', 'version', "actions"]);
  }
  ngOnInit() {

    this.dataSource = new TableSource('study-schema-list', this.api);
    this.dataSource.loadData('', "creationDate desc", 0, 10);
    //this.api.getSchemas().pipe(first()).subscribe((response: StudyItemResponse) => {
    //  this.dataSource = new TableDataSource(response, this.paginator, this.sort);
    //});
  }
  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadDataPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();

  }

  getRecords(id: string) { this.route.navigate(['home/records/' + id]); }

  exportAsXLSX(id: string): void {
    let data = [];
    this.api.getRecord(id).subscribe(responsedata => {
      data = responsedata.values;
    //  console.log(data);
    });

    this.excelService.exportAsExcelFile(data, 'sample');
    

    }
}
