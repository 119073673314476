import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../Fields";
import { DataService } from '../service/data.service';
import * as _ from 'lodash';
@Component({
  selector: "app-number",
  template: `

<div  [formGroup]="group">
 <mat-form-field>
 <input matInput numeric numericType="decimal" [formControlName]="field.name"  max="{{field.settings.maxvalue}}" min="{{field.settings.minvalue}}"  placeholder="Only numeric value" name="{{field.name}}"
                 required="{{field.settings.required}}" (blur)="onBlurMethod($event)" readonly="{{field.disabled}}" >
    <span *ngIf="field.settings.unit != null" matSuffix>&nbsp;{{field.settings.unit}}</span>
 </mat-form-field>
<mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"   *ngIf="field.settings.info != null" ></mat-hint>
</div>

`,
  styles: ['.hint-container { display: flex; flex-direction: column; font-size: 65%; }']

})
export class NumberComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  list = {}; datalist = [];
  constructor(private data: DataService ) { }
  ngOnInit() { }


  onBlurMethod(event) {

    var minvalue = Number(this.field.settings.minvalue)
    var currentvalue = Number(event.target.value == "" ? 0 : event.target.value)

    let minvaluecontrol = false;
    if (currentvalue < minvalue) {      
      event.target.value = null;
      minvaluecontrol = true;
      if (this.list["{" + this.field.name + "}"] != undefined) {
        delete this.list["{" + this.field.name + "}"];

      }
     
    }

    
    if (!minvaluecontrol) {
      this.list["{" + this.field.name + "}"] = currentvalue;
    }
    else {
      
      this.list["{" + this.field.name + "}"] = null;
    }
    this.datalist.push(this.list);
    this.data.datavalues = this.datalist;
    this.data.getcalculation2();
   
         

  }

}
