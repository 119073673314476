import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../Fields";
import { AmazingTimePickerService } from 'amazing-time-picker';



@Component({
  selector: "app-time",
  template: `
<div   [formGroup]="group">
<mat-form-field>
     <input  *ngIf="!field.disabled" atp-time-picker matInput  [formControlName] = "field.name"  [attr.disabled]="field.disabled"/>
     <input  *ngIf="field.disabled"  matInput  [formControlName] = "field.name"  readonly="{{field.disabled}}">
</mat-form-field>
 </div>
<div>
<mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"  *ngIf="field.settings.info != null" ></mat-hint>
 </div>
`,
  styles: ['.hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class TimeComponent implements OnInit{
 
  field: Fields;
  group: FormGroup;


  constructor(private atp: AmazingTimePickerService) {
    
  }
  ngOnInit() {
    
  }


  open() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      //console.log(time);
    });
  }


}


