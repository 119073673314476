export class Group {
  studyname?: string;
  schemaId: string;
  Fields: Fields[];
}

export class Fields {
  id: number;
  name?: string;
  inputType?: string;
  step?: number;
  options?: any;
  type?: string;
  question: string;
  info?: string;
  value?: any;
  dependency?: any;
  settings?: any;
  group?: any;
  validations?: any;
  remark?: boolean;
  disabled?: boolean;
}

export class Validator {
  name?: string;
  validator: any;
  message: string;
}
export interface Settings {
  
}

export class DateTimeSettings {
  dateformat?: string;
  dependent?: boolean = false;
}
export class NumberSettings {
  minvalue?: number=0;
  maxvalue?: number = 0;
  unit?: string = null;
  required?: boolean = false;
  link?: string;
  dependent?: boolean = false;
  info?: string= null;
}
export class InputSettings {
  hint?: string;
  link?: string;
  dependent?: boolean = false;
}
export class GroupSettings {
  Fields: Fields[];
  link?: string;
}
export class TextAreaSettings {
  hint?: string;
  rows?: number;
  cols?: number;
  info?: string;
  required?: boolean;
  link?: string;
  dependent?: boolean = false;
  unit?: string;
}
export class TimeSettings {
  info?: string;
  dependent?: boolean = false;
}
export class SliderSettings {
  thumbLabel?: boolean;
  minvalue: number;
  maxvalue: number;
  increment: number;
  invert?: boolean;
  vertical?: boolean;
  disabled?: boolean;
  unit?: string;
  dependent?: boolean = false;

}
export class SummarySettings {
  info: string;
  summary: string;
  unit?: string;
  dependent?: boolean = false;
}
export class NumberDateSettings {
  required?: boolean;
  link?: string;
  dependent?: boolean = false;
  minvalue?: number;
  maxvalue?: number;
  dateformat?: string;
}

export class DropdownSettings {
  required?: boolean;
  link?: string;
  dependent?: boolean = false;
  choiceId?: number;
  studyId?: number;
  Isglobal?: boolean;
  option?: string;
}

export class CheckboxSettings {
  required?: boolean;
  link?: string;
  dependent?: boolean = false;
  info?: string;
  multiple?: boolean;
  choices?: any;
}

export class RadioButtonSettings {
  layout?: string;
  option?: string;
  link?: string;
  dependent?: boolean = false;
}

export class DefaultSettings {
  info?: string;
  hint?: string;
  link?: string;
  dependent?: boolean = false;
}


export class Options {
  name: string;
  value: string;
}



