import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Fields } from "../Fields";


@Component({
  selector: "app-summary",
  template:
    `
<div [formGroup]="group">
      <a *ngIf="field.settings.info != null"  (click)="control();" ngDefaultControl>
         <i  class="material-icons" style="padding: 5px; color: rgba(0, 0, 0, 0.3);">info</i>
      </a>     
   <input   matInput  [formControlName] = "field.name" [(ngModel)] = "field.value"  readonly="{{field.disabled}}">
<br />
    <div *ngIf="this._info">
        <mat-label [innerHTML]="field.settings.info | safeHtml"  style="padding: 5px; color: rgba(0, 0, 0, 0.6); font-size: 75%;"></mat-label>
    </div>
   
</div>
`,
  styles: ['.material-icons {flex - direction: row; align-items: center;justify-content: space-between; box-sizing: border-box;background: transparent;cursor: pointer;}']
})
export class SummaryComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() {  }
  ngOnInit() { }

  _info: boolean = false;
  control(): boolean {
    if (this._info) {
      this._info = false;
      return this._info;
    }
    else {
      this._info = true;
      return this._info;
    }
  }
}
  

  



