import { Injectable, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sidenav: MatSidenav;
  private opened: boolean = false;

  constructor() { }
  public setSidenav( sidenav: MatSidenav) {
    this.sidenav = sidenav;   
  }
  public open() { this.opened = true; return this.sidenav.open(); }
  public close() { this.opened = false; return this.sidenav.close(); }
  public toggleIt(): void {

    if (this.opened) {
      this.sidenav.close();
    } else {    
      this.sidenav.open();
    }
    this.opened = !this.opened;
    //   this.sidenav.toggle();
  }
}
