import { Component, OnInit, Input ,EventEmitter,Output} from '@angular/core';
import { Fields } from '../../Fields';
import {MatDialog} from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";




@Component({
  exportAs: 'dynamicForm',
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
 
})


export class DynamicFormComponent implements OnInit {
  @Input() groupId: any;
  @Input() formfield: Fields;

  @Input() fieldvalue: any;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  test: any;
 
  get controls() { return this.formfield.type}
  get changes() { return this.form.valueChanges; }
  get valid() { return this.form.valid; }
  get value() { return this.form.value; }

  constructor (
    private dialog: MatDialog, private fb: FormBuilder
  ) {

  }

  Open () {

  }

  ngOnInit() {
    
    this.form = this.createGroup();

  }


   createGroup() {
    const group = this.fb.group({});
  
     if (this.formfield.type === "button") return;
     else if (this.formfield.type === "checkbox" && this.formfield.settings.multiple) {
       if (this.formfield.value.length === undefined) //true || false
       {
         this.formfield.value = "";
       }
     }
     //const control = this.fb.control(this.formfield.value == "" ? '' : this.formfield.value, [Validators.required])
     const control = this.fb.control(this.formfield.value == "" ? '' : this.formfield.value)
     group.addControl(this.formfield.name, control);
    return group;

  }

  public newGroup(value_:any) {
    const group = this.fb.group({});    
    if (this.formfield.type === "button") return;
    else if (this.formfield.type === "checkbox" && this.formfield.settings.multiple) {
      if (this.formfield.value.length === undefined) //true || false
      {
        value_ = "";
      }
    }
    //const control = this.fb.control(value_, Validators.required)
    const control = this.fb.control(value_)

    group.addControl(this.formfield.name, control);

    return group;

  }


  createControl(config: Fields) {
    const { value } = config;
    return this.fb.control({  value }, Validators.required);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.form.value);  
    //console.log(this.form.value);
  }
   setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable': 'enable';
      this.form.controls[name][method]();
      return;
    }

   
  }


  public setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, { emitEvent: true });

  }
  goToLink() {
    window.open(this.formfield.settings.link, "_blank");
  }

}



