import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]'
})
export class NumberDirective {

  @Input('numericType') numericType: string; // number | decimal
  @Input('min') min: number;
  @Input('max') max: number;

  private regex = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
  };

  private specialKeys = {
    number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
  };

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.min === undefined) {
      this.min = 0;
    }
    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }
  
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex[this.numericType])) {
      event.preventDefault();
    }
    var currentvalue = Number(next);

    var currentvaluelength = next.length; 
    var minvaluelength = this.min.toString().length;

   
    if (currentvalue > this.max) {
      event.preventDefault();
      }
    else if (currentvalue < this.min) {
      if (currentvaluelength == minvaluelength+1) {
        event.preventDefault();
      }
      }
 
      
  
  }
}
