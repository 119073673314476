import {  AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableSource } from '../data/tablesource';
import { ApiService } from '../service/api.service';
import { DataTableComponent } from '../data/datatable.component';
import { first, debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { merge } from "rxjs/observable/merge";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { Location } from '@angular/common';
import { DataService } from '../service/data.service';
import { AuthenticationService } from '../service/authentication.service';
import { Sort } from '@angular/material';

@Component({
  selector: 'study-schema-table',
  templateUrl: './study-schema-list.component.html',
  styleUrls: ['./study-schema-list.component.css']
})

export class StudySchemaListComponent extends DataTableComponent implements OnInit, AfterViewInit {
  rightsdata = {};
  show: boolean = false;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private dataservice: DataService,
    private auth: AuthenticationService,
  ) {
    super(['studyName', 'version', 'createdInfo', 'modifiedInfo', 'creationDate',  'modifiedDate',"actions"])
  }
  ngOnInit() {
    var studyvalues = this.dataservice.studyvalues;
    this.rightsdata = this.dataservice.right_fields;
    this.dataSource = new TableSource('study-schema-list',this.api);
    this.dataSource.loadData('', "creationDate desc", 0, 10);
      
    if (this.auth.currentUserValue.role.role == "Site Admin" || this.auth.currentUserValue.role.role == "Domain Admin" || this.auth.currentUserValue.role.role == "Analyst" ) {
      this.show = true;
    }
    
    if (this.sort) {
      merge(this.sort.sortChange).pipe(
        tap(() => {
          let sort = `${this.sort.active} ${this.sort.direction.toString() || 'asc'}`;
          console.log(sort);
          this.dataSource.loadData('', sort, this.paginator.pageIndex, this.paginator.pageSize);
        })
      )
        .subscribe();
    }


  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction == '') {
      return;
    }
    else
      this.load();

  }
  load() {
    
    let sort = this.sort.active ? `${this.sort.active} ${this.sort.direction.toString() || 'asc'}` : "creationDate desc";   
    this.dataSource = new TableSource('study-schema-list', this.api);
    this.dataSource.loadData('', sort, this.paginator.pageIndex, this.paginator.pageSize);
  }
  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => {            
            this.load();
              // this.loadDataPage()
          })
        )
        .subscribe();
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => {
          this.load();
        })
       )
      .subscribe();

    //fromEvent(this.input.nativeElement, 'keyup')
    //  .pipe(
    //    debounceTime(150),
    //    distinctUntilChanged(),
    //    tap(() => {
    //      this.paginator.pageIndex = 0;

    //      this.loadDataPage();
    //    })
    //  )
    //  .subscribe();


  }
  navigateBack() {
    this.location.back();
  }
  getUserInfo(u: Array<any>) {
    if (u.length > 0)
      return `${u[0].firstName} ${u[0].lastName}`;
    else
      return '';
  }
}
