import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Fields } from "../Fields";
import { DataService } from '../service/data.service';
//<input   matInput[formControlName] = "field.name"[(ngModel)] = "field.value"  readonly = "{{field.disabled}}" >
@Component({
  selector: "app-calculation",
  template:
    `
<div [formGroup]="group">
 <mat-form-field  *ngIf="this.data.calculations[this.field.name]">
<input   matInput  [formControlName] = "field.name" [(ngModel)] = "this.data.calculations[this.field.name].value"   readonly="{{field.disabled}}">
   <span *ngIf="field.settings.unit != null" matSuffix>&nbsp;{{field.settings.unit}}</span>
 </mat-form-field>
 <div *ngIf="this.data.calculations[this.field.name]&&this.data.calculations[this.field.name]._error" style="color:red;" > {{ this.data.calculations[this.field.name]._errormessage}}</div>
</div>
`,
  styles: []
})
export class CalculationComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  summary: any;
  constructor(private data: DataService) { }
  ngOnInit() {
    this.data._error = false;
    this.data._errormessage = "";
    var summary = this.field.settings == undefined ? '' : this.field.settings.summary;
    this.data.formula = summary;
    this.data.changeformula = summary;
    this.data.calculations[this.field.name] = { formula: summary, math: summary, value:null };
    if (this.data.changeformula.includes("{")) {
      this.data.calculation = null;
    }

  }

}
