import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
// key that is used to access the data in local storageconst
const STORAGE_KEY = 'local_todolist';
@Injectable()
export class LocalStorageService {
  anotherTodolist = [];
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  //public storeOnLocalStorage(taskTitle: string): void {

  //  // get array of tasks from local storage
  //  const currentTodoList = this.storage.get(STORAGE_KEY) || [];
  //  // push new task to array
  //  currentTodoList.push({
  //    title: taskTitle,
  //    isChecked: false
  //  });
  //  // insert updated array to local storage
  //  this.storage.set(STORAGE_KEY, currentTodoList);
  //  console.log(this.storage.get(STORAGE_KEY) || 'LocaL storage is empty');
  //  this.storage.remove(STORAGE_KEY);
  //}
  public storeOnLocalStorage(_field: []): void {

    // get array of tasks from local storage
    const currentTodoList = this.storage.get(STORAGE_KEY) || [];
    // push new task to array
    currentTodoList.push({
      field: _field,
    });
    // insert updated array to local storage
    this.storage.set(STORAGE_KEY, currentTodoList);
    console.log(this.storage.get(STORAGE_KEY) || 'LocaL storage is empty');
    this.storage.remove(STORAGE_KEY);
  }
}
