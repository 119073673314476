import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAccordion } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { roles } from '../../Permissions';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-study-rights',
  templateUrl: './study-rights.component.html',
  styleUrls: ['./study-rights.component.css']
})
export class StudyRightsComponent implements OnInit {
  panel = false;
  panel_add = false;
  panel_edit = false;
  panel_delete = false;
  form: FormGroup;
  studyName: string;
  isNew: boolean;
  studyrightId: any;
  public sid: string;
  studyrightsdata = {};
 
  constructor(private route: ActivatedRoute, private api: ApiService, private data: DataService,
    private toastr: ToastrService, private location: Location) {

    this.api.getOrganizations().subscribe(aa => {
      var b = aa;
    })
  }

  ngOnInit() {
    this.isNew = true;
    this.sid = this.route.snapshot.params.schemaId;
    this.api.getSchema(this.sid).subscribe(response => {
      this.studyName = response.studyName;

      this.api.getStudySchemaRights(this.sid).subscribe(studyrights => {
        studyrights.result.forEach(_values => {
          this.studyrightsdata = _values.values;
          this.studyrightId = _values.id;
          if (Object.keys(this.studyrightsdata).length > 0) {
            this.isNew = false;
          }
          else {
            this.isNew = true;
          }
          this.data.new_selectiondata = this.studyrightsdata;

          for (let v in this.studyrightsdata) {
            if (v == "list") { this.panel = true; }
            if (v == "add") { this.panel_add = true; }
            if (v == "edit") { this.panel_edit = true; }
            if (v == "delete") { this.panel_delete = true; }
          }
        })
      })
   
    })



    //this.studyrightsdata =  {
    //  "list": [{ name: "selfdata", selection: [{ name: "Nurdan SİNAN", value: "5cDGHGFHd92111137c3c", type: "user" }, { name: "Supervisor", value: "333333333", type: "role" }] }, { name: "alldata", selection: [{ name: "Zehra AKÇE", value: "5cDGHGFGFHGFJ1137c3c", type: "user" }] }] ,
    //  "add": [{ name: "selfdata", selection: [{ name: "Nurdan SİNAN", value: "5cDGHGFHd92111137c3c", type: "user" }, { name: "Şenay KARAGÖZ", value: "5cdb7ede6777f7c14e61c", type: "user" }] } ]
    //}




  }
  getControlName(name: string) {
    return name;
  }
  getControlType(name: string) {
    return name;
  }

  getData(name: string, data) {
    var result;
    data.forEach(aa => {
      if (aa.type === name) {
        result= aa.data;
      }
    })
    return result;
  }
  onChange(event, type:string) {
  

    if (event.checked) {
      if (type == "add") { this.panel_add = true; }
      else if (type == "edit") { this.panel_edit = true; }
      else if (type == "delete") { this.panel_delete = true; }
      else { this.panel = true;}
    } else {
      if (type == "add") { this.panel_add = false; }
      else if (type == "edit") { this.panel_edit = false; }
      else if (type == "delete") { this.panel_delete = false; }
      else { this.panel = false; }
    }
  }

  save() {
    var right_result = this.data.saveStudyRights();
    this.studyrightsdata = right_result;
    var request = {};
    if (this.isNew) {
      request = {
        studySchema: this.sid,
        organization: null,
        values: this.studyrightsdata,
      }
      var result = this.api.saveStudySchemaRights(request).subscribe(response => {
        if (response.success) {
          this.toastr.success('', 'Successfully created');
          this.location.back();
        }
        else {
          this.toastr.error('', 'Error occured', {
            timeOut: 3000
          });
        }

      }, (error) => {
        this.toastr.error('', 'Error occured', {
          timeOut: 3000
        });
      });
    }
    else {

      request = {
        _id: this.studyrightId,
        studySchema: this.sid,
        organization: null,
        values: this.studyrightsdata,
      }

      var result = this.api.updateStudySchemaRights(request).subscribe(response => {
        if (response.success) {
          this.toastr.success('', 'Successfully created');
          this.location.back();
        }
        else {
          this.toastr.error('', 'Error occured', {
            timeOut: 3000
          });
        }

      }, (error) => {
        this.toastr.error('', 'Error occured', {
          timeOut: 3000
        });
      });

    }

    
  }
  CheckControl(_value: string, arr) {
    if (arr != "" && arr != undefined) {
      if (arr.length != 0) {
    

        if (_value in arr) {
          return true;        
        }
        else return false;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

    

  }

}
