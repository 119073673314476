import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling'
import { MaterialModule } from './material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { routingComponents } from './app-routing.module';
import { DragdropformComponent } from './dragdropform/dragdropform.component';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatDialogModule } from '@angular/material';
import { TableComponent } from './table/table.component';
import { HomeComponent } from './home/home.component';
import { InputComponent } from "./components/input.component";
import { TextboxComponent } from "./components/textbox.component";
import { ButtonComponent } from "./components/button.component";
import { DateComponent } from "./components/date.component";
import { RadiobuttonComponent } from "./components/radiobutton.component";
import { CheckboxComponent } from "./components/checkbox.component";
import { DynamicFieldDirective } from "./components/dynamic-field/dynamic-field.directive";
import { DropdownComponent } from "./components/dropdown.component";
import { TimeComponent } from "./components/time.component";
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { TimePickerComponent } from './components/time-picker/time-picker/time-picker.component';
import { MatTimepickerDirective } from './components/time-picker/mat-timepicker.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TextAreaComponent } from "./components/textarea.component";
import { NumberDirective } from './components/number/number.directive';
import { NumberComponent } from "./components/number.component";
import { StudyCreatorComponent } from './study-creator/study-creator.component';
import { SliderComponent } from "./components/slider.component";
import { RemarkComponent } from "./components/remark.component";
import { RandomizationComponent } from "./components/randomization.component";
import { ComponentDialogComponent } from './component-dialog/component-dialog.component';
import { SummaryComponent } from "./components/summary.component";
import { NumberDateComponent } from "./components/numberdate.component";
import { StudyDetailComponent } from './study-detail/study-detail.component';
import { RecordsComponent } from './records/records.component';
import { GoogleChartComponent } from './google-chart/google-chart.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { JwtInterceptor } from './jwt.interceptor';
import { StudySchemaListComponent } from './study-schema-list/study-schema-list.component';
import { HttpErrorInterceptor } from './httperror.interceptor';
import { StudyListComponent } from './study-list/study-list.component';
import { OptiongroupDialogComponent } from './optiongroup-dialog/optiongroup-dialog.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { LocalStorageService } from './service/local-storage.service';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ChoicegroupDialogComponent } from './choicegroup-dialog/choicegroup-dialog.component';
import { GroupComponent } from "./components/group.component";
import { FlexLayoutModule } from '@angular/flex-layout';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SideBarToggleComponent } from './side-bar-toggle/side-bar-toggle.component';
import { SideBarService } from './side-bar/side-bar.service';
import { FormcontrolsService } from './service/formcontrols.service';
import { DataService } from './service/data.service';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { CalculationComponent } from "./components/calculation.component";
import { RolesComponent } from './administration/roles/roles.component';
import { CreateRoleComponent } from './administration/create-role/create-role.component';
import { DragScrollModule } from "cdk-drag-scroll";

import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { StudyRightsComponent } from './administration/study-rights/study-rights.component';
import { ChipsComponent } from './components/chips.component';
import { ReportsComponent } from './reports/reports.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { AuthGuard } from './guard/auth.guard'
import { SafeHtmlPipe } from './components/safe-html';
import { NavigationService } from './navigation.service';
import { ConsentFormDialogComponent } from './consent-form-dialog/consent-form-dialog.component';
import { InvestigatorsComponent } from './investigators/investigators.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { MatSpinner } from '@angular/material';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LeftSidebarComponent,
    TopbarComponent,
    FooterComponent,
    routingComponents,
    HomeComponent,
    DragdropformComponent,
    CheckboxComponent,
    TimeComponent,
    DynamicFieldDirective,
    TextboxComponent,
    ButtonComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    InputComponent,
    DropdownComponent,
    TimeComponent,
    DynamicFormComponent,
    TimePickerComponent,
    MatTimepickerDirective,
    DatePickerComponent,
    TextAreaComponent,
    NumberDirective,
    NumberComponent,
    TableComponent,
    StudyCreatorComponent,
    SliderComponent,
    RemarkComponent,
    RandomizationComponent,
    SummaryComponent,
    NumberDateComponent,
    RandomizationComponent,
    ComponentDialogComponent,
    StudyDetailComponent,
    RecordsComponent,
    GoogleChartComponent,
    StudySchemaListComponent,
    StudyListComponent,
    OptiongroupDialogComponent,
    ChoicegroupDialogComponent,
    SideBarComponent,
    SideBarToggleComponent,
    CalculationComponent,
    RolesComponent,
    CreateRoleComponent,
    StudyRightsComponent,
    ChipsComponent,
    GroupComponent,
    ReportsComponent,
    ForbiddenComponent,
    SafeHtmlPipe,
    ConsentFormDialogComponent,
    InvestigatorsComponent
    
    
  ],
  imports: [
    NgApexchartsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ScrollingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatToolbarModule,
    DragDropModule,
    DragScrollModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    GoogleChartsModule,
    StorageServiceModule,
    AmazingTimePickerModule,
    SelectAutocompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot() 
  ],
  entryComponents: [LeftSidebarComponent,
    TextboxComponent,
    ButtonComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    GroupComponent,
    DropdownComponent,
    InputComponent,
    TimeComponent,
    TimePickerComponent,
    TextAreaComponent,
    NumberComponent,
    SliderComponent,
    RemarkComponent,
    RandomizationComponent,
    SummaryComponent,
    NumberDateComponent,
    ComponentDialogComponent,
    OptiongroupDialogComponent,
    ChoicegroupDialogComponent,
    CalculationComponent,
    ChipsComponent,
    ConsentFormDialogComponent,
    MatSpinner
  ],
  providers: [SideBarService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    LocalStorageService, FormcontrolsService, DataService, AuthGuard, NavigationService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
