import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../Fields";

@Component({
  selector: "app-button",
  template: `
<div [formGroup]="group"> 
  <button mat-raised-button  ngDefaultControl>{{field.name}}</button>
</div>
`,
  styles: []
})
export class ButtonComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
