import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../service/api.service';
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexXAxis, ApexAnnotations, ApexFill, ApexStroke, ApexGrid, ApexResponsive, ApexLegend, NgApexchartsModule } from "ng-apexcharts";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import ApexCharts from 'apexcharts'
import { DataService } from '../service/data.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart; 
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  annotations: ApexAnnotations;
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
  responsive: ApexResponsive[];
  legend: ApexLegend;
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("reportChart") reportChart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public reportChartOptions: Partial<ChartOptions>;

  constructor(private api: ApiService, private translate: TranslateService, private dataservice: DataService) {

  
  }

  ngOnInit() {
    console.log("0")
    this.chartOptions = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      annotations: {
        points: []
      },
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%"


        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },

      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"]
        }
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        categories: [],
        tickPlacement: "on"
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          show:true,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        }
      }
    };

    this.reportChartOptions = {
      series: [
        {
          name: "",
          data: []
        },
        {
          name: "",
          data: []
        },

      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: []
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };

    this.getData();
  }

  getData(): void {

    var translateText = new BehaviorSubject<string>('');
    this.translate.get('reports-page.center').subscribe((text: string) => { translateText.next(`${text}`) });

    var pastWeeksTranslateText = new BehaviorSubject<string>('');
    this.translate.get('reports-page.pastWeeks').subscribe((text: string) => { pastWeeksTranslateText.next(`${text}`) });

    var newCasesTranslateText = new BehaviorSubject<string>('');
    this.translate.get('reports-page.newCases').subscribe((text: string) => { newCasesTranslateText.next(`${text}`) });

    let data = [];
    let chartData = [];
    let newData = [];
    let chartCategories = [];
    let schemaId = '601fbdbc1155b8568ab7fb5d';
    this.api.getOrganizations().subscribe(response => {
      var organizationList = response.filter(r => { return r.codeId > 0 })
      organizationList.sort((a, b) => (a.codeId > b.codeId) ? 1 : ((b.codeId > a.codeId) ? -1 : 0))
      organizationList.forEach(o => {
        data.push({id:o.id,code:o.code, codeId:o.codeId, name:o.name, count:0})
      })


      this.api.getRecordListWithOutOrganization(schemaId, "creationDate desc").subscribe(responseRecord => {
        data.forEach(d => {
          var newCaseStarted = this.dataservice.getLastWeekEpoch();
          var totalRecord = responseRecord.result.filter(i => { return i.organization === d.id })
          var newCasesRecord = responseRecord.result.filter(i => { return i.organization === d.id && i.creationDate >= newCaseStarted})
          d.count = totalRecord.length;
          chartCategories.push(`${translateText.getValue()} ${d.codeId}`)

          var totalRecordPastWeek= totalRecord.length - newCasesRecord.length;
         // chartData.push(totalRecord.length)
          chartData.push(totalRecordPastWeek);
          newData.push(newCasesRecord.length);

        })

        //this.chartOptions.xaxis.categories = chartCategories;
        //this.chartOptions.series[0].data = chartData;

        //var chart = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
        //chart.render();

        this.reportChartOptions.xaxis.categories = chartCategories;
        this.reportChartOptions.series[0].data = chartData;
        this.reportChartOptions.series[0].name = pastWeeksTranslateText.getValue();
        this.reportChartOptions.series[1].data = newData;
        this.reportChartOptions.series[1].name = newCasesTranslateText.getValue();

        var chart = new ApexCharts(document.querySelector("#reportChart"), this.reportChartOptions);
        chart.render();

        chart.updateSeries([
          {
            data: chartData
          }
        ]);
        chart.updateOptions({
          xaxis: {
            categories: chartCategories
          }
        });




   
      })
     
    })

  }

}
