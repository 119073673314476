export interface permissions {

  title: string;
  group_id: string; // Object
  menugroup: any;
  permission: any;
  visible: boolean;

}

export interface permission {
  code: string;
  codeId: number;
  description: string;
  visible: boolean;
}

export interface User {
  name: string;
  id: string;
  role: string;
}

export interface Customer {
  name: string;
  value: string;
}

export class roles {
  id: string;
  role: string;
  isSystemrole: boolean;
  customer: string;
  rights: any;
  permissionusers: any;

}
export interface menugrup {
  group_id: string; //Object;
  description: string;
  code: string;
  routerlink: string;
}
export interface menus {
  usergroup: string;
  username: string;
  menu: any;
}
export interface menu {
  title: string;
  routerlink: string;
  translate: string;
}
export interface studyrights {
  type: string;
  values: any;
}
export interface rights {
  name: string;
  selection: any;
}
export interface selections {
  name: string;
  value: string;
}



