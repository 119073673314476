import { DataSource} from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';

export interface DataTableTestItem {
  name: string;
  id: number;
  explanation: string;
  createdDate: string;
  updatedDate: string;
  pOfCompletion: number;
}


export class TableDataSource extends DataSource<any> {
  data: any[];

  constructor(private source: any, private paginator: MatPaginator, private sort: MatSort) {
    super();
    this.data = source.data;
    paginator.length = source.count; 
  }

  connect(): Observable<any[]> {
 
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  disconnect() { }

  private getPagedData(data: any[]) {
    console.log(this.source.count);
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'studyName': return compare(a.studyName, b.studyName, isAsc);
        case 'version': return compare(+a.version, +b.version, isAsc);
        default: return 0;
      }
    });
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
