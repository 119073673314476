import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Array } from 'core-js';

@Injectable({
  providedIn: 'root'
})
export class ComponentdataService {

  private dataSource = new BehaviorSubject<any>(['Element1','Element2']);
  currentComponentListData = this.dataSource.asObservable();

  constructor() { }

  changeData(componentListData: any) {
    this.dataSource.next(componentListData)
  }
}
