import { Component, OnInit, Inject, ViewEncapsulation, Input, OnDestroy, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatRadioChange, MatRadioButton } from "@angular/material";
import { Fields, NumberSettings, Group } from "../Fields";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import * as moment from 'moment';
import { OptiongroupDialogComponent } from '../optiongroup-dialog/optiongroup-dialog.component';
import { ApiService } from '../service/api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComponentdataService } from '../componentdata.service';
import { identifierModuleUrl } from '@angular/compiler';
import { ChoicegroupDialogComponent } from '../choicegroup-dialog/choicegroup-dialog.component';
import { INVALID } from '@angular/forms/src/model';
import { FormcontrolsService } from '../service/formcontrols.service';
import { DataService } from '../service/data.service';
import { __values } from 'tslib';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-component-dialog',
  templateUrl: './component-dialog.component.html',
  styleUrls: ['./component-dialog.component.css']
})

export class ComponentDialogComponent implements OnInit {
  firstform: FormGroup;
  form: FormGroup;
  type: any;
  @Input() schemaId: string;
  public options: {};

  fieldSteps: any;
  componentList: any;
  haveDependency: false;
  haveoption: boolean;
  changefieldtype: boolean;
  firstname: string;
  summary: string;
  allList: any;
  fieldValues: any;
  groupId: any;
  selectedFieldType: any;
  selection: any;
  constructor(
    private componentListData: ComponentdataService,
    private fb: FormBuilder, public dialog: MatDialog, private controlService: FormcontrolsService, private dataservice: DataService,
    private dialogRef: MatDialogRef<ComponentDialogComponent>, private api: ApiService, private route: ActivatedRoute, private auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.schemaId = this.data.schemaID == undefined ? null : this.data.schemaID;
    this.changefieldtype = false;
    this.firstname = this.data.datafield.name;
    this.fieldSteps = this.data.grouplist.length > 0 ? this.data.grouplist : this.data.fieldlist;

  }

  ondependentChange(mrChange: MatRadioChange) {
    this.selection = mrChange.value;
    if (!mrChange.value) {
      this.data.datafield.dependency.value = "";
      this.data.datafield.dependency.operator = "";
      this.data.datafield.dependency.groupName = "";
      this.data.datafield.dependency.fieldName = "";
      this.data.datafield.dependency.fieldStep = this.data.datafield.step;
    }

  }
  ngOnInit() {

    this.selectedFieldType = "dropdown";

    this.allList = this.data.grouplist.length > 0 ? this.data.grouplist : this.data.fieldlist;
    this.getFieldName(this.data.datafield.dependency.fieldStep, "field"); // for selectedFieldType

    if (this.data.datafield.type === "dropdown" || this.data.datafield.type === "radiobutton") {
      this.haveoption = true;
    }
    else { this.haveoption = false; }

    this.selection = this.data.datafield.settings != undefined ? this.data.datafield.settings.dependent : false;
    if (this.data.groupInfo.type === "group") {
      var filterResult = this.data.fieldlist.filter(i => { return i.step === this.data.groupInfo.step });
      this.data.fieldlist = filterResult;
    }
    else {
      var filterResult = this.data.fieldlist.filter(i => { return i.step != this.data.datafield.step });
      this.data.fieldlist = filterResult;
    }

    let dependencydata = {
      operator: [this.data.datafield.dependency.operator],
      groupStep: [this.data.datafield.dependency.fieldStep == "" ? "" : this.data.datafield.dependency.groupStep],
      fieldStep: [this.data.datafield.dependency.fieldStep],
      groupName: [this.data.datafield.dependency.groupName],
      fieldName: [this.data.datafield.dependency.fieldName],
      value: [this.data.datafield.dependency.value]
    };

    this.form = this.fb.group({
      id: [this.data.datafield.step],//['1'],
      step: [this.data.datafield.step],
      type: [this.data.datafield.type],
      options: [this.haveoption ? this.data.datafield.options : null],
      dependency: this.fb.group(dependencydata),
      name: [this.data.datafield.name == "" ? '' : this.data.datafield.name.replace(" ", "_"), [Validators.required]],
      question: [this.data.datafield.question == "" ? '' : this.data.datafield.question, [Validators.required]],
      settings: this.fb.group(this.controlService.GetFieldType(this.data.datafield))
    });
    if (!this.changefieldtype) {
      this.firstform = this.form.value;
    }

    //get study schema

    this.api.getChoicesList(this.schemaId).subscribe(response => {
      this.data.datafield.options = response.result;
    });

    this.componentListData.currentComponentListData.subscribe(componentList => this.componentList = componentList)
  }

  getFieldName(step, type) {
    var name = "";
    if (type == "group") {
      var result = this.data.fieldlist.find(i => { return i.step === step });
      if (result != undefined) {
        name = result.name;
      }
    }
    else if (type == "field") {
      var result = this.allList.find(i => { return i.step === step });
      if (result != undefined) {
        name = result.name;
        this.selectedFieldType = result.type;
      }
    }
    return name;
  }

  VariableControl(variable: string): boolean {
    let result: boolean = false;

    if (variable != "") {

      let count = 0;
      this.data.fieldlist.forEach(list => {
        if (list.name === variable) {
          count++;
        }
      })
      if (count >= 2) {
        result = true;
      }

      return result;
    }
    else {
      this.form.controls['name'].setErrors(null);
    }
    return result;
  }

  dataControl() {
    let variablecontrol = this.VariableControl(this.form.value.name);
    if (variablecontrol) {

      this.form.controls['name'].setErrors({ 'controlvalid': false });
      if (this.form.invalid) {
        return;
      }
      if (!this.form.controls.name.errors.controlvalid) {
        return;
      }
    }
    else {

      if (this.form.invalid || (this.form.controls.name.errors === null && this.form.controls.name.value === "")) {
        return false;
      }

      this.dialogRef.close(this.form.value);


      this.form.value.name = this.data.datafield.name.replace(" ", "_");
      if (this.data.datafield.type == "checkbox" && this.form.value.settings.multiple) {
        this.form.value.settings.choices = this.data.datafield.settings.choices;
      }
      else if (this.data.datafield.type == "radiobutton" || this.data.datafield.type == "dropdown") {
        
        if (this.data.datafield.settings.option != "" && this.data.datafield.settings.option != null) {
          this.data.datafield.options.forEach(list => {
            
            if (list.id === this.form.value.settings.option) {
              this.data.datafield.options = list.items;
            }
          })
        }
        else {
          if (this.data.datafield.type == "radiobutton") {
            this.data.datafield.options = [{ key: 'Option1', value: 'Option1' }, { key: 'Option2', value: 'Option2' }, { key: 'Option3', value: 'Option3' }];
          }

        }

      }
      //this.data.datafield.dependency = this.form.value.dependency;
      this.data.datafield.settings = this.form.value.settings;
      if (this.data.datafield.type == "calculation") {
        this.dataservice.formula = this.form.value.settings.summary;
      }
      else if (this.data.datafield.type == "slider") {
        this.data.datafield.settings.vertical = this.data.datafield.settings.vertical.toLowerCase() == 'true' ? true : false;
      }
      else if (this.data.datafield.type == "group") {
        this.data.datafield.group = this.data.datafield.group.length() == 0 ? [] : this.data.datafield.group;
      }

      this.data.datafield.dependency.fieldName = this.getFieldName(this.data.datafield.dependency.fieldStep, "field");
      this.data.datafield.dependency.groupName = this.getFieldName(this.data.datafield.dependency.groupStep, "group");



      var allfield = [];
      allfield = this.data.fieldlist;
      allfield.push(this.data.datafield);
      var fieldsort = allfield.sort(function (a, b) { return a.step - b.step })
      var json = JSON.stringify(this.form.value);



      const _studyName = localStorage.getItem('studyname');
      if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {
        localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`);
      }
      localStorage.setItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`, JSON.stringify(fieldsort));
    }

  }

  save() {

    this.dataControl();

  }

  onBlurMethod(event) {
    let errormessages = "";
    let controll = false;
    this.dataservice.alldatafield.forEach(alllist => {
      if (this.data.datafield.settings.summary.includes(alllist.name) && alllist.type != "number") {
        controll = true;
        errormessages = "Please check it calculation formula";

      }
    });


    if (this.data.datafield.settings.summary.includes(":")) {
      controll = true;
      errormessages = "Unexpected token :";
    }
    else if (this.data.datafield.settings.summary.includes("x") || this.data.datafield.settings.summary.includes("X")) {
      controll = true;
      errormessages = "Unexpected token x";
    }
    if (controll) {
      let control = this.form.controls['settings'];
      control.setErrors({ errormessage: errormessages });
      let message = control.errors['errormessage'];

    }
    else {
      this.form.controls['settings'].setErrors(null);
    }

  }


  close() {
    //if (this.changefieldtype) {
    //  this.form.patchValue(this.firstform);  
    //  this.data.datafield.type = this.form.value.type;
    //}
    this.dataControl();


  }

  change(event) {
    if (event.isUserInput) {
      this.changefieldtype = true;
      //console.log(event.source.value, event.source.selected);

      let aa = this.form.value.settings;
      this.data.datafield.type = event.source.value;

      this.ngOnInit();

      let aaa = this.form.value;

    }

  }

  dependentvalue(event, field) {
    var _value;
    if (event.isUserInput) {
      this.changefieldtype = true;
      if (field === "group") {
        if (this.form.value.dependency.groupStep != event.source.value) {
          this.data.datafield.dependency.fieldStep = undefined;
          this.data.datafield.dependency.operator = "";
          this.data.datafield.dependency.value = "";
          this.data.datafield.dependency.fieldName = "";
          this.data.datafield.dependency.groupName = "";
          this.selectedFieldType = "dropdown";
        }

      }
      if (field == "field") {
        _value = this.allList.filter(i => { return i.step === event.source.value });
      }
      else
        _value = this.data.fieldlist.filter(i => { return i.step === event.source.value });

      _value.forEach(f => {
        if (f.type === "dropdown" || f.type === "radiobutton" || (f.type === "checkbox" && f.settings.multiple) || f.type === "date" || f.type === "number" || f.type === "calculation")
          this.selectedFieldType = f.type;
        else this.selectedFieldType = "number";

        if (f.type === "group" && field === "group") {
          this.groupId = event.source.value;
          var filterlist = f.group.filter(i => { return i.type === "dropdown" || i.type === "radiobutton" || (i.type === "checkbox" && i.settings.multiple) || i.type === "date" || i.type === "number" || i.type === "calculation" })

          this.allList = filterlist;
        }
        else if (f.type != "group" && field === "group") {
          var filterlist = this.data.fieldlist.filter(i => { return i.type === "dropdown" || i.type === "radiobutton" || (i.type === "checkbox" && i.settings.multiple) || i.type === "date" || i.type === "number" || i.type === "calculation" })

          this.allList = filterlist;
        }


        if (field === "field") {
          this.fieldValues = f.type === "checkbox" ? f.settings.choices : f.options;
        }


      })
    }

  }
  openDialog(id: string): void {
    const dialogRef = this.dialog.open(OptiongroupDialogComponent, {
      width: '600px',
      data: { schemaID: this.schemaId, id }//datafield

    });


    dialogRef.afterOpen().subscribe(result => {

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ngOnInit();
    });
  }


  openChoiceDialog(datafield: Fields): void {
    const dialogRef = this.dialog.open(ChoicegroupDialogComponent, {
      width: '600px',
      data: {
        datafield, _name: this.firstname
      }

    });


    dialogRef.afterOpen().subscribe(result => {

    });

    dialogRef.afterClosed().subscribe(result => {
      this.data.datafield.name = result;
      console.log('The dialog was closed');
    });
  }

  disableSelect = new FormControl(true);

}
