import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { roles } from "../Permissions";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAccordion } from '@angular/material';
import { DataService } from '../service/data.service';
import { isObject } from 'util';
import { ApiService } from '../service/api.service';



@Component({
  selector: "app-chips",
  template: `

<div>
  <mat-form-field class="chip-list">
                
                        <mat-chip-list #chipList aria-label="Selection">
                          <mat-chip *ngFor="let select of dataselection"
                                    [ngClass]="[select.type === 'user' ? 'user-color': select.type === 'role'?'role-color':'org-color']"
                                    [selected]="true"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(select)">
                               {{select.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="{{'study-rights-page.selection-placeholder'| translate }}" 
                                 #selectedInput
                                 (keyup)="onKey($event)"
                                 [formControl]="name"
                                 [matAutocomplete]="auto"
                                 [matChipInputFor]="chipList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 [matChipInputAddOnBlur]="addOnBlur"
                                 (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                          <mat-option *ngFor="let select of filteredSelection | async" [value]="select">
                            {{select.name}}
                          </mat-option>
                        </mat-autocomplete>
                      <mat-hint>{{'study-rights-page.selection-hint'| translate }}</mat-hint>
                      </mat-form-field>
 </div>
`,
  styles: ['.chip-list {  width: 70%; } .user-color { background-color: #c4d5e6; } .role-color {background-color: #9dbad6; } .org-color {background-color: #ebf1f6; } ']

})

export class ChipsComponent implements OnInit {
  @Input() fieldname: string;
  @Input() type: string;
  @Input() datas = [];

  roles: roles;
  group: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  name = new FormControl();

  filteredSelection: Observable<string[]>;
  selections: string[] = [];
  dataselection: string[] = [];

  allSelections: any[] = [];


  @ViewChild('selectedInput') selectedInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;



  constructor(private data: DataService, private api: ApiService) {
    this.filteredSelection = this.name.valueChanges.pipe(
      startWith(null),
      map((select: string | null) => select ? this._filter(select) : this.allSelections.slice()));
  }

  onKey(event: any) { // without type info


    this.allSelections = [];
    if (event.target.value.length > 2) {
      if (event.target.value.startsWith("@")) {
        // supervisor da  getAllUsers metodu çalışacak
        this.api.getUsers().subscribe(_userlist => {
          _userlist.result.forEach(uservalue => {
            this.allSelections.push({ name: uservalue.firstname + " " + uservalue.lastname, value: uservalue.id, type: "user" });
          })
        });

      }
      else if (event.target.value.startsWith("#")) {
        this.api.getRoles(1, 30, "creationDate").subscribe(response => {
          response.data.forEach(responsedata => {
            this.allSelections.push({ name: responsedata.role, value: responsedata.id, type: "role" });
          })
        })     
      }
      else if (event.target.value.startsWith("org")) {
        this.api.getOrganizations().subscribe(orglist => {
          orglist.forEach(item => {
            this.allSelections.push({ name: item.name, value: item.id, type: "org" });
          })
        });
      }
      else {
        this.allSelections = [];
      }
    }
   
  }

  get rightsSelections() {
    return {
      type: this.type, values: [{name: this.fieldname, selection: this.dataselection}]}
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value.replace("@", "").replace("#", "").replace("org:","");
   
      if ((value || '').trim()) {
        this.selections.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.name.setValue(null);

    }
  }

  remove(select: any): void {
    const index = this.selections.indexOf(select.name);

    if (index >= 0) {
      this.selections.splice(index, 1);
      this.dataselection.splice(index, 1);
      this.data.rights = this.rightsSelections;
      this.data.getselectiondata();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    this.selections.push(event.option.viewValue);
 
    this.dataselection.push(event.option.value);
    this.data.rights = this.rightsSelections;
    this.data.getselectiondata();
    this.selectedInput.nativeElement.value = '';
    this.name.setValue(null);
  }


  private _filter(value: string): string[] {
 
    if (!isObject(value)) {
      const filterValue = value.toLowerCase();
      return this.allSelections.filter(select => select.name.toLowerCase().indexOf(filterValue.replace("@", "").replace("#", "").replace("org:","")) === 0);
    }
  
  }
  ngOnInit() {

    if (this.datas != undefined) {
      var _data = this.datas.find(i => i.name == this.fieldname);

      if (_data != undefined) {
        _data.selection.forEach(list => {
          this.selections.push(list.name);
          this.dataselection.push(list);
        })
      }
    }
   

  }


}

  







