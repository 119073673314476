import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { ApiService } from '../service/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-optiongroup-dialog',
  templateUrl: './optiongroup-dialog.component.html',
  styleUrls: ['./optiongroup-dialog.component.css']
})
export class OptiongroupDialogComponent implements OnInit {
  form: FormGroup;
  public optiondata = [];

  constructor(private fb: FormBuilder, public dialog: MatDialog, private toastr: ToastrService,
    private dialogRef: MatDialogRef<OptiongroupDialogComponent>, private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.form = fb.group({  
      name: ['', [Validators.required]],
      description: [''],
      isGlobal: [''],    
      items: this.fb.array([
        this.getOption()
      ])
    });

    if (this.data.schemaID != undefined && this.data.schemaID != null) { this.form.addControl('studySchema', new FormControl(this.data.schemaID)); }
   
  }
 


  ngOnInit() {

    if (this.data.id) {

      //get study schema
      this.api.getStudyChoices(this.data.schemaID, this.data.id).subscribe(response => {
        response.result.forEach(fromInstance => {

          const items = this.form.get('items') as FormArray;
          while (items.length) {
            items.removeAt(0);
          }
          this.form.patchValue(fromInstance);
          fromInstance.items.forEach(_value => items.push(this.fb.group(_value)));

        })

      });
    }
   
    
  }


  private getOption() {   
    return this.fb.group({
      value: ['', [Validators.required]],
      key: ['', [Validators.required]],   
    });
  }

  private addOption() {

    let listcontrol = false;
    let itemcontrol = this.form.controls['items'];
    itemcontrol.value.forEach(list => {
      if (list.key === '' || list.value === '')
    {
      listcontrol = true;
    }
  })    

  if(!listcontrol) {
    const control = <FormArray>this.form.controls['items'];
    control.push(this.getOption());
  }
  
  }

  private removeOption(i: number) {
    const control = <FormArray>this.form.controls['items'];
    control.removeAt(i);
  }

  save() {

    if (this.form.invalid) {
      return;
    }

    let _global = this.form.value.isGlobal === undefined ? false : this.form.value.isGlobal;
    if (!this.data.schemaID && !_global) {
     
      this.toastr.error('', 'You can not create a new option group without creating study ', {
        timeOut: 5000
      });
     // this.dialogRef.close(this.form.value);
      return;
    }

    //this.dialogRef.close(this.form.value);

    let _keycontrol = [];
    let _valuecontrol = [];
    let itemcontrol = false;
    this.form.value.items.forEach(list => {

      if (_keycontrol.includes(list.key) && _valuecontrol.includes(list.value)) {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate option name  and value. Please check it ', {
          timeOut: 3000
        });
        return;
      }
      else if (_keycontrol.includes(list.key)) {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate option value. Please check it ', {
          timeOut: 3000
        });
        return;
      }
      else if (_valuecontrol.includes(list.value)) {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate option name. Please check it ', {
          timeOut: 3000
        });
        return;
      }


      if (list.key === '' || list.value === '') {
        itemcontrol = true;
      }
      else {
        _keycontrol.push(list.key);
        _valuecontrol.push(list.value);
      }


    })

    if (!itemcontrol) {

    this.dialogRef.close(this.form.value);
      this.form["organization"] = null;

      if (this.data.id) {
        var request:any = {
          _id: this.data.id,
          organization: null,
          name: this.form.value.name,
          isGlobal: this.form.value.isGlobal,
          description: this.form.value.description,
          items: this.form.value.items
        };

        if (this.data.schemaID != undefined && this.data.schemaID != null) { request.studySchema = this.data.schemaID; }

        this.api.updateChoices(request).subscribe(response => {
        }, (error) => {

        });

      }
      else {
      
        this.api.saveChoices(this.form.value).subscribe(response => {
        }, (error) => {

        });
      }
    }
   
  }

  close() {
    this.dialogRef.close();
  }



}
