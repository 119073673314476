import { Component } from "@angular/core";
import { Guid } from "guid-typescript";

@Component({
  selector: "app-randomization",
  template:
    `<div  [formGroup]="group">
     <input   matInput  [formControlName] = "field.name" [(ngModel)] = "field.value" readonly >
 </div>
`,
  styles: []
})



export class RandomizationComponent {
  //public id: Guid;
  //public guid: string;
  constructor() {
    //var _guid = Guid.create().toString();
    //_guid = _guid.toLocaleUpperCase().replace("-", "").replace("-", "").replace("-", "").replace("-", "")

    //this.guid = _guid;
      
  }

 


}

