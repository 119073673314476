import { Injectable, OnInit } from "@angular/core";
import { ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TableSource } from '../data/tablesource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DataTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  dataSource: TableSource;

  constructor(public displayedColumns: string[]) {

  }
  ngOnInit() {

  }

  loadDataPage() {
    let sort = `${this.sort.active} ${this.sort.direction.toString()}`;
    this.dataSource.loadData(
      null,
      sort,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
  refresh() {
    this.loadDataPage();
  }
}
