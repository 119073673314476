import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError,of } from 'rxjs';
import { catchError, isEmpty, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/of';
//import 'rxjs/add/observable/empty';
//import 'rxjs/add/operator/retry'; // don't forget the imports

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private route: Router) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return next.handle(request).pipe(catchError(this.handleError)) as Observable<HttpEvent<any>>;
    return next.handle(request).pipe(catchError((error, caught) => {
      console.log(error);
      this.handleError(error);
      return of(error);
    }) as any);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status === 401) {
        this.route.navigate(["/login"]).catch(err => { });
      }
      else
        console.error(`Backend returned code ${error.status}, body was:`, error.error);

      if (error.error && error.error.message === "Unauthorized request!(organization)") {
        this.route.navigate(['/login']);
      }

    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
}
