import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fields } from "../Fields";





@Component({
  selector: "app-slider",
  template: `
<div   [formGroup]="group">
 <mat-slider class="example-margin" [max]=field.settings.maxvalue [min]=field.settings.minvalue [disabled]=field.settings.disabled
             [step]=field.settings.increment [thumbLabel]=field.settings.thumbLabel [formControlName]="field.name" [vertical]=field.settings.vertical>
  
  </mat-slider>
  <span *ngIf="field.settings.unit != null" matSuffix>&nbsp;{{field.settings.unit}}</span>
 </div>
<div> <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"  *ngIf="field.settings.info != null" ></mat-hint> </div>
`,
  styles: ['.example-margin {  margin: 10px; }  .hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class SliderComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }


 

}
