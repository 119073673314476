import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, Renderer2, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Fields, DateTimeSettings, NumberSettings, Validator, InputSettings, TextAreaSettings, SliderSettings, SummarySettings, NumberDateSettings, Options, DropdownSettings, RadioButtonSettings, TimeSettings, CheckboxSettings, DefaultSettings, GroupSettings } from '../Fields';
import { MatDialog } from '@angular/material';
import { ComponentDialogComponent } from '../component-dialog/component-dialog.component';
import { ApiService } from '../service/api.service';
import { TableDataSource } from '../table/TableDataSource';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized, RouterEvent, NavigationStart } from '@angular/router';
import { ComponentdataService } from '../componentdata.service';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { DataService } from '../service/data.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../service/authentication.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


import { NavigationService } from '../navigation.service';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';

import { Observable, interval} from 'rxjs';
import { takeUntil } from 'rxjs/operators';



export interface StudySchemaResponse {
  count: number;
  data: Fields[];
};

@Component({
  selector: 'app-dragdropform',
  templateUrl: './dragdropform.component.html',
  styleUrls: ['./dragdropform.component.css']
})

export class DragdropformComponent implements OnInit, OnDestroy {
  dataSource: TableDataSource;
  navigationSubscription: Subscription;

  public group = [];
  public groups = { item: new Fields(), group: [] };
  public dependentValues = [];
  public fieldDependent = [];
  id: number;
  unsubcribe: any
  name: string = 'Komponent adı.';
  public field = [];
  public creationdate: any;
  public userOrganization: any;
  forms: FormGroup;
  previousUrl: string = null;
  currentUrl: string = null;

  public isNew: boolean = true;
  public sid: string;
  public panelOpenGroup: boolean = false;
  public panelOpenField: boolean = true;

  private updateSubscription: Subscription;
  private sourceInterval = interval(60 * 1000);
  private onDestroy$ = new Subject<void>();


  constructor(public dialog: MatDialog, private api: ApiService, private route: ActivatedRoute, public router: Router, private auth: AuthenticationService, private navigation: NavigationService,private cdr: ChangeDetectorRef,
    private location: Location, private toastr: ToastrService, private componentListData: ComponentdataService, private dataservice: DataService, private translate: TranslateService, private sidebar: LeftSidebarComponent) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseField();
      }
    });


    if (this.route.snapshot.params.schemaId == undefined) {

      var _studyName = localStorage.getItem('studyname');

      if (this.router.navigated == false) {
        this.getTaskData();
      }

    }
  
  }

  initialiseField() {

    // Set default values and re-fetch any data you need.
    let _newfield = this.field;
    this.getTaskData();
  }
  cacheStudyControl() {
    var _studyName = localStorage.getItem('studyname');

    if (this.navigation.history.length >= 2) {
      this.previousUrl = this.navigation.history[this.navigation.history.length - 2];
      this.currentUrl = this.navigation.history[this.navigation.history.length - 1];
      if (this.previousUrl != null && this.currentUrl == '/home/study-creator' && this.previousUrl != this.currentUrl && localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {

        var text = new BehaviorSubject<string>('');
        var okButton = new BehaviorSubject<string>('');
        var cancelButton = new BehaviorSubject<string>('');
        this.translate.get('alertModal.study.unsaved-study-text').subscribe((translateText: string) => { text.next(`${translateText}`) });
        this.translate.get('alertModal.buttons.yes').subscribe((translateOk: string) => { okButton.next(`${translateOk}`) });
        this.translate.get('alertModal.buttons.no').subscribe((translateCancel: string) => { cancelButton.next(`${translateCancel}`) });
        Swal.fire({
          title: '',
          text: text.getValue(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: okButton.getValue(),
          cancelButtonText: cancelButton.getValue()
        }).then((result) => {
          
          if (result.value) {
            this.getTaskData();

            this.api.getSchemaWithName(_studyName).subscribe(studyresponse => {
              studyresponse.result.forEach(s => {
                this.isNew = false;
                this.sid = s.id;
              })
            
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {

            if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {
              localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`);
            }
            localStorage.removeItem('studyname')
            this.getTaskData();
            this.sidebar.studyname = "";
          }
        })
      }
      else
        this.getTaskData();
    }
    else
      this.getTaskData();
  }
  ngOnInit() {

    this.sid = this.route.snapshot.params.schemaId;
    if (this.sid) {

      this.isNew = false;
      this.field = [];
      this.api.getSchema(this.sid).subscribe(response => {
        this.creationdate = response.creationDate;
        this.field = response.schema;
        this.dataservice.alldatafield = response.schema;
        localStorage.setItem('studyname', response.studyName);


        this.field.forEach(_values => {
          if (_values.type === "radiobutton" || _values.type === "dropdown") {
            _values.options.forEach(_value => {
              if (_value.id === _values.settings.option) {
                _values.options = _value.items;
              }
            })

          }   
          else if (_values.type === "time") {
            _values.value = new Date().getHours() + ':' + new Date().getMinutes();
          }




        });

        this.getDependentFields();
      });
    }
    else {
      if (this.router.url === '/home/study-creator') {
        this.cacheStudyControl();
      }
    }

    this.updateSubscription = this.sourceInterval.pipe(takeUntil(this.onDestroy$)).subscribe((val) => { this.autoSave(); } );

  }


  getTaskData() {
    var _studyName = localStorage.getItem('studyname');
    for (let key in localStorage) {
      if (key.startsWith('sn_') && key != `sn_${this.auth.currentUserValue.organization}_${_studyName}`) {
        localStorage.removeItem(key);
      }

    }

    var studyFields = JSON.parse(localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`));
    if (studyFields != null) {
      this.field = studyFields
      this.field.forEach(_values => {
        if (_values.type === "radiobutton" || _values.type === "dropdown") {
          _values.options.forEach(_value => {
            if (_value.id === _values.settings.option) {
              _values.options = _value.items;
            }
          })

        }
        else if (_values.type === "time") {
          _values.value = new Date().getHours() + ':' + new Date().getMinutes();
        }
      });
      this.getDependentFields();
    }
    else
      this.field = [];
  }

  events: string[] = [];
  opened: boolean;

  form = [];


  drop(event: CdkDragDrop<string[]>) {

    var errorMessage = new BehaviorSubject<string>('');
    const _studyName = localStorage.getItem('studyname');

    if (_studyName != null && _studyName != "") {

      let groupcontrol = false;

      if ("list3" === event.container.id) {
        if (event.item.element.nativeElement.firstChild.nodeValue != null && event.item.element.nativeElement.firstChild.nodeValue.trim() === "group")
          groupcontrol = true;
      }
      //if ("list2" === event.container.id || ("list3" === event.container.id && event.item.element.nativeElement.firstChild.nodeValue.trim() != "group"))
      if ("list2" === event.container.id || ("list3" === event.container.id && !groupcontrol)) {
        if (event.container.id === event.previousContainer.id) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          // console.log("moveItemInArray");
        } else {
          copyArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
       
          var listID = "list1";
          if (listID === event.container.id || "list3" === event.container.id) {
            var text = event.item.element.nativeElement.innerText;
            var index = event.container.data.indexOf(text);
            if (index !== -1) {
              event.container.data.splice(index, 1);
              event.container.data.splice(index, 1);
            }
            var previousIndex = event.previousContainer.data.indexOf(text);
            if (previousIndex !== -1) {
              event.previousContainer.data.splice(previousIndex, 1);
            }
          }
        }

        // console.log(this.form);

        let componentListData = [];
        let fields = [];
        let eventname = "";
        if (event.container.id === event.previousContainer.id) {
          if (this.field.length > 0) {
            this.field.forEach(_values => {
              if (_values.step === event.previousIndex + 1) {
                eventname = _values.type;
              }
            });
          }
        }
        else {
          eventname = event.item.element.nativeElement.firstChild.nodeValue.trim();
        }

        if (event.container.id !== event.previousContainer.id) {

          var field_data = new Fields();

          field_data.id = event.currentIndex + 1;
          field_data.step = event.currentIndex + 1;
          field_data.name = "";
          field_data.type = eventname;
          field_data.disabled = false;
          field_data.inputType = eventname;
          field_data.value = "";
          field_data.dependency = { operator: "", groupStep: event.currentIndex + 1, groupName: "", fieldStep: "", fieldName: "", value: "" };
          //field_data.question = "Integer dignissim lobortis turpis nec gravida. Etiam nec ipsum vitae nulla pretium consequat.";
          field_data.question = "";



          if (eventname == "textbox") {
            field_data.type = "input",
              field_data.name = "txt_";

            field_data.inputType = "input";
            field_data.question = "";


            var _inputsettings = new InputSettings();
            _inputsettings.hint = "";
            _inputsettings.dependent = false;
            field_data.settings = _inputsettings;
          }
          else if (eventname == "date") {

            var settings = new DateTimeSettings();
            settings.dateformat = "LL";
            field_data.settings = settings;
            field_data.name = "dt_";
          }
          else if (eventname == "group") {
            field_data.group = [];
            var _groupsettings = new GroupSettings();
            _groupsettings.link = "";
            field_data.settings = _groupsettings;
          }
          else if (eventname == "radiobutton") {
            field_data.options = [{ key: 'Option1', value: 'Option1' }, { key: 'Option2', value: 'Option2' }, { key: 'Option3', value: 'Option3' }];
            var _radiosettings = new RadioButtonSettings();
            _radiosettings.layout = "vertical";
            _radiosettings.option = "";
            field_data.settings = _radiosettings;
            field_data.name = "rdb_";
          }
          else if (eventname == "dropdown") {

            field_data.name = "drp_";
            var _dropdownsettings = new DropdownSettings();
            _dropdownsettings.Isglobal = true;
            _dropdownsettings.required = false;
            _dropdownsettings.option = "";
            //this.api.getChoicesList(this.sid).subscribe(response => {
            //  field_data.options = response.result;
            //});
            // field_data.options = [{ key: 'Option1', value: 'Option1' }, { key: 'Option2', value: 'Option2' }, { key: 'Option3', value: 'Option3' }];
            field_data.settings = _dropdownsettings;
          }
          else if (eventname == "textarea") {

            var _textareasettings = new TextAreaSettings();
            _textareasettings.hint = "";
            _textareasettings.cols = 2;
            _textareasettings.rows = 5;
            field_data.settings = _textareasettings;
            field_data.name = "txt__";
          }
          else if (eventname == "number") {
            var numbersettings = new NumberSettings();
            numbersettings.minvalue = 0;
            numbersettings.maxvalue = 150;
            field_data.settings = numbersettings;
            field_data.name = "nbr_";
            field_data.question = "";

          }

          else if (eventname == "slider") {
            field_data.name = "sldr_";
            var slidersettings = new SliderSettings();
            slidersettings.minvalue = 0;
            slidersettings.maxvalue = 100;
            slidersettings.thumbLabel = true;
            slidersettings.increment = 5;
            slidersettings.disabled = false;
            slidersettings.vertical = false;
            field_data.settings = slidersettings;
          }
          else if (eventname == "checkbox") {
            field_data.name = "chk_";
            var _checksettings = new CheckboxSettings();
            _checksettings.required = false;
            _checksettings.choices = "";
            field_data.settings = _checksettings;
          }
          else if (eventname == "remark" || eventname == "randomization") {
            field_data.question = "";
            field_data.name = eventname === "remark" ? "rmk_" : "rnd_";
            var _remarksettings = new DefaultSettings();
            _remarksettings.hint = "";
            _remarksettings.info = "";
            field_data.settings = _remarksettings;
            field_data.value = field_data.name;
          }
          else if (eventname == "summary" || eventname == "calculation") {
            var summarysettings = new SummarySettings();
            summarysettings.info = eventname == "calculation" ? "" : "Field information";
            summarysettings.summary = eventname == "calculation" ? "" : "field infromation:{txt_name}, value:{rdb_group}";
            summarysettings.unit = "";
            field_data.settings = summarysettings;
            field_data.value = summarysettings.summary;
          }
          else if (eventname == "numberdate") {
            var numberdatesettings = new NumberDateSettings();
            numberdatesettings.minvalue = 0;
            numberdatesettings.maxvalue = 150;
            numberdatesettings.dateformat = "LL";
            numberdatesettings.required = false;
            field_data.settings = numberdatesettings;
            field_data.name = "nbrdt_";
          }
          else if (eventname == "time") {

            var timesettings = new TimeSettings();
            timesettings.info = "";
            field_data.settings = timesettings;
            field_data.value = new Date().getHours() + ':' + new Date().getMinutes();
          }
          if (field_data.name == "") {
            field_data.name = "var_";
          }
          var _validations = new Validator();
          _validations.name = "required";
          _validations.validator = Validators.required;
          _validations.message = " This field is required";
          field_data.validations = _validations;

          fields.push(field_data);

          var newData = [];
          let fieldcontrol = false;

          if (event.container.id === "list3") {

            this.groups.group.forEach(grouplist => {

              if (grouplist.step != undefined) {
                if (grouplist.step === field_data.step) {
                  grouplist.step = field_data.step;
                  newData.push(field_data);
                  grouplist.step = grouplist.step + 1;
                  grouplist.id = grouplist.step;
                  newData.push(grouplist);
                  fieldcontrol = true;
                }
                else if (grouplist.step > field_data.step) {
                  grouplist.step = grouplist.step + 1;
                  grouplist.id = grouplist.step;
                  newData.push(grouplist);
                }
                else {
                  newData.push(grouplist);
                }
              }

            })
            var _this = this;
            this.field.forEach(_list => {
              if (_list.type === "group") {
                if (fieldcontrol && _list.step === _this.groups.item.step && _list.name === _this.groups.item.name) {
                  _list.group = newData;
                  _this.group = newData;
                  _this.groups.group = newData;
                }
                else {
                  if (_list.step === this.groups.item.step && _list.name === _this.groups.item.name) {
                    _list.group[field_data.step - 1] = field_data;
                    _this.group[field_data.step - 1] = field_data;
                    //_this.groups.group = _this.group;
                    _this.groups.group = _list.group;

                  }

                }

              }

            })

          }
          else {
            this.field.forEach(list => {
              if (list.step === field_data.step) {
                list.step = field_data.step;
                newData.push(field_data);
                list.step = list.step + 1;
                list.id = list.step;
                newData.push(list);
                fieldcontrol = true;
              }
              else if (list.step > field_data.step) {
                list.step = list.step + 1;
                list.id = list.step;
                newData.push(list);
              }
              else {
                newData.push(list);
              }
            }

            )

            if (fieldcontrol) {
              this.field = newData;
            }
            else {
              this.field.push(field_data);
            }
          }
        }
        else {

          let a = event.previousIndex + 1;
          let b = event.currentIndex + 1;

          var newData = [];
          let fieldcontrol = false;
          let movedfield = false;

          if (event.container.id === "list3") {
            this.field.forEach(list => {
              if (list.type === "group") {
                for (let i = 0; i < list.group.length; i++) {
                  list.group[i].step = i + 1;
                  list.group[i].id = i + 1;
                }

              }
            })
          }
          else {
            this.field.forEach(list => {
              if (event.previousIndex > event.currentIndex) {
                if (list.step === event.currentIndex + 1) {
                  // console.log("list.step === event.currentIndex+1");
                  list.step = "-1";// event.previousIndex
                }
                else if (list.step === event.previousIndex + 1) {
                  list.step = event.currentIndex + 1;
                  // console.log("list.step === event.currentIndex+1");
                  this.field.forEach(listchild => {
                    if (listchild.step === "-1") {
                      //listchild.step = event.previousIndex + 1;
                      listchild.step = event.currentIndex + 2;
                    }
                  }
                  )
                }
              }
              else {
                if (list.step === event.currentIndex + 1 && !fieldcontrol) {
                  this.field.forEach(list2 => {
                    if (list2.step === event.currentIndex + 1) {
                      if (event.currentIndex != event.previousIndex) {
                        list2.step = event.currentIndex;
                        list2.id = event.currentIndex;
                        newData.push(list2);
                      }
                      //  console.log("pushed to list2" + list2);
                    }
                  })
                  fieldcontrol = true;

                  this.field.forEach(list3 => {
                    if (list3.step === event.previousIndex + 1 && fieldcontrol && !movedfield) {
                      list3.step = event.currentIndex + 1;
                      list3.id = event.currentIndex + 1;
                      newData.push(list3);
                      //  console.log("pushed to list3" + list3);
                      movedfield = true;
                    }
                  })
                }
                else if (list.step > event.previousIndex + 1 && movedfield) {
                  newData.push(list);
                }
                else {
                  if ((list.step != event.previousIndex + 1 || list.step > event.previousIndex + 1) && !fieldcontrol) {
                    if (list.step > event.previousIndex) {
                      list.step = event.previousIndex + 1;
                      list.id = event.previousIndex + 1;
                    }
                    newData.push(list);
                  }

                }
              }
            }
            )
            let sortedFieldStep = this.field.sort((a, b) => {
              return a.step - b.step;
            });

            newData = sortedFieldStep;


            for (let i = 0; i < newData.length; i++) {
              newData[i].step = i + 1;
              newData[i].id = i + 1;
            }

            this.field = newData;
            this.dataservice.alldatafield = this.field;
          }

        }

        this.getDependentFields()
        this.fieldStepControl();

        //console.log(this.field);       
        this.componentListData.changeData(this.field);

        if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {
          localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`);
        }

        localStorage.setItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`, JSON.stringify(this.field));


      }



    }
    else {
      this.translate.get('alert.study.studyName').subscribe((translateText: string) => { errorMessage.next(`${translateText}`) });
      this.toastr.error('', `${errorMessage.value}`);
    }

  }
  getDependentFields() {

    this.dependentValues = [];
    this.field.forEach(list => {
      if (list.type == "group") {

        var groupList = list.group.filter(g => { return g.dependency && g.dependency.value != "" && g.dependency.value != null })
        if (groupList.length > 0) {
          groupList.forEach(_group => {
            this.dependentValues.push({ step: _group.step, name: _group.name, operator: _group.dependency.operator, fieldStep: _group.dependency.fieldStep, groupStep: _group.dependency.groupStep, groupName: _group.dependency.groupName, fieldName: _group.dependency.fieldName, value: _group.dependency.value, affectedStep: _group.step, affectedfield: _group.name });
          })
        }
      }
      else {
        if (list.dependency && list.dependency.value != "" && list.dependency.value != null)
          this.dependentValues.push({ step: list.step, name: list.name, operator: list.dependency.operator, fieldStep: list.dependency.fieldStep, groupStep: list.dependency.groupStep, groupName: list.dependency.groupName, fieldName: list.dependency.fieldName, value: list.dependency.value, affectedStep: list.step, affectedfield: list.name });

      }
    })


  }
  fieldStepControl() {

    var _this = this;

    this.field.forEach(fieldlist => {
      if (fieldlist.type == "group") {
        fieldlist.group.forEach(fieldgroup => {

          var fielddependent = _this.dependentValues.filter(i => { return i.fieldName === fieldgroup.name && i.groupName === fieldlist.name })
          var groupdependent = _this.dependentValues.find(i => { return i.groupName === fieldlist.name })
          var affecteddependent = _this.dependentValues.find(i => { return i.affectedname === fieldgroup.name && i.groupName === fieldlist.name })

          //if (fielddependent != undefined && fielddependent.fieldStep != fieldgroup.step) {
          //  fielddependent.fieldStep = fieldgroup.step;
          //}
          if (fielddependent.length > 0) {
            fielddependent.forEach(i => {
              if (i.fieldName === fieldgroup.name && i.fieldStep != fieldgroup.step)
                i.fieldStep = fieldgroup.step;

            })
          }

          if (groupdependent != undefined && groupdependent.groupStep != fieldlist.step) {
            groupdependent.groupStep = fieldlist.step;
          }
          if (affecteddependent != undefined && affecteddependent.affectedStep != fieldgroup.step) {
            affecteddependent.affectedStep = fieldgroup.step;
          }
        })

        this.field.forEach(fieldlist => {
          if (fieldlist.type == "group") {
            fieldlist.group.forEach(fieldgroup => {

              var dependentcontrol = this.dependentValues.find(i => { return i.fieldName === fieldgroup.name && i.groupName === fieldlist.name })
              if (dependentcontrol != undefined) {
                if (fieldgroup.dependency.groupName === dependentcontrol.groupName && fieldgroup.dependency.fieldName === dependentcontrol.fieldName && fieldgroup.dependency.fieldStep != dependentcontrol.step) {

                  fieldgroup.dependency.fieldStep = dependentcontrol.step;
                  fieldgroup.dependency.fieldStep = dependentcontrol.groupStep;
                }
              }

            })
          }
        })

      }
      else {

        var fielddependent = this.dependentValues.find(i => { return i.fieldName === fieldlist.name })
        var groupdependent = this.dependentValues.find(i => { return i.groupName === fieldlist.name })
        var affecteddependent = this.dependentValues.find(i => { return i.affectedname === fieldlist.name })

        if (fielddependent != undefined && fielddependent.fieldStep != fieldlist.step) {
          fielddependent.fieldStep = fieldlist.step;
        }

        if (groupdependent != undefined && groupdependent.groupStep != fieldlist.step) {
          groupdependent.groupStep = fieldlist.step;
        }
        if (affecteddependent != undefined && affecteddependent.affectedStep != fieldlist.step) {
          affecteddependent.affectedStep = fieldlist.step;
        }
      }
    })

    this.field.forEach(list => {
      if (list.type == "group") {
        list.group.forEach(fieldgroup => {
          var dependent = this.dependentValues.find(i => { return i.step === fieldgroup.step && i.groupStep === list.step })
          if (dependent != undefined) {
            fieldgroup.dependency = { operator: dependent.operator, value: dependent.value, fieldStep: dependent.fieldStep, groupStep: dependent.groupStep, fieldName: dependent.fieldName, groupName: dependent.groupName };
          }

        })
      }
      else {
        var dependent = this.dependentValues.find(i => { return i.step === list.step && i.name === list.name })
        if (dependent != undefined) {
          list.dependency = { operator: dependent.operator, value: dependent.value, fieldStep: dependent.fieldStep, groupStep: dependent.groupStep, fieldName: dependent.fieldName, groupName: dependent.groupName };
        }
      }

    })

  }


  ngOnDestroy() {

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.updateSubscription) 
      this.updateSubscription.unsubscribe();
    this.onDestroy$.next();

   

  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);

    }
  }



  getConnectedList(): any[] {
    return this.field.map(x => `${x.id}`);
  }

  togglePanel(item): void {

    this.groups = { item: item, group: item.group };
    this.group = item.group;
    this.panelOpenGroup = !this.panelOpenGroup;
    this.panelOpenField = false;
  }
  CloseGroupPanel(): void {
    this.panelOpenField = !this.panelOpenField;
    this.panelOpenGroup = false;
  }
  toggleGroupPanel(item): void {

    this.field.forEach(list => {
      if (list.step === item.item.step && list.type === item.item.type && list.name === item.item.name) {
        list.group = item.group;
        this.groups.group = item.group;
      }
    })

    if (this.check_duplicate_in_field_group(this.field, this.groups.group)) {
      this.toastr.error('', 'There are duplicate variable names. Please check it');
    }
    else if (!this.check_duplicate_in_field(this.groups.group) && !this.check_question_in_field(this.groups.group)) {
      this.panelOpenField = !this.panelOpenField;
      this.panelOpenGroup = false;
    }
    else if (this.check_duplicate_in_field(this.groups.group)) {
      this.toastr.error('', 'There are duplicate variable names. Please check it');
    }
    else if (this.check_question_in_field(this.groups.group)) {
      this.toastr.error('', 'Please enter the question name');
    }
  }



  openDialog(datafield: Fields): void {
    const dialogRef = this.dialog.open(ComponentDialogComponent, {
      width: '800px',
      data: {
        datafield, schemaID: this.sid, fieldlist: this.field, grouplist: this.groups.group, groupInfo: this.groups.item
      }

    });


    dialogRef.afterOpen().subscribe(result => {

    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.componentInstance.form.patchValue(dialogRef.componentInstance.firstform);
      dialogRef.close();

    })

    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        dialogRef.componentInstance.form.patchValue(dialogRef.componentInstance.firstform);
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.optionsControls(datafield);
      if (result != undefined) {
        var newData = []; var newGroupData = [];
        if (this.groups.group.length > 0) {
          var includedField = this.field.find(f => { return f.id == this.groups.item.id })
          if (includedField != undefined) {

            var mappingGroup = includedField.group.map(g => {
              if (g.step === result.step) {
                g = datafield;
              }
              return g;
            })
            let sortedGroupStep = mappingGroup.sort((a, b) => {
              return a.step - b.step;
            });

            let groupCount = 1;

            sortedGroupStep.forEach(newgroup => {

              newgroup.id = groupCount;
              newgroup.step = groupCount;
              newGroupData.push(newgroup);
              groupCount++;
            })
            includedField.group = newGroupData;
          }
        }
        else {
          var mappingField = this.field.map(f => {
            if (f.step === result.step) {
              f = datafield;
            }
            return f;
          })
          let sortedFieldStep = mappingField.sort((a, b) => {
            return a.step - b.step;
          });

          let count = 1;
          sortedFieldStep.forEach(newlist => {

            newlist.id = count;
            newlist.step = count;
            newData.push(newlist);
            count++;
          })
          this.field = newData;
          // this.ngOnDestroy();
        }
      }

    });


  }

  checkDependencyControl() {
    var fieldControl = this.field.filter(f => { return !f.settings.dependent && f.dependency.operator != "" && f.dependency.value != "" })
    if (fieldControl.length >0) {
      fieldControl.forEach(item => {
          item.dependency.value = "";
          item.dependency.operator = "";
          item.dependency.groupName = "";
          item.dependency.fieldName = "";
          item.dependency.fieldStep = item.step;
        
      })
    }

    var groupFilter = this.field.filter(g => { return g.type == "group" })
    if (groupFilter.length > 0) {
      groupFilter.forEach(list => {
        var groupControl = list.group.filter(f => { return !f.settings.dependent && f.dependency.operator != "" && f.dependency.value != "" })
        if (groupControl.length > 0) {
          groupControl.forEach(item => {
            item.dependency.value = "";
            item.dependency.operator = "";
            item.dependency.groupName = "";
            item.dependency.fieldName = "";
            item.dependency.fieldStep = item.step;

          })
        }
      
      })
    }

  }

  check_duplicate_in_field(fieldlist: any): boolean {
    var object = {};

    let result: boolean = false;
    fieldlist.forEach(function (item) {
      if (!object[item.name])
        object[item.name] = 0;
      object[item.name] += 1;
    })

    for (var prop in object) {
      if (object[prop] >= 2) {
        result = true;
      }
    }

    return result;

  }

  check_duplicate_in_field_group(fieldlist: any, grouplist: any): boolean {
    var object = {};    
    let result: boolean = false;
    fieldlist.forEach(function (item) {      
      if (!object[item.name])
        object[item.name] = 0;
      object[item.name] += 1;
    })

    if (grouplist.length == 0) {
      fieldlist.forEach(list => {
        if (list.type == "group") {
          list.group.forEach(function (item) {
            if (!object[item.name])
              object[item.name] = 0;
            object[item.name] += 1;
          })
        }
      })
    }
    else {
      grouplist.forEach(function (item) {
        if (!object[item.name])
          object[item.name] = 0;
        object[item.name] += 1;
      })
    }

    for (var prop in object) {
      if (object[prop] >= 2) {
        result = true;
      }
    }
    return result;

  }

  check_question_in_field(fieldlist: any): boolean {


    let result: boolean = false;

    fieldlist.forEach(function (item) {
      if (item.type === "group") {
        var control = item.group.filter(i => { return i.question === "" })
        if (control.length > 0)
          result = true;
        else result = false;
      }
      if (!result) {
        if (item.question === "") {
          result = true;
        }
      }

    })

    return result;

  }
  optionsControls(dfield: Fields) {

    if (dfield.type == "radiobutton") {

      if (dfield.settings.option == "")
        dfield.options = [{ key: 'Option1', value: 'Option1' }, { key: 'Option2', value: 'Option2' }, { key: 'Option3', value: 'Option3' }];
      else {
        dfield.options.forEach(_value => {
          if (_value.id === dfield.settings.option) {
            dfield.options = _value.items;
          }
        })
      }

    }

  }
  autoSave() {
    console.log(new Date().toLocaleTimeString())
    const _studyname = localStorage.getItem('studyname');
    if (_studyname != null && _studyname != "") {
      this.checkDependencyControl();

      if (this.check_duplicate_in_field_group(this.field, this.groups.group)) {
        this.toastr.error('', 'There are duplicate variable names. Please check it');
      }
      else if (!this.check_duplicate_in_field(this.field) && !this.check_question_in_field(this.field)) {
        if (this.field.length > 0) {
          var request = {};
          if (this.isNew) {
            request = {
              studyName: _studyname,
              organization: null,
              schema: this.field,
              version: "v1"
            }
            var result = this.api.saveSchema(request).subscribe(response => {
              this.isNew = false;
              this.sid = response.id;

              if (this.creationdate == undefined) {
                this.api.getSchema(this.sid).subscribe(responseSchema => {
                  this.creationdate = responseSchema.creationDate;
                })
              }

              this.getTaskData();
              this.toastr.success('', 'Successfully created');

            }, (error) => {
              this.toastr.error('', 'Error occured', {
                timeOut: 3000
              });
            });
          }
          else {
            request = {
              _id: this.sid,
              studyName: _studyname,
              organization: null,
              schema: this.field,
              creationDate: this.creationdate,
              version: "v1"
            }
            var result = this.api.updateSchema(request).subscribe(response => {
              this.toastr.success('', 'Successfully updated.');

            }, (error) => {
              this.toastr.error('', 'Error occured', {
                timeOut: 3000
              });
            });

          }
        }

      }
      else if (this.check_duplicate_in_field(this.field)) {
        this.toastr.error('', 'There are duplicate variable names. Please check it');
      }
      else if (this.check_question_in_field(this.field)) {
        this.toastr.error('', 'Please enter the question name');
      }



    }
    return this.sid;

  }
  save() {

    const _studyname = localStorage.getItem('studyname');
    if (_studyname != null && _studyname != "") {
      if (this.check_duplicate_in_field_group(this.field, this.groups.group)) {
        this.toastr.error('', 'There are duplicate variable names. Please check it');
      }
      else if (!this.check_duplicate_in_field(this.field) && !this.check_question_in_field(this.field)) {
        localStorage.removeItem('studyname')
        var request = {};
        if (this.isNew) {
          request = {
            studyName: _studyname,
            organization: null,
            schema: this.field,
            version: "v1"
          }
          var result = this.api.saveSchema(request).subscribe(response => {
            localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyname}`)
            this.toastr.success('', 'Successfully created');
            this.location.back();
          }, (error) => {
            this.toastr.error('', 'Error occured', {
              timeOut: 3000
            });
          });
        }
        else {
          request = {
            _id: this.route.snapshot.params.schemaId,
            studyName: _studyname,
            organization: null,
            schema: this.field,
            creationDate: this.creationdate,
            version: "v1"
          }
          var result = this.api.updateSchema(request).subscribe(response => {
            localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyname}`)
            this.toastr.success('', 'Successfully updated.');
            this.location.back();
          }, (error) => {
            this.toastr.error('', 'Error occured', {
              timeOut: 3000
            });
          });
          //  console.log(this.field);
        }
      }
      else if (this.check_duplicate_in_field(this.field)) {
        this.toastr.error('', 'There are duplicate variable names. Please check it');
      }
      else if (this.check_question_in_field(this.field)) {
        this.toastr.error('', 'Please enter the question name');
      }



    }
    else {
      this.toastr.error('', 'Please enter the study name');
    }

  }
  remove(item: Fields): void {
    try {
      this.field = this.field.filter((i: Fields) => i.step != item.step);

      let index = 1;
      this.field.forEach(list => {

        if (list.step != index) {
          list.step = index;
          index++;
        }
        else { index = list.step; index++; }
      })
      this.getDependentFields();
      this.fieldStepControl();

      const _studyName = localStorage.getItem('studyname');
      if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {
        localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`);
      }
      localStorage.setItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`, JSON.stringify(this.field));

    } catch (e) {
      console.error(e);
    }
  }

  removeGroup(item: Fields): void {
    var _this = this;
    try {
      _this.groups.group = _this.groups.group.filter((i: Fields) => i.step != item.step);
      let index = 1;
      _this.groups.group.forEach(list => {
        if (list.step != index) {
          list.step = index;
          index++;
        }
        else { index = list.step; index++; }
      })

      _this.field.forEach(list => {
        if (list.step === _this.groups.item.step && list.type === _this.groups.item.type && list.name === _this.groups.item.name) {
          list.group = _this.groups.group;
        }
      })

      this.getDependentFields();
      this.fieldStepControl();
    } catch (e) {
      console.error(e);
    }
  }
  close() {
    const _studyName = localStorage.getItem('studyname');
    if (localStorage.getItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`) != null) {
      localStorage.removeItem(`sn_${this.auth.currentUserValue.organization}_${_studyName}`);
    }
    localStorage.removeItem('studyname')

    this.router.navigate(['/home/studies']);
  }
}




