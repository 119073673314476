import { Component, OnInit, Directive, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AuthenticationService } from '../service/authentication.service';
import { SidenavService } from '../service/sidenav.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})

export class TopbarComponent implements OnInit {

  name: string;

  private isOpened: boolean = false;

  constructor(private authService: AuthenticationService, private sidenav: SidenavService, /*private translate: TranslateService*/) {

  }

  //useLanguage(language: string) {
  //  this.translate.use(language);
  //}

  ngOnInit() {
    this.name = `${this.authService.currentUserValue.firstName} ${this.authService.currentUserValue.lastName}`;
  }
  logout() {

    this.authService.logout();
  }
  public toggleMenu() {
    this.sidenav.toggleIt();
    //this.isOpened = !this.isOpened;
    //if (this.isOpened) {
    //  this.sidenav.open();
    //}
    //else {
    //  this.sidenav.close();
    //}
  }
}
