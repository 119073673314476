import { Component, OnInit, AfterViewInit} from '@angular/core';
import { trigger, transition, style, state, animate, query, stagger, keyframes, animateChild } from "@angular/animations";
import { DataTableComponent } from '../../data/datatable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import { User } from '../../Permissions';
import { TableSource } from '../../data/tablesource';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { merge } from "rxjs/observable/merge";
import { fromEvent } from 'rxjs/observable/fromEvent';
import { first, debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';


export const userdata: User[] = [
  { name: "Nurdan SİNAN", id: "5cd92238e7cf9b8f7c137c3c", role: "Supervisor" },
  { name: "Şenay KARAGÖZ", id: "5cdbede6e7cf9b8f7c14e61c", role: "Supervisor" },
  { name: "Zehra AKÇE", id: "5cdbedc3e7cf9b8f7c14e600", role: "Domain Admin" },
  { name: "Bahadır KALAY", id: "5d023aa23a62e1b0d48a979b", role: "User" },

];

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
 

})
export class RolesComponent extends DataTableComponent implements OnInit, AfterViewInit {
  datas = []; 
  schemaId: string;
  displayedColumns: string[] = ['role','actions'];
  dataSource: any;
  roleData:any= [];
  constructor(private route: ActivatedRoute, private auth: AuthenticationService, private api: ApiService, private data: DataService) {
    super(['role', 'createdInfo', 'modifiedInfo', 'creationDate', 'modifiedDate', "actions"])
  }

  ngOnInit() {
    //this.dataSource = new TableSource('role-list', this.api);
    //var aaa = this.dataSource;
    //console.log(this.dataSource);
    //this.dataSource.loadData('', "creationDate desc", 0, 10);
    //merge(this.sort.sortChange).pipe(
    //  tap(() => {
    //    let sort = `${this.sort.active} ${this.sort.direction.toString() || 'asc'}`;
    //    console.log(sort);

    //    this.dataSource.loadData('', sort, this.paginator.pageIndex, this.paginator.pageSize);
    //  })
    //)
    //  .subscribe();
    this.dataSource = new TableSource('role-list', this.api,this.data);

    this.dataSource.loadRoleData('', "creationDate desc", 0, 10);
    merge(this.sort.sortChange).pipe(
      tap(() => {
        let sort = `${this.sort.active} ${this.sort.direction.toString() || 'asc'}`;
        console.log(sort);

        this.dataSource.loadRoleData('', sort, this.paginator.pageIndex, this.paginator.pageSize);
      })
    )
      .subscribe(list => {
        console.log(list);
      }

      );

   

    //this.api.getRoles().subscribe(rolelist => {
    //  console.log(rolelist);
    //  this.datas = rolelist.result;
    
    //  userdata.forEach(userlist => {

    //    if (userlist.id == this.auth.currentUserValue.id) {

    //      if (this.auth.currentUserValue.organization = this.data.adminOrganizationId) {
    //        if (userlist.role == "Supervisor") {
    //          this.data.system_roles.forEach(systemroles => {
    //            this.roleData.push(systemroles);
    //          })

    //          this.datas.forEach(list => {
    //            if (list.organization == this.auth.currentUserValue.organization) {
    //              this.roleData.push(list);
    //            }
    //          })

    //        }
    //      }
    //      else {
    //        if (userlist.role == "Site Admin" || userlist.role == "Domain Admin") {
    //          this.datas.forEach(list => {
    //            if (list.organizationId == this.auth.currentUserValue.organization) {
    //              this.roleData.push(list);
    //            }
    //          })

    //        }
    //      }
    //      this.dataSource = this.roleData;
        
    //    }
    //  })
    //})
       
  }

  ngAfterViewInit() {

    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    //merge(this.sort.sortChange, this.paginator.page)
    //  .pipe(
    //    tap(() => this.loadDataPage())
    //  )
    //  .subscribe();
  }

}
