import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../service/data.service';
import { ApiService } from '../service/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private dataservice: DataService,
    private api: ApiService
  
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    if (localStorage.getItem('currentUser') != null) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      var role = currentUser["role"].role;      
      if (route.data.roles && !route.data.roles.includes(role)) {
        this.router.navigate(['/forbidden']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
     //this.api.getsystemrole().subscribe(rolelist => {

    //    rolelist.forEach(_role => {
    //      if (_role.id == roleid) {
    //        notFoundSystemRole = false;
    //        roles = _role.rights;

    //      }

    //    })
    //    if (notFoundSystemRole) {
    //      this.api.getRole(roleid).subscribe(responsedata => {
    //        roles = responsedata.rights;


    //      });
    //    }


    //   var match = this.dataservice.roleMatch(roles);

  }


 

