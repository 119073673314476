import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-study-creator',
  templateUrl: './study-creator.component.html',
  styleUrls: ['./study-creator.component.css']
})
export class StudyCreatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
