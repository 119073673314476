import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { Fields } from "../Fields";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-choicegroup-dialog',
  templateUrl: './choicegroup-dialog.component.html',
  styleUrls: ['./choicegroup-dialog.component.css']
})
export class ChoicegroupDialogComponent implements OnInit {
  form: FormGroup;

  constructor(private fb: FormBuilder, public dialog: MatDialog,
    private dialogRef: MatDialogRef<ChoicegroupDialogComponent>, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.form = fb.group({
      choices: this.fb.array([
        this.getChoices()
      ])
    });
  }




  ngOnInit() {

    if (this.data.datafield.settings.choices != "") {

      const choices = this.form.get('choices') as FormArray;
      while (choices.length) {
        choices.removeAt(0);
      }
      this.data.datafield.settings.choices.forEach(fromInstance => {
        choices.push(this.fb.group(fromInstance));
      })
    } 
   


  }

  private getChoices() {
    return this.fb.group({
      value: ['', [Validators.required]],
      key: ['', [Validators.required]],   
    });
  }

  private addChoice() {
    let listcontrol = false;
    let itemcontrol = this.form.controls['choices'];
    itemcontrol.value.forEach(list => {
      if (list.key === '' || list.value === '') {
        listcontrol = true;
      }
    })

    if (!listcontrol) {
      const control = <FormArray>this.form.controls['choices'];
      control.push(this.getChoices());
    }
   
  }

  private removeChoice(i: number) {
    const control = <FormArray>this.form.controls['choices'];
    control.removeAt(i);
  }

  save() {

    if (this.form.invalid) {
      return;
    }

    let _keycontrol = [];
    let _valuecontrol = [];
    let itemcontrol = false;
    this.form.value.choices.forEach(list => {

     
      if (_keycontrol.includes(list.key) && _valuecontrol.includes(list.value))
      {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate choice name  and value. Please check it ', {
          timeOut: 3000
        });
        return;
      }
      else if (_keycontrol.includes(list.key)) {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate choice name. Please check it ', {
          timeOut: 3000
        });
        return;
      }
      else if (_valuecontrol.includes(list.value)) {
        itemcontrol = true;
        this.toastr.error('', 'There are duplicate choice value. Please check it ', {
          timeOut: 3000
        });
        return;
      }
    

      if (list.key === '' || list.value === '') {
        itemcontrol = true;
      }
      else {
        _keycontrol.push(list.key);
        _valuecontrol.push(list.value);
      }

    
    })


   



    if (!itemcontrol) {
      this.data.datafield.settings.choices = this.form.value.choices;
      let changedname = this.data.datafield.name;
      this.data.datafield.name = this.data._name;
      //this.dialogRef.close(this.form.value);
      this.dialogRef.close(changedname);
    }


  }

  close() {
    this.dialogRef.close(this.data.datafield.name);
  }


 
}


