import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fields } from "../Fields";
@Component({
  selector: "app-dropdown",
  template: `
   <div [formGroup]="group">
    <mat-form-field>
 
    <mat-select  required="{{field.settings.required}}" ngDefaultControl [formControlName]="field.name" [disabled]="field.disabled" >
    <mat-option> </mat-option>
    <mat-option *ngFor="let option of field.options" [value]="option.value">
      {{option.value}}
    </mat-option>
  </mat-select>   
  <mat-error *ngIf="selectcontrol.hasError(this.field.validations.name)">
    {{this.field.validations.message }}
  </mat-error>
    </mat-form-field>
  <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"   *ngIf="field.settings.info != null" ></mat-hint>
  </div>
`,
  styles: ['.hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class DropdownComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  selectcontrol = new FormControl('', Validators.required);
  constructor() { }
  ngOnInit() { }
}
