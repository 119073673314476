import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { retry, first,map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  headers: HttpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  private apiBaseUrl: string = '/crems/';

  constructor(private http: HttpClient,
    private authService: AuthenticationService) {

  }
  private getOrganization(): string {
    return this.authService.currentUserValue.organization;
  }

  public getAutoSaveInterval() {
    return this.http.get<any>(`${this.apiBaseUrl}record/getAutoSaveInterval`);
  }


  public getRecords(schemaId: string, query = null, page: number = 1, nPerPAge: number = 10, ...sort: string[]) {
    // var headers = new HttpHeaders().set("Content-Type", "application/json");
    var request = {
      StudySchema: schemaId,
      Organization: this.getOrganization(),
      Page: page,
      NumberPerPage: nPerPAge,
      SortBy: sort,
      Query: query
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/getRecordList`, request, { headers: this.headers }).pipe(first());
  }
  public getRecordList(schemaId: string,  ...sort: string[]) {   
    var request = {
      StudySchema: schemaId,
      Organization: this.getOrganization(),
      SortBy: sort
    };
   return this.http.post<any>(`${this.apiBaseUrl}record/getRecords`, request, { headers: this.headers }).pipe(first());
  }
  public getRecordListWithOutOrganization(schemaId: string, ...sort: string[]) {
    var request = {
      StudySchema: schemaId,
      SortBy: sort
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/getRecordsWithOutOrganization`, request, { headers: this.headers }).pipe(first());
  }
  public getRecord(recordId: string) {
    var request = {
      Id: recordId,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/getRecord`, request, { headers: this.headers }).pipe(first());
  }

  public updateSchema(schema: any) {

    schema.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(schema),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}schema/updateSchema`, request, { headers: this.headers }).pipe(first());
  }

  public saveRecord(record: any) {
    record.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(record),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/saveRecord`, request, { headers: this.headers }).pipe(first());
  }
  public updateRecord(record: any) {
    record.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(record),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/updateRecord`, request, { headers: this.headers }).pipe(first());
  }


  public getSchema(schemaId: string) {
    var request = {
      Id: schemaId,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}schema/getSchema`, request, { headers: this.headers }).pipe(first());
  }
  public getSchemaWithName(schemaName: string) {
    var request = {
      StudySchema: schemaName,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}schema/getSchema`, request, { headers: this.headers }).pipe(first());
  }

  public saveSchema(schema: any) {
    schema.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(schema),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}schema/saveSchema`, request, { headers: this.headers }).pipe(first());
  }

  public getSchemas(page: number = 1, nPerPAge: number = 10, ...sort: string[]) {
    //   var headers = new HttpHeaders().set("Content-Type", "application/json");
    var request = {
      Organization: this.getOrganization(),
      Page: page,
      NumberPerPage: nPerPAge,
      SortBy: sort,
    }
    return this.http.post<any>(`${this.apiBaseUrl}schema/getSchemaList`, request, { headers: this.headers }).pipe(first());
  }
  public getSchemasforRecords(page: number = 1, nPerPAge: number = 10, ...sort: string[]) {
    var request = {
      Organization: this.getOrganization(),
      Page: page,
      NumberPerPage: nPerPAge,
      SortBy: sort,
    }
    return this.http.post<any>(`${this.apiBaseUrl}schema/getSchemaListForRecords`, request, { headers: this.headers }).pipe(first());
  }
  

  public getChoicesList(schemaId: string) {
    var request = {
      Organization: this.getOrganization(),
      StudySchema: schemaId
    }
    return this.http.post<any>(`${this.apiBaseUrl}choice/choices`, request, { headers: this.headers }).pipe(first());
  }
  public getStudyChoices(schemaId: string, ...choices: string[]) {
    var request = {
      Organization: this.getOrganization(),
      StudySchema: schemaId,
      Query: choices.join(",")
    }
    return this.http.post<any>(`${this.apiBaseUrl}choice/studyChoices`, request, { headers: this.headers }).pipe(first());
  }
  public saveChoices(choice: any) {
    choice.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(choice),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}choice/save`, request, { headers: this.headers }).pipe(first());
  }
  public updateChoices(choice: any) {
    choice.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(choice),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}choice/update`, request, { headers: this.headers }).pipe(first());
  }
  public getRoles(page: number = 1, nPerPAge: number = 10, ...sort: string[]) {
    var request = {
      Organization: this.getOrganization(),
      Page: page,
      NumberPerPage: nPerPAge,
      SortBy: sort
    }
    return this.http.post<any>(`${this.apiBaseUrl}role/getRoleList`, request, { headers: this.headers }).pipe(first());
  }
  public getRole(roleId: string) {
    var request = {
      Id: roleId,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getRole`, request, { headers: this.headers }).pipe(first());
  }
  public getUsers() {
    var request = {
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getUserList`, request, { headers: this.headers }).pipe(first());
  }
  public getUser(userId:string) {
    var request = {
      Id: userId,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}user/getUser`, request, { headers: this.headers }).pipe(first());
  }
  public updateConsentConfirm(user:any) {
    var request = {
      Json: JSON.stringify(user),
      Organization: this.getOrganization()
     
    };
    return this.http.post<any>(`${this.apiBaseUrl}user/updateConsentConfirm`, request, { headers: this.headers }).pipe(first());
  }

  public getAllUsers() {
    var request = {
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getUsers`, request, { headers: this.headers }).pipe(first());
  }
  public getsystemrole() {
    return this.http.get<any>(`${this.apiBaseUrl}role/getSystemRole`).pipe(first());
  }

  public getRights() {
    return this.http.get<any>(`${this.apiBaseUrl}role/getRights`).pipe(first());
  }
  public getAdminOrganization() {
    return this.http.get<any>(`${this.apiBaseUrl}role/getAdminOrganization`).pipe(first());
  }



  public getUserRights(roleId: string) {
    var request = {
      Id:  roleId ,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getUserRights`, request, { headers: this.headers }).pipe(first());
  }
  public saveRole(role: any) {
    role.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(role),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/saveRole`, request, { headers: this.headers }).pipe(first());
  }
  public updateRole(role: any) {
    role.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(role),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/updateRole`, request, { headers: this.headers }).pipe(first());
  }
  public saveUserRights(userrights: any) {
    userrights.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(userrights),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/saveUserRights`, request, { headers: this.headers }).pipe(first());
  }
  public updateUserRights(userrights: any) {
    userrights.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(userrights),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/updateuserrights`, request, { headers: this.headers }).pipe(first());
  }

  public getStudySchemaRights(schemaId: string) {
    var request = {
      StudySchema: schemaId,
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getStudySchemaRights`, request, { headers: this.headers }).pipe(first());
  }
  public getStudyRights() {
    var request = {
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getStudyRights`, request, { headers: this.headers }).pipe(first());
  }


  public saveStudySchemaRights(studyrights: any) {
    studyrights.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(studyrights),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/saveStudySchemaRights`, request, { headers: this.headers }).pipe(first());
  }

  public updateStudySchemaRights(studyrights: any) {
    studyrights.organization = this.getOrganization();
    var request = {
      Json: JSON.stringify(studyrights),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/updateStudySchemaRights`, request, { headers: this.headers }).pipe(first());
  }
  getDataListWithPaged(objectName: string, name: string, page: number, pageSize: number, sort?: string, ...params: any[]): Observable<any> {
    switch (objectName) {
      case "study-schema-list":
        return this.getSchemas(page, pageSize, sort, params[0][0]);
      case "record-list":
        return this.getRecords(params[0][0],name, page, pageSize, sort);
      case "role-list":
        return this.getRoles( page, pageSize, sort);
  
      default: throw new Error("Unknown object name for paged request!");
    }
  }
  public getOrganizations() {
    var request = {
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}role/getOrganizations`,request, { headers: this.headers }).pipe(first());
  }
  public saveRecordWithCounter(schemaId: string, record: any) {
    record.organization = this.getOrganization();
    var request = {
      Id: schemaId,
      Json: JSON.stringify(record),
      Organization: this.getOrganization()
    };
    return this.http.post<any>(`${this.apiBaseUrl}record/saveRecordWithCounter`, request, { headers: this.headers }).pipe(first());
  }
}

