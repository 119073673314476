import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fields } from "../Fields";

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material';


@Component({
  selector: "app-numberdate",
  template: `

<div class="row" >
    <div class="column">
        <mat-form-field> 
           <input matInput numeric numericType="number" [(ngModel)]="field.value.numbervalue"  max="{{field.settings.maxvalue}}" min="{{field.settings.minvalue}}"
                          placeholder="Only numeric value"   required="{{field.settings.required}}" readonly="{{field.disabled}}" (blur)="onBlurMethod($event)"   ngDefaultControl >
            <span *ngIf="field.settings.unit != null" matSuffix>&nbsp;{{field.settings.unit}}</span>
           <mat-error>{{field.validations.message }} </mat-error>  
        </mat-form-field>
    </div>
    <div class="column"></div>
    <div class="column">
      <mat-form-field>
  <input matInput [matDatepicker]="picker" [(ngModel)]="field.value.datevalue"  readonly="{{field.disabled}}" (dateChange)="onDate($event)"  required="{{field.settings.required}}" >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker ></mat-datepicker>
</mat-form-field>
    </div>
  
</div>
<div class="row">
  <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"  *ngIf="field.settings.info != null" ></mat-hint>
</div>


`,
  styles: ['.row { display: table;width: 100 %;table-layout: fixed;border-spacing: 10px;}',
    '.column{ display: table-cell; }', '.hint-container { display: flex; flex-direction: column; font-size: 65%; }'],
  providers: [
    {
      provide: MAT_DATE_LOCALE, useValue: 'ja-JP'
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]

})


export class NumberDateComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  myNum: number;
  date: any;
  value: any;
  arr: { numbervalue: number, datevalue:any };
  constructor(private _adapter: DateAdapter<any>) { }


  tr() {
    this._adapter.setLocale('fr');
  } 
  ngOnInit() {
    this.tr();
    if (this.field.value != "") {

      this.arr = { numbervalue: this.field.value.numbervalue, datevalue: this.field.value.datevalue };
    }
    else {
      this.arr = { numbervalue: null, datevalue: null };
    }
     
  }


  getfieldname(fieldname:string, prm:string) {
    return fieldname+"_"+prm;
  }
  
  onBlurMethod(event) {

    if (this.field.value != "") {

      this.arr = { numbervalue: this.field.value.numbervalue, datevalue: this.field.value.datevalue };
    }


    var currentvalue = event.target.value;
    this.arr.numbervalue = currentvalue;
    this.field.value = this.arr;
  }


  public onDate(event: MatDatepickerInputEvent<Date>) {

    if (this.field.value != "") {

      this.arr = { numbervalue: this.field.value.numbervalue, datevalue: this.field.value.datevalue };
    }

    this.date = event.value;
  
    this.arr.datevalue = event.value;//event.value.format("DD/MM/YYYY");
    this.field.value = this.arr;
    
  }
}




