import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { TableComponent } from './table/table.component';
import { StudyCreatorComponent } from './study-creator/study-creator.component';
import { StudyDetailComponent } from './study-detail/study-detail.component';
import { RecordsComponent } from './records/records.component';
import { StudySchemaListComponent } from './study-schema-list/study-schema-list.component';
import { StudyListComponent } from './study-list/study-list.component';
import { RolesComponent } from './administration/roles/roles.component';
import { CreateRoleComponent } from './administration/create-role/create-role.component';
import { StudyRightsComponent } from './administration/study-rights/study-rights.component';
import { ReportsComponent } from './reports/reports.component';
import { AuthGuard } from './guard/auth.guard'
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { Role } from './Roles';
import { InvestigatorsComponent } from './investigators/investigators.component';


const routes: Routes = [
  { path: '', redirectTo: 'home/dashboard', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
      { path: 'studies', component: StudySchemaListComponent , canActivate: [AuthGuard]},
      { path: 'study-creator', component: StudyCreatorComponent, canLoad: [AuthGuard]},
      { path: 'study-creator/:schemaId', component: StudyCreatorComponent, canActivate: [AuthGuard]},
      { path: 'study-detail', component: StudyDetailComponent, canActivate: [AuthGuard]},
      { path: 'study-detail/:schemaId', component: StudyDetailComponent, canActivate: [AuthGuard]},
      { path: 'study-detail/:schemaId/:recordId', component: StudyDetailComponent, canActivate: [AuthGuard]},
      {
        path: 'study-list', component: StudyListComponent, canActivate: [AuthGuard]
      },
      {
        path: "records/:schemaId", component: RecordsComponent, canActivate: [AuthGuard]
      },
      { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
      { path: 'create-role', component: CreateRoleComponent, canActivate: [AuthGuard]},
      { path: 'create-role/:roleid', component: CreateRoleComponent, canActivate: [AuthGuard]},
      { path: 'study-rights/:schemaId', component: StudyRightsComponent, canActivate: [AuthGuard]},
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
      //{ path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], data: { roles: [Role.Supervisor, Role.SiteAdmin, Role.DomainAdmin] } },
      
    ],

    runGuardsAndResolvers:'always',
  },
  { path: 'login', component: LoginComponent},
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'investigators', component: InvestigatorsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation:'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent]
