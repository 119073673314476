
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Fields } from "../../Fields";
import { TextboxComponent } from "../textbox.component";
import { ButtonComponent } from "../button.component";
import { DropdownComponent } from "../dropdown.component";
import { DateComponent } from "../date.component";
import { RadiobuttonComponent } from "../radiobutton.component";
import { CheckboxComponent } from "../checkbox.component";
import { InputComponent } from "../input.component";
import { TimeComponent } from "../time.component";
import { TextAreaComponent } from "../textarea.component";
import { NumberComponent } from '../number.component';
import { SliderComponent } from '../slider.component';
import { RemarkComponent } from '../remark.component';
import { RandomizationComponent } from '../randomization.component';
import { SummaryComponent } from '../summary.component';
import { NumberDateComponent } from '../numberdate.component';
import { CalculationComponent } from '../calculation.component';
import { GroupComponent } from '../group.component';
//import { TabComponent } from '../tab.component';

const componentMapper = {
  textbox: TextboxComponent,
  button: ButtonComponent,
  dropdown: DropdownComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  input: InputComponent,
  time: TimeComponent,
  textarea: TextAreaComponent,
  number: NumberComponent,
  slider: SliderComponent,
  remark: RemarkComponent,
  randomization: RandomizationComponent,
  summary: SummaryComponent,
  numberdate: NumberDateComponent,
  calculation: CalculationComponent,
  group: GroupComponent,
  //tab: TabComponent
};
@Directive({
  selector: "[appDynamicField]"
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: Fields;
  @Input() dfvalue: any;
  @Input() group: FormGroup;
  public componentdata: any;
  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.componentRef) {
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
    }
  }
  ngOnInit() {
    this.dfvalue = this.dfvalue || {};
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.value = this.dfvalue[this.field.name];
  }
}
