import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);


  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }


  private username: string;

  //constructor() { }
  constructor(private router: Router) {

  }
  ngOnInit() {
  }

  onSendMail() {


    if (!(this.username === '' || this.username === undefined)) {
      this.router.navigate(['/reset-password']);

    }
  }
}
