import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators} from "@angular/forms";
import { Fields } from "../Fields";
  // <mat-form-field class="example-full-width">
  //  <textarea matInput placeholder="{{field.placeholder}}" [formControl]="name" [(ngModel)]="value" rows='{{field.settings.rows}}' cols='{{field.settings.cols}}'></textarea>
  //  <mat-hint>{{field.settings.hint}}</mat-hint>
  //  <mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error>
  //</mat-form-field>
@Component({
  selector: "app-textarea",
  template: `
<div   [formGroup]="group">
 <mat-form-field class="example-full-width">
    <textarea matInput [placeholder]="field.settings.hint" [formControlName]="field.name" rows='{{field.settings.rows}}' cols='{{field.settings.cols}}' required="{{field.settings.required}}" readonly="{{field.disabled}}" ></textarea>
    <mat-error *ngIf="InputFormControl.invalid"> {{getErrorMessage()}}</mat-error>
 </mat-form-field>
      <mat-hint class="hint-container" [innerHTML]="field.settings.info | safeHtml"    *ngIf="field.settings.info != null" ></mat-hint>
 </div>
`,
  styles: ['.example-full-width {  width: 60%; } .hint-container { display: flex; flex-direction: column; font-size: 65%; }']
})
export class TextAreaComponent implements OnInit {
  field: Fields;
  group: FormGroup;
  public value: "";

  constructor() { }
  ngOnInit() { }


  InputFormControl = new FormControl('', [Validators.required]);
  getErrorMessage() {
    return this.InputFormControl.hasError(this.field.validations.name) ? this.field.validations.message : '';
  }

}
